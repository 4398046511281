import { Button, Grid, Skeleton } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"
import { Teacher } from "../../Objects/Teacher"
import TeacherCardItem from "../molecules/TeacherCardItem"

export interface TeacherListProps {
    teachers: Teacher[]
    isLoading: boolean
}

const TeacherList: React.FC<TeacherListProps> = ({
    teachers,
    isLoading,
}) => {
    let navigate = useNavigate()

    return (
        <Grid container
            spacing={2}
        >
            {isLoading ? (
                <>
                    <Grid item
                        xs={12} sm={6} md={4}
                    >
                        <Skeleton
                            variant="rectangular"
                            height={100}
                        />
                    </Grid>
                    <Grid item
                        xs={12} sm={6} md={4}
                    >
                        <Skeleton
                            variant="rectangular"
                            height={100}
                        />
                    </Grid>
                    <Grid item
                        xs={12} sm={6} md={4}
                    >
                        <Skeleton
                            variant="rectangular"
                            height={100}
                        />
                    </Grid>
                    <Grid item
                        xs={12} sm={6} md={4}
                    >
                        <Skeleton
                            variant="rectangular"
                            height={100}
                        />
                    </Grid>
                    <Grid item
                        xs={12} sm={6} md={4}
                    >
                        <Skeleton
                            variant="rectangular"
                            height={100}
                        />
                    </Grid>
                    <Grid item
                        xs={12} sm={6} md={4}
                    >
                        <Skeleton
                            variant="rectangular"
                            height={100}
                        />
                    </Grid>
                </>
            ) : (
                <>
                    {teachers.map((teacher: Teacher, index: number) => (
                        <Grid item
                            xs={12} sm={6} md={4}
                            key={index}
                        >
                            <TeacherCardItem
                                teacher={teacher}
                            >
                                <Grid container
                                    justifyContent="right"
                                >
                                    <Grid item>
                                        <Button
                                            onClick={() => {
                                                navigate("/lesson/reserve/" + teacher.teacher_id)
                                            }}
                                        >予約</Button>
                                    </Grid>
                                </Grid>
                            </TeacherCardItem>
                        </Grid>
                    ))}
                </>
            )}
        </Grid>
    )
}

export default TeacherList
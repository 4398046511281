import { EquipData } from "./EquipData"

/**
 * interface EqucateData
 */
export interface EqucateData {
    equcate_id: number
    equcate_name: string
    equ_list: EquipData[]
}
export const ObjectToEqucateData = ({
    equcate_id,
    equcate_name,
    equ_list,
}: {
    equcate_id: string,
    equcate_name: string,
    equ_list?: EquipData[],
}): EqucateData => {
    return {
        equcate_id: parseInt(equcate_id),
        equcate_name: equcate_name,
        equ_list: equ_list ? equ_list : []
    }
}
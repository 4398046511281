import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { ApiError } from "../../Api/ApiError";
import { getReserveDetail, getReserveDetailResult, ReserveCancelRequest, ReserveCancelRequestResult, ReserveCancelSubmit, ReserveCancelSubmitResult } from "../../Api/Mypage";
import { ReserveDetail } from "../../Objects/ReserveDetail";
import ReserveCardItem from "./ReserveCardItem";
import StudioCancelCharge from "./StudioCancelCharge";

export interface ReserveCancelDialogProps {
    open: boolean
    onClose: () => void
    onCanceled: () => void
    stdrv_id?: number
    enableBackdrop?: boolean
}

const ReserveCancelDialog: React.FC<ReserveCancelDialogProps> = ({
    open,
    onClose,
    onCanceled,
    stdrv_id,
    enableBackdrop,
}) => {
    let { enqueueSnackbar } = useSnackbar()

    let [isLoading, setIsLoading] = React.useState<boolean>(true)
    let [reserveDetail, setReserveDetail] = React.useState<ReserveDetail>()
    let [studioCancelCharge, setStudioCancelCharge] = React.useState<number>()
    let [equipmentCancelCharge, setEquipmentCancelCharge] = React.useState<number>()

    let [dialog, setDialog] = React.useState<boolean>(false)

    const abortControllerRef = React.useRef(new AbortController())

    const doReserveCancel = React.useCallback(() => {
        if (stdrv_id) {
            setIsLoading(true)
            ReserveCancelSubmit({
                stdrv_id: stdrv_id
            }, {signal: abortControllerRef.current.signal}).then((result: ReserveCancelSubmitResult) => {
                switch (result.send_mail_code) {
                    case true:
                    case 'SUCCESS':
                        enqueueSnackbar("予約をキャンセルしました。", { variant: "info" })
                        break;
                    case 'NOT_MEMBERSHIP':
                        enqueueSnackbar("予約をキャンセルしました 確認メールが送信出来ませんでした。", { variant: "info" })
                        break;
                    case 'NO_MAIL':
                        enqueueSnackbar("予約をキャンセルしました 確認メールが送信出来ませんでした。", { variant: "info" })
                        break;
                    default:
                        enqueueSnackbar("キャンセルに失敗しました", { variant: "error" })
                        break;
                }
                setDialog(false)
                onCanceled()
            }).catch((e: any) => {
                if( e.__CANCEL__ ) {
                    console.info("canceled")
                } else if (e instanceof ApiError) {
                    enqueueSnackbar(e.message, { variant: "error" })
                } else if (e instanceof Error) {
                    enqueueSnackbar(e.message, { variant: "error" })
                }else {
                    enqueueSnackbar("通信に失敗しました", { variant: "error" })
                }
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }, [stdrv_id, enqueueSnackbar, onCanceled])

    React.useEffect(() => {
        if (open && stdrv_id) {
            setIsLoading(true)
            setDialog(false)
            // データ取得
            getReserveDetail({
                stdrv_id: stdrv_id
            }, {signal: abortControllerRef.current.signal}).then((result: getReserveDetailResult) => {
                setReserveDetail(result.reserveDetail)
                ReserveCancelRequest({
                    stdrv_id: stdrv_id
                }, {signal: abortControllerRef.current.signal}).then((result: ReserveCancelRequestResult) => {
                    setStudioCancelCharge(result.studio_cancel_charge)
                    setEquipmentCancelCharge(result.equipment_cancel_charge)
                    setIsLoading(false)
                    setDialog(true)
                }).catch((e: any) => {
                    if( e.__CANCEL__ ) {
                        console.info("canceled")
                    } else if (e instanceof ApiError) {
                        enqueueSnackbar(e.message, { variant: "error" })
                    } else if (e instanceof Error) {
                        enqueueSnackbar(e.message, { variant: "error" })
                    }else {
                        enqueueSnackbar("通信に失敗しました", { variant: "error" })
                    }
                })
            }).catch((e: any) => {
                if( e.__CANCEL__ ) {
                    console.info("canceled")
                } else if (e instanceof ApiError) {
                    enqueueSnackbar(e.message, { variant: "error" })
                } else if (e instanceof Error) {
                    enqueueSnackbar(e.message, { variant: "error" })
                }else {
                    enqueueSnackbar("通信に失敗しました", { variant: "error" })
                }
            })
        }
    }, [open, stdrv_id, enqueueSnackbar])

    React.useEffect(() => {
        abortControllerRef.current = new AbortController()
        return () => {
            abortControllerRef.current.abort()
        }
    }, [])

    /**
     * View
     */
    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={enableBackdrop ? isLoading : false}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                onClose={() => {
                    onClose()
                    setDialog(false)
                }}
                TransitionProps={{
                    onExited: () => {
                    }
                }}
                open={dialog}
                fullWidth
            >
                <DialogContent>
                    {reserveDetail &&
                        <ReserveCardItem
                            reserveData={reserveDetail}
                        />
                    }
                    {studioCancelCharge !== undefined && equipmentCancelCharge !== undefined &&
                        <Paper
                            variant="outlined"
                            sx={{
                                p: 1,
                                mt: 2,
                            }}
                        >
                            <StudioCancelCharge
                                studioCancelCharge={studioCancelCharge}
                                equipmentCancelCharge={equipmentCancelCharge}
                            />
                        </Paper>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            onClose()
                            setDialog(false)
                        }}
                        variant="contained"
                    >閉じる</Button>
                    <Button
                        onClick={() => {
                            doReserveCancel()
                        }}
                        variant="outlined"
                        color="error"
                        disabled={isLoading}
                    >キャンセルを実行</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default ReserveCancelDialog
import { Button, Grid, Skeleton } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"
import { Student } from "../../Objects/Student"
import StudentCardItem from "../molecules/StudentCardItem"

export interface StudentListProps {
    students: Student[]
    isLoading: boolean
}

const StudentList: React.FC<StudentListProps> = ({
    students,
    isLoading,
}) => {
    let navigate = useNavigate()

    return (
        <Grid container
            spacing={2}
        >
            {isLoading ? (
                <>
                    <Grid item
                        xs={12} sm={6} md={4}
                    >
                        <Skeleton
                            variant="rectangular"
                            height={100}
                        />
                    </Grid>
                    <Grid item
                        xs={12} sm={6} md={4}
                    >
                        <Skeleton
                            variant="rectangular"
                            height={100}
                        />
                    </Grid>
                    <Grid item
                        xs={12} sm={6} md={4}
                    >
                        <Skeleton
                            variant="rectangular"
                            height={100}
                        />
                    </Grid>
                    <Grid item
                        xs={12} sm={6} md={4}
                    >
                        <Skeleton
                            variant="rectangular"
                            height={100}
                        />
                    </Grid>
                    <Grid item
                        xs={12} sm={6} md={4}
                    >
                        <Skeleton
                            variant="rectangular"
                            height={100}
                        />
                    </Grid>
                    <Grid item
                        xs={12} sm={6} md={4}
                    >
                        <Skeleton
                            variant="rectangular"
                            height={100}
                        />
                    </Grid>
                </>
            ) : (
                <>
                    {students.map((student: Student, index: number) => (
                        <Grid item
                            xs={12} sm={6} md={4}
                            key={index}
                        >
                            <StudentCardItem
                                student={student}
                            >
                                <Grid container
                                    justifyContent="right"
                                >
                                    <Grid item>
                                        <Button
                                            onClick={() => {
                                                navigate("/teacher/reserve/" + student.student_id)
                                            }}
                                        >予約</Button>
                                    </Grid>
                                </Grid>
                            </StudentCardItem>
                        </Grid>
                    ))}
                </>
            )}
        </Grid>
    )
}

export default StudentList
import { StudioData } from "./StudioData"

/**
 * interface ReservableStudio
 */
export interface ReservableStudio extends StudioData {
    sum_charge?: number
}
export const ObjectToReservableStudio = ({
    std_id,
    std_name,
    str_id,
    str_name,
    sort,
    std_area,
    stdimg_id,
    stdimg_data,
    stdimg_mime_type,
    sum_charge,
}: {
    std_id: string
    std_name: string
    str_id: string
    str_name: string
    sort: string
    std_area?: string
    stdimg_id: string
    stdimg_data: string
    stdimg_mime_type: string
    sum_charge?: string
}) => {
    return {
        std_id: parseInt(std_id),
        std_name: std_name,
        str_id: parseInt(str_id),
        str_name: str_name,
        sort: parseInt(sort),
        std_area: std_area,
        stdimg_id: parseInt(stdimg_id),
        stdimg_data: stdimg_data,
        stdimg_mime_type: stdimg_mime_type,
        sum_charge: sum_charge ? parseInt(sum_charge) : undefined,
    }
}
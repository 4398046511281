import { Dialog, DialogTitle, List, ListItem } from "@mui/material";
import React from "react";

export interface NumberSelectDialogProps {
    open: boolean
    selectedValue: number
    onClose: (value: number) => void
    item_number: number
}

const NumberSelectDialog: React.FC<NumberSelectDialogProps> = ({
    open,
    selectedValue,
    onClose,
    item_number,
}) => {


    /**
     * View
     */
    return (
        <Dialog
            onClose={() => {
                onClose(selectedValue)
            }}
            open={open}
        >
            <DialogTitle>レンタルの個数を選択してください</DialogTitle>
            <List>
                <ListItem
                    button
                    onClick={() => {
                        onClose(0)
                    }}
                >予約しない</ListItem>
                {Array.from({ length: item_number }, (_, index) => index + 1).map((value: number, i: number) => (
                    <ListItem
                        button
                        onClick={() => {
                            onClose(value)
                        }}
                        key={i}
                    >{value}台</ListItem>
                ))}
            </List>
        </Dialog>
    )
}
export default NumberSelectDialog
import { ObjectToStudent, Student } from "./Student"
import { ObjectToTeacher, Teacher } from "./Teacher"

/**
 * interface LessonReserve
 */
export interface LessonReserve {
    lesson_id?: number
    teacher?: Teacher
    student?: Student
}
export const ObjectToLessonReserve = ({
    lesson_id,
    teacher,
    teacher_id,
    student,
    student_id,
}:{
    lesson_id?: number | string
    teacher?: any
    teacher_id?: number | string
    student?: any
    student_id?: number | string
}): LessonReserve => {
    return {
        lesson_id: typeof lesson_id === 'string' ? parseInt(lesson_id) : lesson_id,
        teacher: teacher ? ObjectToTeacher(teacher) : ( teacher_id ? ObjectToTeacher({teacher_id: teacher_id}) : undefined ),
        student: student ? ObjectToStudent(student) : ( student_id ? ObjectToStudent({student_id: student_id}) : undefined ),
    }
}
/**
 * interface EquipData
 */
export interface EquipData {
    equ_id: number
    id_list: number[]
    str_id: number
    equ_shortened: string
    equ_name: string
    equ_maker: string
    equ_model: string
    equ_charge: number
    equ_charge_type: string
    item_number: number
    charge: number
    free_flug: number
    rental_flug: number
    equcate_id: number
    equgrp_id: number
    equgrp_name: string
    delete_flug: number
    repaired_flug: number

}
export const ObjectToEquipData = ({
    equ_id,
    id_list,
    str_id,
    equ_shortened,
    equ_name,
    equ_maker,
    equ_model,
    equ_charge,
    equ_charge_type,
    item_number,
    charge,
    free_flug,
    rental_flug,
    equcate_id,
    equgrp_id,
    equgrp_name,
    delete_flug,
    repaired_flug,
}: {
    equ_id: number | string,
    id_list?: number[] | string[],
    str_id: number | string,
    equ_shortened: string,
    equ_name: string,
    equ_maker: string,
    equ_model: string,
    equ_charge: number | string,
    equ_charge_type: string,
    item_number: number | string,
    charge: number | string,
    free_flug: number | string,
    rental_flug: number | string,
    equcate_id: number | string,
    equgrp_id: number | string,
    equgrp_name: string,
    delete_flug: number | string,
    repaired_flug: number | string,
}): EquipData => {
    return {
        equ_id: typeof equ_id === 'string' ? parseInt(equ_id) : equ_id,
        id_list: id_list ? id_list.map((value: number | string) => typeof value === 'string' ? parseInt(value) : value) : [],
        str_id: typeof str_id === 'string' ? parseInt(str_id) : str_id,
        equ_shortened: equ_shortened,
        equ_name: equ_name,
        equ_maker: equ_maker,
        equ_model: equ_model,
        equ_charge: typeof equ_charge === 'string' ? parseInt(equ_charge) : equ_charge,
        equ_charge_type: equ_charge_type,
        item_number: typeof item_number === 'string' ? parseInt(item_number) : item_number,
        charge: typeof charge === 'string' ? parseInt(charge) : charge,
        free_flug: typeof free_flug === 'string' ? parseInt(free_flug) : free_flug,
        rental_flug: typeof rental_flug === 'string' ? parseInt(rental_flug) : rental_flug,
        equcate_id: typeof equcate_id === 'string' ? parseInt(equcate_id) : equcate_id,
        equgrp_id: typeof equgrp_id === 'string' ? parseInt(equgrp_id) : equgrp_id,
        equgrp_name: equgrp_name,
        delete_flug: typeof delete_flug === 'string' ? parseInt(delete_flug) : delete_flug,
        repaired_flug: typeof repaired_flug === 'string' ? parseInt(repaired_flug) : repaired_flug,
    }
}
import { Box } from '@mui/material'
import blstudio1_3 from '../assets/blstudio1-3.svg'

const BLstudioLogo: React.FC = () => {
    return <Box
        sx={{
            maxWidth: "120px",
            m: 1,
        }}
    >
        <img
            src={blstudio1_3}
            alt="BLstudio"
        />
    </Box>
}

export default BLstudioLogo
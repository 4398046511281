import { SxProps, TextField } from "@mui/material"

export interface InputMailAddressProps {
    value: string
    onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    label?: string
    required?: boolean
    sx?: SxProps
}
const InputMailAddress: React.FC<InputMailAddressProps> = ({
    value,
    onChange,
    label,
    required,
    sx,
}) => {
    return (
        <TextField
            label={label !== undefined ? label : "メールアドレス"}
            variant="standard"
            fullWidth
            value={value}
            onChange={onChange}
            required={required !== undefined ? required : true}
            sx={sx !== undefined ? sx : {
                my: 1,
            }}
        />
    )
}

export default InputMailAddress
import { Box, Button, CircularProgress, Grid, MenuItem, TextField, Typography } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { useSnackbar } from "notistack"
import React from "react"
import { ApiError } from "../../Api/ApiError"
import { RegisterProps } from "../../Api/Mypage"
import { Addr, Zipcode } from "../../Api/Zipcode"
import BlueArea from "../atoms/BlueArea"
import ListDialog from "../molecules/ListDialog"
import RegisterStep5Enquete from "./RegisterStep5Enquete"

export interface RegisterStep5Props {
    isConfirm?: ConstrainBoolean
    data: RegisterProps
    onSubmit: () => void
    onChange: (data: RegisterProps) => void
    onReset: () => void
    isSending?: boolean
}
const RegisterStep5: React.FC<RegisterStep5Props> = ({
    isConfirm,
    data,
    onSubmit,
    onChange,
    onReset,
    isSending,
}) => {
    let { enqueueSnackbar } = useSnackbar()

    const [openAddrSelect, setOpenAddrSelect] = React.useState<boolean>(false)
    const [addrSelectList, setAddrSelectList] = React.useState<Addr[]>([])

    const abortControllerRef = React.useRef(new AbortController())

    React.useEffect(() => {
        abortControllerRef.current = new AbortController()
        return () => {
            abortControllerRef.current.abort()
        }
    }, [])

    const doZipcodeToAddress = React.useCallback(() => {
        if (data.postcode) {
            Zipcode({
                code: data.postcode
            }, { signal: abortControllerRef.current.signal }).then(result => {
                if (result.addr.length > 1) {
                    setAddrSelectList(result.addr)
                    setOpenAddrSelect(true)
                } else {
                    let d: RegisterProps = {
                        ...data,
                        address1: `${result.addr[0].pref}${result.addr[0].city}${result.addr[0].town}`
                    }
                    onChange(d)
                }
            }).catch(e => {
                if (e.__CANCEL__) {
                    console.info("canceled")
                } else if (e instanceof ApiError) {
                    enqueueSnackbar(e.message, { variant: "error" })
                } else if (e instanceof Error) {
                    enqueueSnackbar(e.message, { variant: "error" })
                } else {
                    enqueueSnackbar("通信に失敗しました", { variant: "error" })
                }
            })
        }
    }, [data, enqueueSnackbar, onChange])

    return (
        <Box>
            <BlueArea>
                <Typography
                    textAlign='left'
                    sx={{
                        fontWeight: 'bold'
                    }}
                >お客様の情報を記入お願いします。</Typography>
            </BlueArea>
            {isConfirm ? (
                <Box
                    textAlign='right'
                    sx={{
                        mx: 2
                    }}
                >
                </Box>
            ) : (
                <Box
                    component='form'
                    onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                        e.preventDefault()
                        onSubmit()
                    }}
                    sx={{
                        my: 2,
                        mx: 'auto',
                        px: 2,
                        py: 1,
                        width: { xs: '100%', md: '80%' },
                    }}
                >
                    <Grid container
                        spacing={2}
                    >
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="姓"
                                required={true}
                                name='name1'
                                value={data.name1 ? data.name1 : ""}
                                onChange={(e) => {
                                    let d: RegisterProps = {
                                        ...data,
                                        name1: e.target.value
                                    }
                                    onChange(d)
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="名"
                                required={true}
                                name='name2'
                                value={data.name2 ? data.name2 : ""}
                                onChange={(e) => {
                                    let d: RegisterProps = {
                                        ...data,
                                        name2: e.target.value
                                    }
                                    onChange(d)
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="せい"
                                required={true}
                                name='pronunciation1'
                                value={data.pronunciation1 ? data.pronunciation1 : ""}
                                onChange={(e) => {
                                    let d: RegisterProps = {
                                        ...data,
                                        pronunciation1: e.target.value
                                    }
                                    onChange(d)
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="めい"
                                required={true}
                                name='pronunciation2'
                                value={data.pronunciation2 ? data.pronunciation2 : ""}
                                onChange={(e) => {
                                    let d: RegisterProps = {
                                        ...data,
                                        pronunciation2: e.target.value
                                    }
                                    onChange(d)
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                openTo="year"
                                views={['year', 'month', 'day']}
                                label="生年月日"
                                onChange={(date) => {
                                    let d: RegisterProps = {
                                        ...data,
                                        birthday: date ? date : undefined
                                    }
                                    onChange(d)
                                }}
                                value={data.birthday ? data.birthday : null}
                                slotProps={{
                                    textField: {
                                        helperText: "例）1990-01-10",
                                        required: true,
                                        fullWidth: true
                                    }
                                }}
                                disableFuture={true}
                                defaultCalendarMonth={null}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                label="性別"
                                value={data.gender ?? ""}
                                onChange={e => {
                                    let selected: string = e.target.value as string
                                    let d: RegisterProps = {
                                        ...data,
                                        gender: selected ? selected : undefined
                                    }
                                    onChange(d)
                                }}
                                fullWidth
                                select
                                required
                            >
                                <MenuItem value='' disabled>選択してください。</MenuItem>
                                <MenuItem value="male">男性</MenuItem>
                                <MenuItem value="female">女性</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="電話番号"
                                required={data.tel2 ? false : true}
                                name='tel1'
                                value={data.tel1 ? data.tel1 : ""}
                                onChange={(e) => {
                                    let d: RegisterProps = {
                                        ...data,
                                        tel1: e.target.value
                                    }
                                    onChange(d)
                                }}
                                fullWidth
                                helperText="例）0521234568 (固定電話か携帯電話のどちらか必須)"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="電話番号(携帯)"
                                required={data.tel1 ? false : true}
                                name='tel2'
                                value={data.tel2 ? data.tel2 : ""}
                                onChange={(e) => {
                                    let d: RegisterProps = {
                                        ...data,
                                        tel2: e.target.value
                                    }
                                    onChange(d)
                                }}
                                fullWidth
                                helperText="例）08012345678 (固定電話か携帯電話のどちらか必須)"
                            />
                        </Grid>
                        <Grid item xs={7} sm={6}>
                            <TextField
                                variant="outlined"
                                size="small"
                                label="郵便番号"
                                value={data.postcode ?? ""}
                                onChange={e => {
                                    let d: RegisterProps = {
                                        ...data,
                                        postcode: e.target.value
                                    }
                                    onChange(d)
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={5} sm={3}>
                            <Button
                                variant="outlined"
                                onClick={e => {
                                    doZipcodeToAddress()
                                }}
                                fullWidth
                            >住所検索</Button>
                            <ListDialog
                                open={openAddrSelect}
                                values={addrSelectList.map(addr => `${addr.pref}${addr.city}${addr.town}`)}
                                onClose={(selected) => {
                                    setOpenAddrSelect(false)
                                    let d: RegisterProps = {
                                        ...data,
                                        address1: selected
                                    }
                                    onChange(d)
                                }}
                                onCancel={() => {
                                    setOpenAddrSelect(false)
                                }}
                                title="住所を選択してください"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                size="small"
                                label="住所 (県・市区町村)"
                                value={data.address1 ?? ""}
                                onChange={e => {
                                    let d: RegisterProps = {
                                        ...data,
                                        address1: e.target.value
                                    }
                                    onChange(d)
                                }}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                size="small"
                                label="住所（番地・マンション・アパート名）"
                                value={data.address2 ?? ""}
                                onChange={e => {
                                    let d: RegisterProps = {
                                        ...data,
                                        address2: e.target.value
                                    }
                                    onChange(d)
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RegisterStep5Enquete
                                enqueteAnswers={data.enqdata ? data.enqdata : []}
                                onChange={(newValue) => {
                                    let d: RegisterProps = {
                                        ...data,
                                        enqdata: newValue
                                    }
                                    onChange(d)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} textAlign="right">
                            <Button
                                variant="contained"
                                type="submit"
                                disabled={isSending}
                            >{isSending ? <CircularProgress size={"1em"} color="inherit" sx={{my: 0.7}} /> : "送信"}</Button>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </Box>
    )
}

export default RegisterStep5
import { Edit } from "@mui/icons-material";
import { IconButton, Typography, TypographyProps } from "@mui/material";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import React from "react";

export interface TypographyDateProps {
    date: Date
    typographyProps?: TypographyProps
    onEdit?: () => void
}
const TypographyDate: React.FC<TypographyDateProps> = ({
    date,
    typographyProps,
    onEdit,
}) => {

    /**
     * View
     */

    return (
        <Typography
            {...typographyProps}
        >
            {format(date, "yyyy年MM月dd日(E)", { locale: ja })}
            {onEdit &&
                <IconButton aria-label="変更" color="primary" size="small"
                    onClick={onEdit}
                >
                    <Edit fontSize="inherit" />
                </IconButton>
            }
        </Typography>
    )
}

export default TypographyDate
import { Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemText } from "@mui/material";

export interface ListDialogProps {
    open: boolean
    values: string[]
    onClose: (selected: string, index: number) => void
    onCancel: () => void
    title?: string
}

export default function ListDialog(props: ListDialogProps) {
    return (
        <Dialog
            open={props.open}
            onClose={() => {
                props.onCancel()
            }}
        >
            {props.title &&
                <DialogTitle>{props.title}</DialogTitle>
            }
            <List>
                {props.values.map((value, index) => (
                    <ListItem key={index} disableGutters>
                        <ListItemButton
                            onClick={() => {
                                props.onClose(value, index)
                            }}
                        >
                            <ListItemText primary={value}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Dialog>
    )
}
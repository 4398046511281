import { Box, Button, Paper, Stack, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import React from "react"
import { ApiError } from "../../Api/ApiError"
import { ChangePassword, getProfile, getProfileResult, setProfile } from "../../Api/Mypage"
import BlueArea from "../atoms/BlueArea"
import InputMailAddress from "../atoms/InputMailAddress"
import InputPassword from "../atoms/InputPassword"

const ProfilePage: React.FC = () => {
    let { enqueueSnackbar } = useSnackbar()

    let [mailAddress1, setMailAddress1] = React.useState<string>("")
    let [mailAddress2, setMailAddress2] = React.useState<string>("")
    let [oldPassword, setOldPassword] = React.useState<string>("")
    let [newPassword, setNewPassword] = React.useState<string>("")
    let [confirmPassword, setConfirmPassword] = React.useState<string>("")

    let confirmPasswordInput = React.useRef<HTMLInputElement>(null)

    const abortControllerRef = React.useRef(new AbortController())

    React.useEffect(() => {
        getProfile({signal: abortControllerRef.current.signal}).then((result: getProfileResult) => {
            setMailAddress1(result.mail1)
            setMailAddress2(result.mail2)
        }).catch((e: any) => {
            if( e.__CANCEL__ ) {
                console.info("canceled")
            } else if (e instanceof ApiError) {
                enqueueSnackbar(e.message, { variant: "error" })
            } else if (e instanceof Error) {
                enqueueSnackbar(e.message, { variant: "error" })
            }else {
                enqueueSnackbar("通信に失敗しました", { variant: "error" })
            }
        })
    }, [enqueueSnackbar])

    let doEditProfile = React.useCallback(() => {
        setProfile({
            mail1: mailAddress1,
            mail2: mailAddress2,
        }, {signal: abortControllerRef.current.signal}).then(() => {
            enqueueSnackbar("プロフィールを変更しました", { variant: "success" })
        }).catch((e: any) => {
            if( e.__CANCEL__ ) {
                console.info("canceled")
            } else if (e instanceof ApiError) {
                enqueueSnackbar(e.message, { variant: "error" })
            } else if (e instanceof Error) {
                enqueueSnackbar(e.message, { variant: "error" })
            }else {
                enqueueSnackbar("通信に失敗しました", { variant: "error" })
            }
        })
    }, [mailAddress1, mailAddress2, enqueueSnackbar])

    let doChangePassword = React.useCallback(() => {
        if (newPassword === confirmPassword) {
            ChangePassword({
                oldPassword: oldPassword,
                newPassword: newPassword,
                confirmPassword: confirmPassword
            }, {signal: abortControllerRef.current.signal}).then(() => {
                enqueueSnackbar("パスワードを変更しました", { variant: "success" })
                setOldPassword("")
                setNewPassword("")
                setConfirmPassword("")
            }).catch((e: any) => {
                if( e.__CANCEL__ ) {
                    console.info("canceled")
                } else if (e instanceof ApiError) {
                    enqueueSnackbar(e.message, { variant: "error" })
                } else if (e instanceof Error) {
                    enqueueSnackbar(e.message, { variant: "error" })
                }else {
                    enqueueSnackbar("通信に失敗しました", { variant: "error" })
                }
            })

        } else {
            enqueueSnackbar("新しいパスワードが一致しません", { variant: "error" })
            confirmPasswordInput.current?.focus()
            confirmPasswordInput.current?.select()
        }
    }, [oldPassword, newPassword, confirmPassword, confirmPasswordInput, enqueueSnackbar])

    React.useEffect(() => {
        abortControllerRef.current = new AbortController()
        return () => {
            abortControllerRef.current.abort()
        }
    }, [])

    return (
        <Paper sx={{
            p: {
                xs: 1,
                sm: 2,
            },
        }}>
            <BlueArea>
                <Typography
                    textAlign='left'
                    sx={{
                        fontWeight: 'bold'
                    }}
                >プロフィール</Typography>
            </BlueArea>
            <Paper
                variant="outlined"
                sx={{
                    p: 2
                }}
            >
                <Stack
                    spacing={1}
                    sx={{
                        maxWidth: "450px",
                        mx: "auto",
                        my: 2,
                    }}
                >
                    <Box>
                        <InputMailAddress
                            value={mailAddress1}
                            onChange={(e) => {
                                setMailAddress1(e.target.value)
                            }}
                            label="メールアドレス(PC)"
                            required={false}
                        />
                    </Box>
                    <Box>
                        <InputMailAddress
                            value={mailAddress2}
                            onChange={(e) => {
                                setMailAddress2(e.target.value)
                            }}
                            label="メールアドレス(携帯)"
                            required={false}
                        />
                    </Box>
                    <Box>
                        <Button
                            variant="contained"
                            onClick={doEditProfile}
                        >メールアドレス変更</Button>
                    </Box>
                </Stack>
            </Paper>
            <Paper
                variant="outlined"
                sx={{
                    m: 2,
                    p: 2
                }}
            >
                <Box
                    component='form'
                    onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                        e.preventDefault()
                        doChangePassword()
                    }}
                >
                    <Stack
                        spacing={1}
                        sx={{
                            maxWidth: "450px",
                            mx: "auto",
                            my: 2,
                        }}
                    >

                        <Box>
                            <InputPassword
                                id="old_password"
                                value={oldPassword}
                                onChange={(e) => {
                                    setOldPassword(e.target.value)
                                }}
                                label="古いパスワード"
                                required={false}
                            />
                        </Box>
                        <Box>
                            <InputPassword
                                id="new_password"
                                value={newPassword}
                                onChange={(e) => {
                                    setNewPassword(e.target.value)
                                }}
                                label="新しいパスワード"
                                required={false}
                            />
                        </Box>
                        <Box>
                            <InputPassword
                                id="confirm_password"
                                ref={confirmPasswordInput}
                                value={confirmPassword}
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value)
                                }}
                                label="新しいパスワード（再入力）"
                                required={false}
                            />
                        </Box>
                        <Box>
                            <Button
                                variant="contained"
                                type="submit"
                                disabled={oldPassword.length === 0 || newPassword.length === 0 || confirmPassword.length === 0}
                            >パスワード変更</Button>
                        </Box>
                    </Stack>

                </Box>
            </Paper>
        </Paper>
    )
}

export default ProfilePage
import { Backdrop, Box, Button, CircularProgress, Paper, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ApiError } from "../../Api/ApiError"
import { ForgotCheck as ForgotCheckAPI } from "../../Api/Mypage"
import BlueArea from "../atoms/BlueArea"

const ForgotCheck: React.FC = () => {
    let { enqueueSnackbar } = useSnackbar()
    let navigate = useNavigate()
    let { id, key } = useParams()

    const [isComplete, setIsComplete] = React.useState<boolean>()

    React.useEffect(() => {
        if (id && key) {
            ForgotCheckAPI({
                id: id,
                key: key
            }).then(result => {
                setIsComplete(true);
            }).catch((e: any) => {
                if (e.__CANCEL__) {
                    console.info("canceled")
                    return;
                } else if (e instanceof ApiError) {
                    enqueueSnackbar(e.message, { variant: "error" })
                } else if (e instanceof Error) {
                    enqueueSnackbar(e.message, { variant: "error" })
                } else {
                    enqueueSnackbar("通信に失敗しました", { variant: "error" })
                }
                setIsComplete(false);
            })
        }
    }, [id, key])

    if (isComplete === undefined) {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    return (
        <Paper sx={{
            p: {
                xs: 1,
                sm: 2,
            },
        }}>
            {isComplete ? (
                <BlueArea>
                    <Typography
                        textAlign='left'
                    >パスワードの再発行が完了しました。</Typography>
                </BlueArea>
            ) : (
                <BlueArea>
                    <Typography
                        textAlign='left'
                    >パスワードの再発行に失敗しました。</Typography>
                </BlueArea>
            )}
            <Box
                sx={{
                    maxWidth: '600px',
                    mx: 'auto'
                }}
            >
                <Button
                    variant="outlined"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault()
                        navigate("/login")
                    }}
                    fullWidth
                    sx={{
                        my: 1
                    }}
                >戻る</Button>
            </Box>
        </Paper>
    )
}

export default ForgotCheck
import { Grid, SxProps, Typography } from "@mui/material";
import React from "react";
import { StudioAdjustment } from "../../Objects/StudioAdjustment";

export interface StudioAdjustmentTotalProps {
    studioAdjustmentList: StudioAdjustment[]
    sx?: SxProps
}

const StudioAdjustmentTotal: React.FC<StudioAdjustmentTotalProps> = ({
    studioAdjustmentList,
    sx,
}) => {

    /**
     * View
     */
    return (
        <Grid container
            sx={sx}
        >
            <Grid item
                xs={6}
            >
                <Typography>スタジオ代合計</Typography>
            </Grid>
            <Grid item
                xs={6}
            >
                <Typography align="right">{studioAdjustmentList.reduce((sum: number, item: StudioAdjustment) => {
                    return sum + item.chrptn_charge
                }, 0).toLocaleString()}円</Typography>
            </Grid>
        </Grid>
    )
}

export default StudioAdjustmentTotal
import { Box, Button, Stack, Typography } from "@mui/material"
import React from "react"
import BlueArea from "../atoms/BlueArea"

export interface RegisterStep1Props {
    result?: boolean
    onClick: (value: boolean) => void
    onReset: () => void
}
const RegisterStep1: React.FC<RegisterStep1Props> = ({
    result,
    onClick,
    onReset,
}) => {

    return (
        <Box>
            <BlueArea>
                <Typography
                    textAlign='left'
                    sx={{
                        fontWeight: 'bold'
                    }}
                >Step1.以前にBLstudioの会員証を作ったことはありますか？</Typography>
            </BlueArea>
            {result === undefined ? (
                <Stack
                    direction='row'
                    spacing={2}
                    justifyContent='center'
                >
                    <Button
                        variant='outlined'
                        onClick={() => {
                            onClick(true)
                        }}
                    >はい</Button>
                    <Button
                        variant='outlined'
                        onClick={() => {
                            onClick(false)
                        }}
                    >いいえ</Button>
                </Stack>
            ) : (
                <Box
                    textAlign='right'
                    sx={{
                        mx: 2
                    }}
                >
                    <Button
                        onClick={() => {
                            onReset()
                        }}
                    >{result ? 'はい' : 'いいえ'}</Button>
                </Box>
            )}
        </Box>
    )
}

export default RegisterStep1
import { Box, Button, Link, Paper, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import React from "react"
import { useNavigate } from "react-router-dom"
import { ApiError } from "../../Api/ApiError"
import { login, loginResult } from "../../Api/Mypage"
import { UserObjectStore } from "../../ObjectStore/UserObjectStore"
import BlueArea from "../atoms/BlueArea"
import InputMailAddress from "../atoms/InputMailAddress"
import InputPassword from "../atoms/InputPassword"

const SigninPage: React.FC = () => {
    let { enqueueSnackbar } = useSnackbar()
    let { userObjectState, userObjectDispatch } = React.useContext(UserObjectStore)
    let navigate = useNavigate()

    let [inputMailAddress, setInputMailAddress] = React.useState("")
    let [inputPassword, setInputPassword] = React.useState("")

    const [doingLogin, setDoingLogin] = React.useState<boolean>(false)

    const abortControllerRef = React.useRef(new AbortController())

    const doLogin = React.useCallback(() => {
        setDoingLogin(true)
        login({
            mail: inputMailAddress,
            password: inputPassword
        }, {signal: abortControllerRef.current.signal}).then((result: loginResult) => {
            if (result.ctm_id) {
                userObjectDispatch({
                    type: "login",
                    payload: {
                        ctm_id: result.ctm_id
                    }
                })
            }
        }).catch((e: any) => {
            if( e.__CANCEL__ ) {
                console.info("canceled")
            } else if (e instanceof ApiError) {
                enqueueSnackbar(e.message, { variant: "error" })
            } else if (e instanceof Error) {
                enqueueSnackbar(e.message, { variant: "error" })
            }else {
                enqueueSnackbar("通信に失敗しました", { variant: "error" })
            }
        }).finally(() => {
            setDoingLogin(false)
        })
    }, [inputMailAddress, inputPassword, enqueueSnackbar, userObjectDispatch])

    React.useEffect(() => {
        if (userObjectState.ctm_id) {
            navigate("/")
        }
    }, [navigate, userObjectState])

    React.useEffect(() => {
        abortControllerRef.current = new AbortController()
        return () => {
            abortControllerRef.current.abort()
        }
    }, [])

    return (
        <Paper sx={{
            p: {
                xs: 1,
                sm: 2,
            },
        }}>
            <BlueArea>
                <Typography variant="body1" component="p" textAlign="left" sx={{ fontWeight: 'bold', }}>ご利用ありがとうございます</Typography>
            </BlueArea>
            <Paper
                variant="outlined"
                sx={{
                    p: 3,
                }}
            >
                <Box
                    component='form'
                    onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                        e.preventDefault()
                        doLogin()
                    }}
                    sx={{
                        maxWidth: '600px',
                        mx: 'auto'
                    }}
                >
                    <InputMailAddress
                        value={inputMailAddress}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setInputMailAddress(e.target.value)
                        }}
                    />
                    <InputPassword
                        value={inputPassword}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setInputPassword(e.target.value)
                        }}
                    />
                    <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        disabled={doingLogin}
                        sx={{
                            my: 1
                        }}
                    >ログイン</Button>
                    <Button
                        variant="outlined"
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.preventDefault()
                            navigate('/register')
                        }}
                        fullWidth
                        disabled={doingLogin}
                        sx={{
                            my: 1
                        }}
                    >新規登録</Button>
                    <Box
                        sx={{
                            my: 1,
                            textAlign: 'right'
                        }}
                    >
                        <Link
                            component="button"
                            variant="body1"
                            disabled={doingLogin}
                            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                                e.preventDefault()
                                navigate('/forgot')
                            }}
                        >[パスワードをお忘れの方]</Link>
                    </Box>
                </Box>
            </Paper>
        </Paper>
    )
}

export default SigninPage
import axios, { AxiosRequestConfig } from "axios"
import { ApiError } from "./ApiError"

axios.defaults.withCredentials = true
axios.defaults.xsrfHeaderName = 'X-CSRF-Token'

export interface Addr {
    pref: string
    city: string
    town: string
}

export interface ZipcodeProps {
    code: string
}
export interface ZipcodeResults {
    addr: Addr[]
}
export const Zipcode = async (props: ZipcodeProps, config?: AxiosRequestConfig): Promise<ZipcodeResults> => {
    try {

        let res = await axios.get(
            process.env.REACT_APP_API_ENDPOINT + 'zipcode', {
            ...config,
            params: {
                code: props.code
            }
        }
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }
        return {
            addr: res.data.data.map((res: any) => {
                return {
                    pref: res.pref ?? "",
                    city: res.city ?? "",
                    town: res.town ?? ""
                }
            })
        }
    } catch (error) {
        throw error
    }
}
import { Box, Button, CssBaseline, Divider, Grid, Typography } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"
import BLstudioLogo from "../../Objects/BLstudioLogo"

interface MypageHeaderProps {
    title?: string
    subtitle?: string
    showLogout?: boolean
    onLogout?: React.MouseEventHandler<HTMLButtonElement>
    disableLogout?: boolean
}
const MypageHeader: React.FC<MypageHeaderProps> = ({
    title,
    subtitle,
    showLogout,
    onLogout,
    disableLogout = false,
}) => {
    return (
        <Box
            sx={{
                // backgroundColor: (theme) => theme.palette.secondary.dark,
                backgroundColor: "common.black",
                color: "common.white",
            }}
        >
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    maxWidth: "900px",
                    mx: "auto",
                    p: 0.5,
                }}
            >
                <Grid item md>
                    <Typography
                        variant="h6"
                        component="h1"
                        textAlign="left"
                    >{title}{subtitle && " - " + subtitle}</Typography>
                </Grid>
                {showLogout &&
                    <Grid item md={1.5}>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={onLogout}
                            fullWidth
                            disabled={disableLogout}
                        >ログアウト</Button>
                    </Grid>
                }
            </Grid>
        </Box>
    )
}

interface MypageNaviProps {
    showMenu?: boolean
}
const MypageNavi: React.FC<MypageNaviProps> = ({
    showMenu,
}) => {
    let navigate = useNavigate()
    return (
        <Box
            sx={{
                backgroundColor: "common.white",
            }}
        >
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    maxWidth: "900px",
                    mx: "auto",
                    py: 1,
                }}
            >
                <Grid item xs={12} sm={4}>
                    <BLstudioLogo />
                </Grid>
                {showMenu &&
                    <Grid item xs={12} sm={8}>
                        <Grid container
                            spacing={1}
                            alignItems="stretch"
                            sx={{
                                px: {
                                    xs: 1,
                                    sm: 1,
                                    md: 0,
                                }
                            }}
                        >
                            <Grid item xs={6} sm={6} md={3}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    size="small"
                                    sx={{
                                        height: "100%"
                                    }}
                                    onClick={() => {
                                        navigate("/")
                                    }}
                                >トップ</Button>
                            </Grid>
                            <Grid item xs={6} sm={6} md={3}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    size="small"
                                    sx={{
                                        height: "100%"
                                    }}
                                    onClick={() => {
                                        navigate("/profile")
                                    }}
                                >プロフィール</Button>
                            </Grid>
                            <Grid item xs={6} sm={6} md={3}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    size="small"
                                    sx={{
                                        height: "100%"
                                    }}
                                    onClick={() => {
                                        navigate("/reserve")
                                    }}
                                >スタジオ予約</Button>
                            </Grid>
                            <Grid item xs={6} sm={6} md={3}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    size="small"
                                    sx={{
                                        height: "100%"
                                    }}
                                    onClick={() => {
                                        navigate("/list")
                                    }}
                                >予約履歴</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
            <Divider
                sx={{
                    borderBottomWidth: "5px",
                    borderBottomColor: (theme) => theme.palette.primary.dark,
                }}
            />
        </Box>
    )
}

export interface MypageTemplateProps {
    children: React.ReactNode
    isLogin: boolean
    onLogout?: React.MouseEventHandler<HTMLButtonElement>
    disableLogout?: boolean
}
const MypageTemplate: React.FC<MypageTemplateProps> = ({
    children,
    isLogin,
    onLogout,
    disableLogout = false,
}) => {
    return (
        <Box
            sx={{
                backgroundColor: (theme) => theme.palette.grey[100],
            }}
        >
            <CssBaseline />
            <MypageHeader
                title="BLstudio"
                showLogout={isLogin}
                onLogout={onLogout}
                disableLogout={disableLogout}
            />
            <MypageNavi
                showMenu={isLogin}
            />
            <Box
                sx={{
                    p: {
                        xs: 1,
                        sm: 2,
                    },
                    maxWidth: "900px",
                    mx: "auto",
                }}
            >
                {children}
            </Box>
        </Box>
    )
}
export default MypageTemplate
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Paper, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { ApiError } from "../../Api/ApiError";
import { getReserveConfirmData, getReserveConfirmDataResult } from "../../Api/Mypage";
import { EquipmentAdjustment } from "../../Objects/EquipmentAdjustment";
import { ReserveData } from "../../Objects/ReserveData";
import { StudioAdjustment } from "../../Objects/StudioAdjustment";
import EquipmentAdjustmentDetail from "./EquipmentAdjustmentDetail";
import EquipmentAdjustmentTotal from "./EquipmentAdjustmentTotal";
import ReserveConditions from "./ReserveConditions";
import ReserveTotal from "./ReserveTotal";
import StudioAdjustmentDetail from "./StudioAdjustmentDetail";
import StudioAdjustmentTotal from "./StudioAdjustmentTotal";
import StudioItem from "./StudioItem";

export interface ConfirmReserveDialogProps {
    open: boolean
    onClose: () => void
    reserveData?: ReserveData
    onReserve: () => void
    disabled?: boolean
}

const ConfirmReserveDialog: React.FC<ConfirmReserveDialogProps> = ({
    open,
    onClose,
    reserveData,
    onReserve,
    disabled = false,
}) => {
    let { enqueueSnackbar } = useSnackbar()

    let [isLoading, setIsLoading] = React.useState<boolean>(false)

    let [dialog, setDialog] = React.useState<boolean>(false)
    let [studioAdjustmentList, setStudioAdjustmentList] = React.useState<StudioAdjustment[]>()
    let [equipmentAdjustmentList, setEquipmentAdjustmentList] = React.useState<EquipmentAdjustment[]>()

    const abortControllerRef = React.useRef(new AbortController())

    React.useEffect(() => {
        if (open && reserveData) {
            setIsLoading(true)
            setDialog(false)
            getReserveConfirmData({
                reserveData: reserveData
            }, {signal: abortControllerRef.current.signal}).then((result: getReserveConfirmDataResult) => {
                setIsLoading(false)
                setStudioAdjustmentList(result.studioAdjustmentList)
                setEquipmentAdjustmentList(result.equipmentAdjustmentList)
                setDialog(true)
            }).catch((e: any) => {
                if( e.__CANCEL__ ) {
                    console.info("canceled")
                } else if (e instanceof ApiError) {
                    enqueueSnackbar(e.message, { variant: "error" })
                } else if (e instanceof Error) {
                    enqueueSnackbar(e.message, { variant: "error" })
                }else {
                    enqueueSnackbar("通信に失敗しました", { variant: "error" })
                }
                setIsLoading(false)
            })
        }
    }, [open, reserveData, enqueueSnackbar])

    React.useEffect(() => {
        abortControllerRef.current = new AbortController()
        return () => {
            abortControllerRef.current.abort()
        }
    }, [])

    /**
     * View
     */
    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                onClose={() => {
                    onClose()
                    setDialog(false)
                }}
                TransitionProps={{
                    onExited: () => {
                        setStudioAdjustmentList(undefined)
                        setEquipmentAdjustmentList(undefined)
                    }
                }}
                open={dialog}
                fullWidth
            >
                <DialogTitle>予約確認</DialogTitle>
                <DialogContent>
                    <Typography
                        variant="body1"
                        component="p"
                        textAlign="left"
                        color="primary"
                        sx={{
                            fontWeight: "bold"
                        }}>予約条件</Typography>
                    <Paper
                        variant="outlined"
                        sx={{
                            p: 1
                        }}
                    >
                        <ReserveConditions
                            reserveData={reserveData}
                            align="left"
                        />
                    </Paper>
                    {reserveData?.reservableStudio &&
                        <>
                            <Typography
                                variant="body1"
                                component="p"
                                textAlign="left"
                                color="primary"
                                sx={{
                                    fontWeight: "bold",
                                    mt: 2,
                                }}
                            >予約スタジオ</Typography>
                            <Grid container
                                spacing={1}
                            >
                                <Grid item
                                    xs={12}
                                    sm={5}
                                >
                                    <StudioItem
                                        studioData={reserveData?.reservableStudio}
                                    />
                                </Grid>
                                {(studioAdjustmentList && studioAdjustmentList.length>0) &&
                                    <>
                                        <Grid item
                                            xs={12}
                                            sm={7}
                                        >
                                            <Paper
                                                variant="outlined"
                                                sx={{
                                                    p: 1
                                                }}
                                            >
                                                <StudioAdjustmentDetail
                                                    studioAdjustmentList={studioAdjustmentList}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item
                                            xs={12}
                                        >
                                            <Paper
                                                variant="outlined"
                                                sx={{
                                                    p: 1
                                                }}
                                            >
                                                <StudioAdjustmentTotal
                                                    studioAdjustmentList={studioAdjustmentList}
                                                />
                                            </Paper>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </>
                    }
                    {equipmentAdjustmentList &&
                        <>
                            <Typography
                                variant="body1"
                                component="p"
                                textAlign="left"
                                color="primary"
                                sx={{
                                    fontWeight: "bold",
                                    mt: 2
                                }}
                            >レンタル機材</Typography>
                            <Grid container>
                                <Grid item
                                    xs={12}
                                >
                                    <EquipmentAdjustmentDetail
                                        equipmentAdjustmentList={equipmentAdjustmentList}
                                    />
                                </Grid>
                                <Grid item
                                    xs={12}
                                >
                                    <Paper
                                        variant="outlined"
                                        sx={{
                                            p: 1
                                        }}
                                    >
                                        <EquipmentAdjustmentTotal
                                            equipmentAdjustmentList={equipmentAdjustmentList}
                                        />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </>
                    }

                    <Divider
                        sx={{
                            my: 2,
                        }}
                    />

                    {studioAdjustmentList && equipmentAdjustmentList &&
                        <Paper
                            variant="outlined"
                            sx={{
                                p: 1,
                            }}
                        >
                            <ReserveTotal
                                studioAdjustmentList={studioAdjustmentList}
                                equipmentAdjustmentList={equipmentAdjustmentList}
                            />
                        </Paper>
                    }


                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            onClose()
                            setDialog(false)
                        }}
                        disabled={disabled}
                    >キャンセル</Button>
                    <Button
                        onClick={() => {
                            onReserve()
                        }}
                        variant="contained"
                        disabled={disabled}
                    >予約</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default ConfirmReserveDialog
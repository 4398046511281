import { parse } from "date-fns"

export interface Student {
    student_id?: number
    student_name?: string
    ctm_id?: number
    create_at?: Date
    delete_at?: Date
}
export const ObjectToStudent = ({
    student_id,
    student_name,
    ctm_id,
    create_at,
    delete_at,
}: {
    student_id?: number | string
    student_name?: string
    ctm_id?: number | string
    create_at?: string
    delete_at?: string
}): Student => {
    return {
        student_id: typeof student_id === 'string' ? parseInt(student_id) : student_id,
        student_name: student_name,
        ctm_id: typeof ctm_id === 'string' ? parseInt(ctm_id) : ctm_id,
        create_at: create_at ? parse(create_at, "yyyy-MM-dd HH:mm:ss", new Date()) : undefined,
        delete_at: delete_at ? parse(delete_at, "yyyy-MM-dd HH:mm:ss", new Date()) : undefined,
    }
}
import { Card, CardActionArea, CardContent, CardMedia, Chip, Grid, Stack } from "@mui/material";
import React from "react";
import { ReserveData } from '../../Objects/ReserveData';
import { ReserveDetail } from "../../Objects/ReserveDetail";
import { UserObjectStore } from "../../ObjectStore/UserObjectStore";
import TypographyDate from "../atoms/TypographyDate";
import TypographyStudio from "../atoms/TypographyStudio";
import TypographyTime from "../atoms/TypographyTime";

export interface ReserveCardItemProps {
    reserveData: ReserveData | ReserveDetail
    onClick?: () => void
}
const ReserveCardItem: React.FC<ReserveCardItemProps> = ({
    reserveData,
    onClick,
}) => {
    let { userObjectState } = React.useContext(UserObjectStore)

    /**
     * View
     */

    return (
        <Card>
            <CardActionArea
                onClick={() => {
                    onClick && onClick()
                }}
            >
                <Grid container>
                    <Grid item xs={12} sm={4}>
                        <CardMedia
                            component="img"
                            src={'data:image/png;base64,' + reserveData.reserveStudio?.stdimg_data}
                            sx={{
                                height: {
                                    xs: "100px",
                                    sm: "100%",
                                },
                                objectFit: "cover",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <CardContent>
                            <Stack>
                                {reserveData.date &&
                                    <TypographyDate
                                        date={reserveData.date}
                                        typographyProps={{
                                            align: 'left'
                                        }}
                                    />
                                }
                                {reserveData.startTime && reserveData.durationTime &&
                                    <TypographyTime
                                        startTime={reserveData.startTime}
                                        durationTime={reserveData.durationTime}
                                        typographyProps={{
                                            align: 'left'
                                        }}
                                    />
                                }
                                {reserveData.reserveStudio &&
                                    <TypographyStudio
                                        studioData={reserveData.reserveStudio}
                                        typographyProps={{
                                            align: 'left'
                                        }}
                                    />
                                }
                                {reserveData.lessonReserve &&
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                    >
                                        <Chip label={"オンラインレッスン / " + (reserveData.lessonReserve.teacher?.ctm_id === userObjectState.ctm_id ? reserveData.lessonReserve.student?.student_name : reserveData.lessonReserve.teacher?.teacher_name+" 先生")} />
                                    </Stack>
                                }
                            </Stack>

                        </CardContent>

                    </Grid>
                </Grid>
            </CardActionArea>
        </Card>
    )
}

export default ReserveCardItem
import React from "react"
import { Paper, SxProps } from "@mui/material"
import { Theme } from "@mui/system"

export interface BlueAreaProps {
    children: React.ReactNode
    sx?: SxProps<Theme>
}

const BlueArea: React.FC<BlueAreaProps> = ({
    children,
    sx,
}) => {
    return (
        <Paper
            sx={{
                p: { xs: 2, md: 3 },
                mb: 2,
                backgroundColor: (theme) => theme.palette.mode === 'light' ? 'rgb(247,252,255)' : 'rgb(247,252,255)',
                ...sx
            }}
            variant="outlined"
        >
            {children}
        </Paper>
    )
}

export default BlueArea
import { Box, Divider, Pagination, Paper, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import React from "react"
import { ApiError } from "../../Api/ApiError"
import { Students, StudentsResult } from "../../Api/Mypage"
import { Student } from "../../Objects/Student"
import StudentList from "../organisms/StudentList"

const TeacherPage: React.FC = () => {
    let { enqueueSnackbar } = useSnackbar()

    let [studentListPageIndex, SetStudentListPageIndex] = React.useState<number>(0)
    let [studentListLimit] = React.useState<number>(10)

    let [isLoading, setIsLoading] = React.useState<boolean>(false)

    let [studentList, setStudentList] = React.useState<Student[]>([])
    let [studentListCount, setStudentListCount] = React.useState<number>()

    const abortControllerRef = React.useRef(new AbortController())

    let loadStudentList = React.useCallback((limit: number, offset: number) => {
        setIsLoading(true)
        Students({
            limit: limit,
            offset: offset,
        }, {signal: abortControllerRef.current.signal}).then((result: StudentsResult) => {
            setStudentList(result.students)
            setStudentListCount(result.students_count)
        }).catch((e: any) => {
            if( e.__CANCEL__ ) {
                console.info("canceled")
            } else if (e instanceof ApiError) {
                enqueueSnackbar(e.message, { variant: "error" })
            } else if (e instanceof Error) {
                enqueueSnackbar(e.message, { variant: "error" })
            }else {
                enqueueSnackbar("通信に失敗しました", { variant: "error" })
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }, [enqueueSnackbar])

    React.useEffect(() => {
        loadStudentList(studentListLimit, studentListPageIndex * studentListLimit)
    }, [studentListLimit, studentListPageIndex, loadStudentList])

    React.useEffect(() => {
        abortControllerRef.current = new AbortController()
        return () => {
            abortControllerRef.current.abort()
        }
    }, [])

    return (
        <Paper sx={{
            p: {
                xs: 1,
                sm: 2,
            },
        }}>
            <Typography
                sx={{
                    textAlign: 'left',
                    color: '#333399'
                }}
            >オンラインレッスン生一覧</Typography>
            <Divider sx={{ mb: 2 }} />
            <StudentList
                students={studentList}
                isLoading={isLoading}
            />
            <Box
                sx={{
                    my: 2
                }}
            >
                <Pagination
                    count={studentListCount ? Math.ceil(studentListCount / studentListLimit) : 0}
                    page={studentListPageIndex + 1}
                    onChange={(e: React.ChangeEvent<unknown>, page: number) => {
                        SetStudentListPageIndex(page - 1)
                    }}
                />
            </Box>
        </Paper>
    )
}

export default TeacherPage
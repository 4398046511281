import { Box, Button, Stack, Typography } from "@mui/material"
import React from "react"
import BlueArea from "../atoms/BlueArea"

export interface RegisterStep4Props {
    result?: boolean
    onClick: (value: boolean) => void
    onReset: () => void
}
const RegisterStep4: React.FC<RegisterStep4Props> = ({
    result,
    onClick,
    onReset,
}) => {

    return (
        <Box>
            <BlueArea>
                <Typography
                    textAlign='left'
                    sx={{
                        fontWeight: 'bold'
                    }}
                >下のボタンから、登録を行なってください。</Typography>
            </BlueArea>
            {result === undefined ? (
                <Stack
                    direction='row'
                    spacing={2}
                    justifyContent='center'
                >
                    <Button
                        variant='outlined'
                        onClick={() => {
                            onClick(false)
                        }}
                    >初めてのご利用の方</Button>
                    <Button
                        variant='outlined'
                        onClick={() => {
                            onClick(true)
                        }}
                    >すでに会員証をお持ちの方</Button>
                </Stack>
            ) : (
                <Box
                    textAlign='right'
                    sx={{
                        mx: 2
                    }}
                >
                    <Button
                        onClick={() => {
                            onReset()
                        }}
                    >{result ? 'すでに会員証をお持ちの方' : '初めてのご利用の方'}</Button>
                </Box>
            )}
        </Box>
    )
}

export default RegisterStep4
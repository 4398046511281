import { Time } from "../Helper/Time/Time"

/**
 * interface StudioAdjustment
 */
export interface StudioAdjustment {
    start: Time
    end: Time
    item_number: number
    apiece: number
    chrcate_id: number
    chrcate_name: string
    chrptn_charge: number
    list_comment: string
    extra: string
    is_settled: boolean
}
export const ObjectToStudioAdjustment = ({
    start,
    end,
    item_number,
    apiece,
    chrcate_id,
    chrcate_name,
    chrptn_charge,
    list_comment,
    extra,
    is_settled,
}: {
    start: string,
    end: string,
    item_number: number | string,
    apiece: number | string,
    chrcate_id: number | string,
    chrcate_name: string,
    chrptn_charge: number | string,
    list_comment: string,
    extra: string,
    is_settled: string,
}): StudioAdjustment => {
    return {
        start: new Time(start),
        end: new Time(end),
        item_number: typeof item_number === 'string' ? parseInt(item_number) : item_number,
        apiece: typeof apiece === 'string' ? parseInt(apiece) : apiece,
        chrcate_id: typeof chrcate_id === 'string' ? parseInt(chrcate_id) : chrcate_id,
        chrcate_name: chrcate_name,
        chrptn_charge: typeof chrptn_charge === 'string' ? parseInt(chrptn_charge) : chrptn_charge,
        list_comment: list_comment,
        extra: extra,
        is_settled: typeof is_settled === "string" ? is_settled === "1" : false,
    }
}
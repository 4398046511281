import { Card, CardActions, CardContent, Typography } from "@mui/material";
import React from "react";
import { Student } from "../../Objects/Student";

export interface StudentCardItemProps {
    student: Student
    children?: React.ReactElement
}

const StudentCardItem: React.FC<StudentCardItemProps> = ({
    student,
    children,
}) => {


    /**
     * View
     */
    return (
        <Card>
            <CardContent
                sx={{
                    p: 1
                }}
            >
                <Typography
                    variant="body1"
                    textAlign="left"
                >{student.student_name}</Typography>
            </CardContent>
            {children &&
                <CardActions>{children}</CardActions>
            }
        </Card>
    )
}

export default StudentCardItem
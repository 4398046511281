import { Box, Button, CircularProgress, Grid, Stack, TextField, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import React from "react"
import { ApiError } from "../../Api/ApiError"
import { SendRegMail } from "../../Api/Mypage"
import BlueArea from "../atoms/BlueArea"

export interface RegisterStep2bProps {
    onSubmit: () => void
}
const RegisterStep2b: React.FC<RegisterStep2bProps> = ({
    onSubmit,
}) => {
    let { enqueueSnackbar } = useSnackbar()

    const [mailAddress, setMailAddress] = React.useState<string>("")
    const [isSending, setIsSending] = React.useState<boolean>(false)
    const abortControllerRef = React.useRef(new AbortController())

    React.useEffect(() => {
        abortControllerRef.current = new AbortController()
        return () => {
            abortControllerRef.current.abort()
        }
    }, [])

    const doSendRegMail = React.useCallback(() => {
        if (mailAddress) {
            setIsSending(true)
            SendRegMail({
                mail: mailAddress
            }, {signal: abortControllerRef.current.signal}).then(() => {
                onSubmit()
            }).catch((e: any) => {
                if( e.__CANCEL__ ) {
                    console.info("canceled")
                } else if (e instanceof ApiError) {
                    enqueueSnackbar(e.message, { variant: "error" })
                } else if (e instanceof Error) {
                    enqueueSnackbar(e.message, { variant: "error" })
                }else {
                    enqueueSnackbar("通信に失敗しました", { variant: "error" })
                }
            }).finally(() => {
                setIsSending(false)
            })
        }
    }, [mailAddress, enqueueSnackbar, onSubmit])

    return (
        <Box>
            <BlueArea>
                <Typography
                    textAlign='left'
                    sx={{
                        fontWeight: 'bold'
                    }}
                >Step2.BLstudioの仮会員登録を行います。</Typography>
                <Typography
                    textAlign='left'
                >メールアドレスを入力するか、空メールを送信してください。</Typography>
            </BlueArea>
            <Box
                component='form'
                onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault()
                    doSendRegMail()
                }}
                sx={{
                    my: 2,
                    mx: 'auto',
                    px: 2,
                    py: 1,
                    width: { xs: '100%', md: '80%' },
                }}
            >
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant="outlined"
                            href={`mailto:` + process.env.REACT_APP_SYSTEM_REGISTER_MAIL}
                            fullWidth
                        >空メール送信</Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Stack flexDirection={"row"} alignItems={"stretch"}>
                            <TextField
                                label="メールアドレス"
                                name='mail'
                                value={mailAddress ? mailAddress : ""}
                                onChange={(e) => {
                                    setMailAddress(e.target.value)
                                }}
                                fullWidth
                                size="small"
                            />
                            <Button
                                variant="outlined"
                                type="submit"
                                disabled={isSending}
                            >{isSending ? <CircularProgress size={18} color="inherit" /> : "登録"}</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>

        </Box>
    )
}

export default RegisterStep2b
import { Box, Divider, Pagination, Paper, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import React from "react"
import { ApiError } from "../../Api/ApiError"
import { Teachers, TeachersResult } from "../../Api/Mypage"
import { Teacher } from "../../Objects/Teacher"
import TeacherList from "../organisms/TeacherList"

const StudentPage: React.FC = () => {
    let { enqueueSnackbar } = useSnackbar()
    let [teacherListPageIndex, setTeacherListPageIndex] = React.useState<number>(0)
    let [teacherListLimit] = React.useState<number>(10)

    let [isLoading, setIsLoading] = React.useState<boolean>(false)

    let [teacherList, setTeacherList] = React.useState<Teacher[]>([])
    let [teacherListCount, setTeacherListCount] = React.useState<number>()

    const abortControllerRef = React.useRef(new AbortController())

    let loadTeacherList = React.useCallback((limit: number, offset: number) => {
        setIsLoading(true)
        Teachers({
            limit: limit,
            offset: offset,
        }, {signal: abortControllerRef.current.signal}).then((result: TeachersResult) => {
            setTeacherList(result.teachers)
            setTeacherListCount(result.teachers_count)
        }).catch((e: any) => {
            if( e.__CANCEL__ ) {
                console.info("canceled")
            } else if (e instanceof ApiError) {
                enqueueSnackbar(e.message, { variant: "error" })
            } else if (e instanceof Error) {
                enqueueSnackbar(e.message, { variant: "error" })
            }else {
                enqueueSnackbar("通信に失敗しました", { variant: "error" })
            }
        }).finally(() => {
            setIsLoading(false)
        })
    },[enqueueSnackbar])

    React.useEffect(() => {
        loadTeacherList(teacherListLimit, teacherListPageIndex * teacherListLimit);
    }, [teacherListLimit, teacherListPageIndex, loadTeacherList])

    React.useEffect(() => {
        abortControllerRef.current = new AbortController()
        return () => {
            abortControllerRef.current.abort()
        }
    }, [])

    return (
        <Paper sx={{
            p: {
                xs: 1,
                sm: 2,
            },
        }}>
            <Typography
                sx={{
                    textAlign: 'left',
                    color: '#333399'
                }}
            >オンラインレッスン一覧</Typography>
            <Divider sx={{ mb: 2 }} />
            <TeacherList
                teachers={teacherList}
                isLoading={isLoading}
            />
            <Box
                sx={{
                    my: 2
                }}
            >
                <Pagination
                    count={teacherListCount ? Math.ceil(teacherListCount/teacherListLimit) : 0}
                    page={teacherListPageIndex+1}
                    onChange={(e: React.ChangeEvent<unknown>, page: number) => {
                        setTeacherListPageIndex(page-1)
                    }}
                />
            </Box>
        </Paper>
    )
}

export default StudentPage
import { Box, Stack, SxProps, Typography } from "@mui/material";
import React from "react";
import { StudioAdjustment } from "../../Objects/StudioAdjustment";

export interface StudioAdjustmentDetailProps {
    studioAdjustmentList: StudioAdjustment[]
    sx?: SxProps
}

const StudioAdjustmentDetail: React.FC<StudioAdjustmentDetailProps> = ({
    studioAdjustmentList,
    sx,
}) => {


    /**
     * View
     */

    return (
        <Stack
            sx={sx}
            spacing={1}
        >
            {studioAdjustmentList.map((studioAdjustment: StudioAdjustment, index: number) => (
                <Box key={index}>
                    <Typography variant="body2">{studioAdjustment.chrcate_name}({studioAdjustment.start.toStringHM()}〜{studioAdjustment.end.toStringHM()})</Typography>
                    <Typography variant="body2" align="right">{studioAdjustment.chrptn_charge.toLocaleString()}円</Typography>
                </Box>
            ))}
        </Stack>
    )
}

export default StudioAdjustmentDetail
import { Typography } from "@mui/material"
import React from "react"
import { ReservableStudio } from "../../Objects/ReservableStudio"
import StudioItem from "../molecules/StudioItem"

export interface ReservableStudioItemProps {
    reservableStudio: ReservableStudio
    onClick?: (reservableStudio: ReservableStudio) => void
}
const ReservableStudioItem: React.FC<ReservableStudioItemProps> = ({
    reservableStudio,
    onClick
}) => {

    return (
        <StudioItem
            studioData={reservableStudio}
            onClick={onClick ? () => {
                onClick(reservableStudio)
            } : undefined}
        >
            <>
                {reservableStudio.sum_charge &&
                    <Typography
                        sx={{
                            textAlign: 'right'
                        }}
                    >{reservableStudio.sum_charge.toLocaleString()}円</Typography>
                }
            </>
        </StudioItem>
    )
}

export default ReservableStudioItem
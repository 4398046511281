/**
 * interface StudioData
 */
export interface StudioData {
    std_id: number
    std_name: string
    str_id: number
    str_name: string
    sort: number
    std_area?: string
    stdimg_id: number
    stdimg_data: string
    stdimg_mime_type: string
}
export const ObjectToStudioData = ({
    std_id,
    std_name,
    str_id,
    str_name,
    sort,
    std_area,
    stdimg_id,
    stdimg_data,
    stdimg_mime_type,
}: {
    std_id: string | number
    std_name: string
    str_id: string | number
    str_name: string
    sort: string | number
    std_area?: string
    stdimg_id: string | number
    stdimg_data: string
    stdimg_mime_type: string
}) => {
    return {
        std_id: typeof std_id === 'string' ? parseInt(std_id) : std_id,
        std_name: std_name,
        str_id: typeof str_id === 'string' ? parseInt(str_id) : str_id,
        str_name: str_name,
        sort: typeof sort === 'string' ? parseInt(sort) : sort,
        std_area: std_area,
        stdimg_id: typeof stdimg_id === 'string' ? parseInt(stdimg_id) : stdimg_id,
        stdimg_data: stdimg_data,
        stdimg_mime_type: stdimg_mime_type,
    }
}
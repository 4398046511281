import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material"
import { format } from "date-fns"
import React from "react"
import { useNavigate } from "react-router-dom"
import { UserObjectStore } from "../../ObjectStore/UserObjectStore"
import BlueArea from "../atoms/BlueArea"
import NextReserves from "../organisms/NextReserves"

const IndexPage: React.FC = () => {
    let { userObjectState } = React.useContext(UserObjectStore)
    let navigate = useNavigate()


    return (
        <Paper sx={{
            p: {
                xs: 1,
                sm: 2,
            },
        }}>
            <Grid container
                spacing={2}
                direction='row-reverse'
            >
                <Grid item xs={12} sm={4}>
                    <Paper
                        sx={{
                            p: { xs: 1, md: 2 }
                        }}
                    >
                        <Stack
                            spacing={1}
                        >
                            <BlueArea
                                sx={{
                                    textAlign: 'left',
                                    m: 0,
                                    mb: 2,
                                }}
                            >
                                <Typography sx={{
                                    fontWeight: 'bold',
                                }}>{userObjectState.customer_data?.name1} {userObjectState.customer_data?.name2} 様</Typography>
                                <Typography sx={{
                                    fontWeight: 'bold',
                                }}>マイページへようこそ！</Typography>
                            </BlueArea>
                            <Box>
                                <Typography sx={{
                                    textAlign: 'left',
                                    color: '#333399'
                                }}>現在のポイント</Typography>
                                <Typography sx={{
                                    textAlign: 'left',
                                }}>{userObjectState.customer_data?.point} ポイント</Typography>
                            </Box>
                            <Box>
                                <Typography sx={{
                                    textAlign: 'left',
                                    color: '#333399'
                                }}>会員有効期限</Typography>
                                <Typography sx={{
                                    textAlign: 'left',
                                }}>{userObjectState.customer_data?.validity_date ? format(userObjectState.customer_data.validity_date, "yyyy年MM月dd日") : "-"}</Typography>
                            </Box>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Box
                        sx={{
                            mb: 4
                        }}
                    >
                        <NextReserves />
                    </Box>
                    <Stack
                        spacing={2}
                        direction={{
                            xs: 'column',
                            sm: 'row'
                        }}
                    >
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                                navigate("/reserve")
                            }}
                        >スタジオ予約</Button>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                                navigate("/list")
                            }}
                        >予約履歴</Button>
                    </Stack>
                    <Stack
                        spacing={2}
                        sx={{
                            my: 2
                        }}
                    >
                        {typeof userObjectState.customer_data?.teacher_id === 'number' &&
                            <Box>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    onClick={() => {
                                        navigate("/teacher")
                                    }}
                                >講師ページ</Button>
                            </Box>
                        }
                        {typeof userObjectState.customer_data?.student_id === 'number' &&
                            <Box>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    onClick={() => {
                                        navigate("/lesson")
                                    }}
                                >オンラインレッスンページ</Button>
                            </Box>
                        }
                    </Stack>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default IndexPage
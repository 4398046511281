import { Grid, SxProps, Typography } from "@mui/material";
import React from "react";

export interface StudioCancelChargeProps {
    studioCancelCharge: number
    equipmentCancelCharge: number
    sx?: SxProps
}

const StudioCancelCharge: React.FC<StudioCancelChargeProps> = ({
    studioCancelCharge,
    equipmentCancelCharge,
    sx,
}) => {
    /**
     * View
     */
    return (
        <Grid container
            sx={sx}
        >
            <Grid item
                xs={6}
            >
                <Typography>キャンセル代合計</Typography>
            </Grid>
            <Grid item
                xs={6}
            >
                <Typography align="right">{(studioCancelCharge + equipmentCancelCharge).toLocaleString()}円</Typography>
            </Grid>
        </Grid>
    )
}

export default StudioCancelCharge
import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";
import { ReservableStudio } from "../../Objects/ReservableStudio";
import { StudioData } from "../../Objects/StudioData";

export interface StudioItemProps {
    studioData: StudioData | ReservableStudio
    onClick?: () => void
    children?: React.ReactElement
    size?: "small" | "large"
}

const StudioItem: React.FC<StudioItemProps> = ({
    studioData,
    onClick,
    children,
    size,
}) => {

    let cardMedia = studioData.stdimg_data ? (
        <CardMedia
            component="img"
            src={'data:image/png;base64,' + studioData.stdimg_data}
            height={120}
        />
    ) : null
    let cardContent = size === "small" ? null : (
        <CardContent
            sx={{
                textAlign: 'left',
            }}
        >
            <Typography
                variant='caption'
            >{studioData.str_name}</Typography>
            <Typography>{studioData.std_name}{studioData.std_area && (" / " + studioData.std_area)}</Typography>
            {children}
        </CardContent>
    )


    /**
     * View
     */

    return (
        <Card>
            {onClick ? (
                <CardActionArea
                    onClick={onClick ? (event: React.MouseEvent<HTMLButtonElement>) => {
                        onClick()
                    } : undefined}
                >
                    {cardMedia}
                    {cardContent}
                </CardActionArea>
            ) : (
                <>
                    {cardMedia}
                    {cardContent}
                </>
            )}
        </Card>
    )
}

export default StudioItem
/**
 * interface StudioTag
 */
export interface StudioTag {
    std_list: number[]
    stdtag_id: number
    stdtag_name: string
    stdtag_memo?: string
}
export const ObjectToStudioTag = ({
    std_list,
    stdtag_id,
    stdtag_name,
    stdtag_memo,
}: {
    std_list: string
    stdtag_id: string
    stdtag_name: string
    stdtag_memo?: string
}) => {
    return {
        std_list: std_list.split(',').map(value => parseInt(value)),
        stdtag_id: parseInt(stdtag_id),
        stdtag_name: stdtag_name,
        stdtag_memo: stdtag_memo ? stdtag_memo : undefined,
    }
}
import { parse } from "date-fns"
import { Time, timeLength } from "../Helper/Time/Time"
import { EquipmentAdjustment, ObjectToEquipmentAdjustment } from "./EquipmentAdjustment"
import { LessonReserve } from "./LessonReserve"
import { ReserveEquip } from "./ReserveEquip"
import { ObjectToStudioAdjustment, StudioAdjustment } from "./StudioAdjustment"
import { ObjectToStudioData, StudioData } from "./StudioData"

/**
 * interface ReserveDetail
 */
export interface ReserveDetail {
    stdrv_id?: number
    date?: Date
    startTime?: Time
    durationTime?: Time
    numMember?: number
    reserveStudio?: StudioData
    reserveEquipList?: ReserveEquip[]
    studioAdjustmentList: StudioAdjustment[]
    equipmentAdjustmentList: EquipmentAdjustment[]
    lessonReserve?: LessonReserve
}
export const ObjectToReserveDetail = ({
    stdrv_id,
    reserve_date,
    start,
    end,
    number,
    std_id,
    std_name,
    std_area,
    stdimg_data,
    stdimg_id,
    str_id,
    str_name,
    sort,
    stdimg_mime_type,
    studioAdjustmentList,
    equipmentAdjustmentList,
    lesson_reserve,
}: {
    stdrv_id?: number | string
    reserve_date: string
    start: string
    end: string
    number: number | string
    std_id: number | string
    std_name: string
    std_area: string
    stdimg_data: string
    stdimg_id: number | string
    str_id: number | string
    str_name: string
    sort: string | number
    stdimg_mime_type: string
    studioAdjustmentList: any[],
    equipmentAdjustmentList: any[],
    lesson_reserve?: LessonReserve,
}): ReserveDetail => {
    let duration = timeLength(start, end)
    return {
        stdrv_id: typeof stdrv_id === 'string' ? parseInt(stdrv_id) : stdrv_id,
        date: parse(reserve_date, "yyyy-MM-dd", new Date()),
        startTime: new Time(start),
        durationTime: duration ? duration : undefined,
        numMember: typeof number === 'string' ? parseInt(number) : number,
        reserveStudio: ObjectToStudioData({
            std_id: std_id,
            std_name: std_name,
            str_id: str_id,
            str_name: str_name,
            sort: sort,
            std_area: std_area,
            stdimg_id: stdimg_id,
            stdimg_data: stdimg_data,
            stdimg_mime_type: stdimg_mime_type
        }),
        studioAdjustmentList: studioAdjustmentList.map(data => ObjectToStudioAdjustment(data)),
        equipmentAdjustmentList: equipmentAdjustmentList.map(data => ObjectToEquipmentAdjustment(data)),
        lessonReserve: lesson_reserve,
    }
}
import { Box, Button, Paper, Stack, Typography } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"

const NotfoundPage: React.FC = () => {
    return (
        <Paper sx={{
            p: {
                xs: 1,
                sm: 2,
            },
        }}>
            <Stack
                spacing={3}
                py={5}
            >
                <Box>
                    <Typography variant="h2" component={"p"} color="primary">404</Typography>
                    <Typography variant="h3" component={"p"} color="primary">Not Found...</Typography>
                </Box>
                <Box>
                    <Typography>お探しのページは見つかりませんでした。</Typography>
                </Box>
                <Box>
                    <Link to="/">Home</Link>
                </Box>
            </Stack>
        </Paper>
    )
}

export default NotfoundPage
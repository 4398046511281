import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import React from "react";
import { EquipData } from "../../Objects/EquipData";

export interface EquipCardItemProps {
    equipData: EquipData
    onClick: () => void
    children?: React.ReactElement
}

const EquipCardItem: React.FC<EquipCardItemProps> = ({
    equipData,
    onClick,
    children,
}) => {


    /**
     * View
     */
    return (
        <Card>
            <CardActionArea
                onClick={() => {
                    if (onClick) {
                        onClick()
                    }
                }}
            >
                <CardContent
                    sx={{
                        p: 1
                    }}>
                    <Typography
                        variant="body1"
                        display="block"
                        textAlign="left"
                    >{equipData.equ_name}</Typography>
                    <Typography
                        variant="caption"
                        display="block"
                        textAlign="left"
                        color="gray"
                    >
                        {equipData.equ_model}
                        {equipData.equ_maker ? "(" + equipData.equ_maker + ")" : (equipData.equ_model ? "" : "-")}
                    </Typography>
                    <Typography
                        variant="body2"
                        display="block"
                        textAlign="left"
                    >
                        {equipData.charge.toLocaleString()}円
                        {equipData.free_flug === 1 ? "(1台目無料)" : ""}
                    </Typography>
                    {children &&
                        children
                    }
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default EquipCardItem
import { parse } from "date-fns"

export interface Teacher {
    teacher_id?: number
    teacher_name?: string
    ctm_id?: number
    create_at?: Date
    delete_at?: Date
}
export const ObjectToTeacher = ({
    teacher_id,
    teacher_name,
    ctm_id,
    create_at,
    delete_at,
}: {
    teacher_id?: number | string
    teacher_name?: string
    ctm_id?: number | string
    create_at?: string
    delete_at?: string
}) => {
    return {
        teacher_id: typeof teacher_id === 'string' ? parseInt(teacher_id) : teacher_id,
        teacher_name: teacher_name,
        ctm_id: typeof ctm_id === 'string' ? parseInt(ctm_id) : ctm_id,
        create_at: create_at ? parse(create_at, "yyyy-MM-dd HH:mm:ss", new Date()) : undefined,
        delete_at: delete_at ? parse(delete_at, "yyyy-MM-dd HH:mm:ss", new Date()) : undefined,
    }
}
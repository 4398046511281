import React from 'react';
import './App.css';
import MypageTemplate from './components/templates/MypageTemplate';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import SigninPage from './components/pages/SigninPage';
import IndexPage from './components/pages/IndexPage';
import ProfilePage from './components/pages/ProfilePage';
import ReservePage from './components/pages/ReservePage';
import ReserveListPage from './components/pages/ReserveListPage';
import { Box } from '@mui/material';
import CopyRights from './components/atoms/CopyRights';
import { UserObjectStore } from './ObjectStore/UserObjectStore';
import { ApiError } from './Api/ApiError';
import { getCustomerData, getCustomerDataResult, isLogin, isLoginResult, Logout } from './Api/Mypage';
import { useSnackbar } from 'notistack';
import RegisterPage from './components/pages/RegisterPage';
import ForgotPage from './components/pages/ForgotPage';
import RegisterCheckPage from './components/pages/RegisterCheckPage';
import ForgotCompletePage from './components/pages/ForgotCompletePage';
import TeacherPage from './components/pages/TeacherPage';
import StudentPage from './components/pages/StudentPage';
import LessonReservePage from './components/pages/LessonReservePage';
import ForgotCheck from './components/pages/ForgotCheck';
import NotfoundPage from './components/pages/NotfoundPage';

interface RequireAuthProps {
    children: React.ReactNode
}
const RequireAuth: React.FC<RequireAuthProps> = ({
    children,
}) => {
    const location = useLocation()
    const { userObjectState } = React.useContext(UserObjectStore)

    if (userObjectState.ctm_id) {
        return <>{children}</>
    }
    return <Navigate to="/login" state={{ from: location }} replace />
}

function App() {
    const { userObjectState, userObjectDispatch } = React.useContext(UserObjectStore)
    let { enqueueSnackbar } = useSnackbar()

    let [isLoading, setIsLoading] = React.useState(true)
    const [isLogouting, setIsLogouting] = React.useState(false)

    const abortControllerRef = React.useRef(new AbortController())

    const doLogout = React.useCallback(() => {
        setIsLogouting(true)
        Logout({signal: abortControllerRef.current.signal}).finally(() => {
            userObjectDispatch({ type: 'logout' })
            setIsLogouting(false)
        })
    }, [userObjectDispatch])

    React.useEffect(() => {
        abortControllerRef.current = new AbortController()
        return () => {
            abortControllerRef.current.abort()
        }
    }, [])

    /**
     * ログイン状況を確認
     * ログインしていれば、ctm_idを登録する
     * ログインしていなければ、ページを表示する
     */
    React.useEffect(() => {
        setIsLoading(true)
        isLogin({signal: abortControllerRef.current.signal}).then((result: isLoginResult) => {
            if (result.ctm_id) {
                userObjectDispatch({
                    type: "login",
                    payload: {
                        ctm_id: result.ctm_id
                    }
                })
            } else {
                setIsLoading(false)
            }
        }).catch((e: any) => {
            if( e.__CANCEL__ ) {
                console.info("canceled")
            } else if (e instanceof ApiError) {
                enqueueSnackbar(e.message, { variant: "error" })
            } else if (e instanceof Error) {
                enqueueSnackbar(e.message, { variant: "error" })
            }else {
                enqueueSnackbar("通信に失敗しました", { variant: "error" })
            }
            setIsLoading(false)
        })
    }, [userObjectDispatch, enqueueSnackbar])

    /**
     * ctm_idが変わったら顧客データを取得する
     */
    React.useEffect(() => {
        if (userObjectState.ctm_id) {
            setIsLoading(true)
            getCustomerData({signal: abortControllerRef.current.signal}).then((customerData: getCustomerDataResult) => {
                userObjectDispatch({
                    type: 'customerData',
                    payload: {
                        customer_data: customerData
                    }
                })
            }).catch((e: any) => {
                if( e.__CANCEL__ ) {
                    console.info("canceled")
                } else if (e instanceof ApiError) {
                    enqueueSnackbar(e.message, { variant: "error" })
                } else if (e instanceof Error) {
                    enqueueSnackbar(e.message, { variant: "error" })
                }else {
                    enqueueSnackbar("通信に失敗しました", { variant: "error" })
                }
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }, [userObjectDispatch, userObjectState.ctm_id, enqueueSnackbar])

    return (
        <Box className="App">
            <BrowserRouter>
                <MypageTemplate
                    isLogin={userObjectState.ctm_id !== undefined}
                    onLogout={() => {
                        doLogout()
                    }}
                    disableLogout={isLogouting}
                >
                    {!isLoading &&
                        <Routes>
                            <Route path="/login" element={<SigninPage />} />
                            <Route path="/index.php" element={<Navigate replace to="/register" />} />
                            <Route path="/register">
                                <Route path=""  element={<RegisterPage />} />
                                <Route path=":key/:subkey"  element={<RegisterCheckPage />} />
                            </Route>
                            <Route path="/forgot">
                                <Route path="" element={<ForgotPage />} />
                                <Route path="complete" element={<ForgotCompletePage />} />
                                <Route path=":id/:key"  element={<ForgotCheck />} />
                            </Route>
                            <Route path="/" element={<RequireAuth><IndexPage /></RequireAuth>} />
                            <Route path="/profile" element={<RequireAuth><ProfilePage /></RequireAuth>} />
                            <Route path="/reserve" element={<RequireAuth><ReservePage /></RequireAuth>} />
                            <Route path="/list" element={<RequireAuth><ReserveListPage /></RequireAuth>} />
                            <Route path="/teacher">
                                <Route path ="" element={<RequireAuth><TeacherPage /></RequireAuth>} />
                                <Route path="reserve/:student_id_param" element={<RequireAuth><LessonReservePage /></RequireAuth>} />
                            </Route>
                            <Route path="/lesson">
                                <Route path="" element={<RequireAuth><StudentPage /></RequireAuth>} />
                                <Route path="reserve/:teacher_id_param" element={<RequireAuth><LessonReservePage /></RequireAuth>} />
                            </Route>
                            <Route path="*" element={<NotfoundPage />} />
                        </Routes>
                    }
                    <CopyRights />
                </MypageTemplate>
            </BrowserRouter>
        </Box>
    );
}

export default App;

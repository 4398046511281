import { Edit } from "@mui/icons-material";
import { IconButton, Typography, TypographyProps } from "@mui/material";
import React from "react";
import { StudioData } from "../../Objects/StudioData";

export interface TypographyStudioProps {
    studioData: StudioData,
    typographyProps?: TypographyProps
    onEdit?: () => void
}
const TypographyStudio: React.FC<TypographyStudioProps> = ({
    studioData,
    typographyProps,
    onEdit,
}) => {

    /**
     * View
     */

    return (
        <Typography
            {...typographyProps}
        >
            {studioData.str_name} {studioData.std_name}{studioData.std_area && ("/" + studioData.std_area)}
            {onEdit &&
                <IconButton aria-label="変更" color="primary" size="small"
                    onClick={onEdit}
                >
                    <Edit fontSize="inherit" />
                </IconButton>
            }
        </Typography>
    )
}

export default TypographyStudio
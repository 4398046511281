import { Backdrop, Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Slide, Typography } from "@mui/material"
import { TransitionProps } from "@mui/material/transitions"
import { useSnackbar } from "notistack"
import React from "react"
import { useNavigate } from "react-router-dom"
import { ApiError } from "../../Api/ApiError"
import { getAllStoreName, getAllStoreNameResult, getReservableStudioList, getReservableStudioListResult, getStudioTagList, getStudioTagListResult, getWorkingtime, getWorkingtimeResult, Reserve, ReserveResult } from "../../Api/Mypage"
import { Time } from "../../Helper/Time/Time"
import { EquipData } from "../../Objects/EquipData"
import { ReservableStudio } from "../../Objects/ReservableStudio"
import { ReserveData } from "../../Objects/ReserveData"
import { ReserveEquip } from "../../Objects/ReserveEquip"
import { StoreData } from "../../Objects/StoreData"
import { StudioTag } from "../../Objects/StudioTag"
import ConfirmReserveDialog from "../molecules/ConfirmReserveDialog"
import NumberSelectDialog from "../molecules/NumberSelectDialog"
import ReservableStudioItem from "../organisms/ReservableStudioItem"
import SelectedReserveData from "../organisms/SelectedReserveData"
import SelectReserveDate from "../organisms/SelectReserveDate"
import SelectReserveDetail from "../organisms/SelectReserveDetail"
import SelectReserveEquip from "../organisms/SelectReserveEquip"

const DialogTransition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
) {
    // return <Collapse ref={ref} {...props} />
    return <Slide direction="up" ref={ref} {...props} />
})

const ReservePage: React.FC = () => {
    let { enqueueSnackbar } = useSnackbar()
    let navigate = useNavigate()

    let [isLoading, setIsLoading] = React.useState<boolean>(false)
    let [showSelectReserveDate, setShowSelectReserveDate] = React.useState<boolean>(true)
    let [showSelectReserveDetail, setShowSelectReserveDetail] = React.useState<boolean>(false)
    let [showSelectReserveEquip, setShowSelectReserveEquip] = React.useState<boolean>(false)

    let [reserveData, setReserveData] = React.useState<ReserveData>()
    let [workingtimeResult, setWorkingtimeResult] = React.useState<getWorkingtimeResult>()

    let [searchStore, setSearchStore] = React.useState<StoreData[]>([])
    let [searchStudioTag, setSearchStudioTag] = React.useState<StudioTag[]>([])

    let [allStoreNames, setAllStoreNames] = React.useState<StoreData[]>([])
    let [studioTagList, setStudioTagList] = React.useState<StudioTag[]>([])

    let [showReservableStudioDialog, setShowReservableStudioDialog] = React.useState<boolean>(false)
    let [reservableStudioList, setReservableStudioList] = React.useState<ReservableStudio[]>()

    let [numberSelectDialog, setNumberSelectDialog] = React.useState<boolean>(false)
    let [numberSelectValue, setNumberSelectValue] = React.useState<number>(0)
    let [numberSelectEqu, setNumberSelectEqu] = React.useState<EquipData>()

    let [confirmReserveDialog, setConfirmReserveDialog] = React.useState<boolean>(false)

    let [completeReserveDialog, setCompleteReserveDialog] = React.useState<boolean>(false)

    const [reserving, setReserving] = React.useState<boolean>(false)

    const abortControllerRef = React.useRef(new AbortController())

    const doReserve = React.useCallback(() => {
        if (reserveData) {
            setReserving(true)
            Reserve({
                reserveData: reserveData
            }, {signal: abortControllerRef.current.signal}).then((result: ReserveResult) => {
                switch (result.sendmail) {
                    case 'NOT_MEMBERSHIP':
                        enqueueSnackbar("予約に失敗しました 会員情報を確認できませんでした", { variant: "error" })
                        break;
                    case 'NO_MAIL':
                        enqueueSnackbar("予約が完了しました メールアドレスの登録が見つからなかったため確認メールは送信されませんでした", { variant: "info" })
                        break;
                    case 'SEND':
                        enqueueSnackbar("予約が完了しました 登録メールアドレスに確認メールが送信されました", { variant: "success" })
                        break;
                    case 'FALSE':
                    default:
                        enqueueSnackbar("予約に失敗しました", { variant: "error" })
                        break;
                }
                setConfirmReserveDialog(false)
                setCompleteReserveDialog(true)
            }).catch((e: any) => {
                if( e.__CANCEL__ ) {
                    console.info("canceled")
                } else if (e instanceof ApiError) {
                    enqueueSnackbar(e.message, { variant: "error" })
                } else if (e instanceof Error) {
                    enqueueSnackbar(e.message, { variant: "error" })
                }else {
                    enqueueSnackbar("通信に失敗しました", { variant: "error" })
                }
            }).finally(() => {
                setReserving(false)
            })
        }
    }, [reserveData, enqueueSnackbar])

    const loadReservableStudioList = React.useCallback(() => {
        setIsLoading(true)
        if (reserveData?.date && reserveData?.startTime && reserveData?.durationTime && reserveData?.numMember) {
            getReservableStudioList({
                reserve_date: reserveData.date,
                start_time: reserveData.startTime,
                end_time: new Time(reserveData.startTime.toSec() + reserveData.durationTime.toSec()),
                number: reserveData.numMember,
                store_list: searchStore,
                stdtag_list: searchStudioTag
            }, {signal: abortControllerRef.current.signal}).then((result: getReservableStudioListResult) => {
                setReservableStudioList(result.list)
                setIsLoading(false)
                setShowReservableStudioDialog(true)
            }).catch((e: any) => {
                if( e.__CANCEL__ ) {
                    console.info("canceled")
                } else if (e instanceof ApiError) {
                    enqueueSnackbar(e.message, { variant: "error" })
                } else if (e instanceof Error) {
                    enqueueSnackbar(e.message, { variant: "error" })
                }else {
                    enqueueSnackbar("通信に失敗しました", { variant: "error" })
                }
            })
        }
    }, [reserveData, searchStore, searchStudioTag, enqueueSnackbar])

    let showPage = React.useCallback((page: string) => {
        switch (page) {
            case "reserveDate":
                setShowSelectReserveDate(true)
                setShowSelectReserveDetail(false)
                setShowSelectReserveEquip(false)
                break;
            case "reserveDetail":
                setShowSelectReserveDetail(true)
                setShowSelectReserveDate(false)
                setShowSelectReserveEquip(false)
                break;
            case "reserveEquip":
                setShowSelectReserveEquip(true)
                setShowSelectReserveDetail(false)
                setShowSelectReserveDate(false)
                break;

            default:
                break;
        }
    }, [])

    let regReserveEquip = React.useCallback((reserveEquip: ReserveEquip) => {
        let _reserveEquipList: ReserveEquip[] | undefined = reserveData?.reserveEquipList ? reserveData.reserveEquipList : []
        if (_reserveEquipList) {
            _reserveEquipList = _reserveEquipList.filter(_reserveEquip => reserveEquip.equip.equ_id !== _reserveEquip.equip.equ_id)
        }
        if (reserveEquip.number > 0) {
            _reserveEquipList?.push(reserveEquip)
        }
        if (_reserveEquipList.length === 0) {
            _reserveEquipList = undefined
        }
        let _reserveData: ReserveData = {
            ...reserveData,
            reserveEquipList: _reserveEquipList
        }
        setReserveData(_reserveData)

    }, [reserveData])


    React.useEffect(() => {
        getAllStoreName({signal: abortControllerRef.current.signal}).then((result: getAllStoreNameResult) => {
            setAllStoreNames(result.list)
            setSearchStore(result.list)
        }).catch((e: any) => {
            if( e.__CANCEL__ ) {
                console.info("canceled")
            } else if (e instanceof ApiError) {
                enqueueSnackbar(e.message, { variant: "error" })
            } else if (e instanceof Error) {
                enqueueSnackbar(e.message, { variant: "error" })
            }else {
                enqueueSnackbar("通信に失敗しました", { variant: "error" })
            }
        })
        getStudioTagList({}, {signal: abortControllerRef.current.signal}).then((result: getStudioTagListResult) => {
            setStudioTagList(result.list)
        }).catch((e: any) => {
            if( e.__CANCEL__ ) {
                console.info("canceled")
            } else if (e instanceof ApiError) {
                enqueueSnackbar(e.message, { variant: "error" })
            } else if (e instanceof Error) {
                enqueueSnackbar(e.message, { variant: "error" })
            }else {
                enqueueSnackbar("通信に失敗しました", { variant: "error" })
            }
        })
    }, [enqueueSnackbar])

    React.useEffect(() => {
        if (reserveData?.date) {
            getWorkingtime({
                reserve_date: reserveData.date
            }, {signal: abortControllerRef.current.signal}).then((result: getWorkingtimeResult) => {
                setWorkingtimeResult(result)
            }).catch((e: any) => {
                if( e.__CANCEL__ ) {
                    console.info("canceled")
                } else if (e instanceof ApiError) {
                    enqueueSnackbar(e.message, { variant: "error" })
                } else if (e instanceof Error) {
                    enqueueSnackbar(e.message, { variant: "error" })
                }else {
                    enqueueSnackbar("通信に失敗しました", { variant: "error" })
                }
            })
        } else {
            setWorkingtimeResult(undefined)
        }
        setReserveData(prev => {
            return {
                ...prev,
                date: reserveData?.date,
                startTime: undefined,
                reservableStudio: undefined,
            }
        })
    }, [reserveData?.date, enqueueSnackbar])

    React.useEffect(() => {
        setReserveData(prev => {
            return {
                ...prev,
                startTime: reserveData?.startTime,
                durationTime: reserveData?.durationTime,
                reservableStudio: undefined,
            }
        })
    }, [reserveData?.startTime, reserveData?.durationTime])

    React.useEffect(() => {
        setReserveData(prev => {
            return {
                ...prev,
                reservableStudio: reserveData?.reservableStudio,
                reserveEquipList: undefined,
            }
        })
    }, [reserveData?.reservableStudio])

    React.useEffect(() => {
        abortControllerRef.current = new AbortController()
        return () => {
            abortControllerRef.current.abort()
        }
    }, [])

    return (
        <Paper sx={{
            p: {
                xs: 1,
                sm: 2,
            },
        }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container
                direction="row-reverse"
                spacing={2}
            >
                <Grid item xs={12} sm={4}>
                    <SelectedReserveData reserveData={reserveData}
                        onEditDate={() => {
                            showPage("reserveDate")
                        }}
                        onEditTime={() => {
                            showPage("reserveDetail")
                        }}
                        onEditNumMember={() => {
                            showPage("reserveDetail")
                        }}
                        onEditReservableStudio={() => {
                            loadReservableStudioList()
                        }}
                        onEditReserveEquip={(reserveEquip: ReserveEquip) => {
                            setNumberSelectEqu(reserveEquip.equip)
                            setNumberSelectValue(reserveEquip.number)
                            setNumberSelectDialog(true)
                        }}
                        onConfirmReaserve={() => {
                            if (reserveData) {
                                setConfirmReserveDialog(true)
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Collapse in={showSelectReserveDate}>
                        <SelectReserveDate
                            selected={reserveData?.date}
                            onSelect={(value?: Date) => {
                                let _reserveData: ReserveData = {
                                    ...reserveData,
                                    date: value
                                }
                                setReserveData(_reserveData)
                                showPage("reserveDetail")
                            }}
                        />
                    </Collapse>
                    <Collapse in={showSelectReserveDetail}>
                        <SelectReserveDetail
                            open={workingtimeResult?.open}
                            close={workingtimeResult?.close}
                            unit={workingtimeResult?.unit}
                            startTime={reserveData?.startTime}
                            setStartTime={(value?: Time) => {
                                let _reserveData: ReserveData = {
                                    ...reserveData,
                                    startTime: value
                                }
                                setReserveData(_reserveData)
                            }}
                            durationTime={reserveData?.durationTime}
                            setDurationTime={(value?: Time) => {
                                let _reserveData: ReserveData = {
                                    ...reserveData,
                                    durationTime: value
                                }
                                setReserveData(_reserveData)
                            }}
                            numMember={reserveData?.numMember}
                            setNumMember={(value?: number) => {
                                let _reserveData: ReserveData = {
                                    ...reserveData,
                                    numMember: value
                                }
                                setReserveData(_reserveData)
                            }}
                            searchStore={searchStore}
                            setSearchStore={(value: StoreData[]) => {
                                setSearchStore(value)
                            }}
                            searchStudioTag={searchStudioTag}
                            setSearchStudioTag={(value: StudioTag[]) => {
                                setSearchStudioTag(value)
                            }}
                            allStoreNames={allStoreNames}
                            studioTagList={studioTagList}
                            onSubmit={() => {
                                loadReservableStudioList()
                            }}
                        />
                    </Collapse>
                    <Collapse in={showSelectReserveEquip}>
                        <SelectReserveEquip
                            reserve_date={reserveData?.date}
                            start_time={reserveData?.startTime}
                            duration_time={reserveData?.durationTime}
                            number={reserveData?.numMember}
                            studio={reserveData?.reservableStudio}
                            reserveEquipList={reserveData?.reserveEquipList}
                            onSelect={(equData: EquipData) => {
                                setNumberSelectEqu(equData)
                                setNumberSelectValue(0)
                                setNumberSelectDialog(true)
                            }}
                        />
                    </Collapse>
                </Grid>
            </Grid>

            <Dialog
                open={showReservableStudioDialog}
                onClose={() => {
                    setShowReservableStudioDialog(false)
                }}
                TransitionComponent={DialogTransition}
                TransitionProps={{
                    onExited: () => {
                        setReservableStudioList(undefined)
                    }
                }}
            >
                <DialogTitle>ご希望のスタジオを選択してください。</DialogTitle>
                <DialogContent>
                    <Grid container
                        spacing={1}
                    >
                        {(reservableStudioList && reservableStudioList.length === 0) ? (
                            <Grid item xs={12}>
                                <Typography>スタジオが見つかりません</Typography>
                            </Grid>
                        ) : (
                            <>
                                {reservableStudioList?.map((reservableStudio: ReservableStudio, index: number) => (
                                    <Grid item xs={12} sm={6} key={index}>
                                        <ReservableStudioItem
                                            reservableStudio={reservableStudio}
                                            onClick={(reservableStudio: ReservableStudio) => {
                                                let _reserveData: ReserveData = {
                                                    ...reserveData,
                                                    reservableStudio: reservableStudio
                                                }
                                                setReserveData(_reserveData)
                                                showPage("reserveEquip")
                                                setShowReservableStudioDialog(false)
                                            }}
                                        />
                                    </Grid>
                                ))}
                            </>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setShowReservableStudioDialog(false)
                    }}>キャンセル</Button>
                </DialogActions>
            </Dialog>

            <NumberSelectDialog
                open={numberSelectDialog}
                selectedValue={numberSelectValue}
                onClose={(value: number) => {
                    if (numberSelectEqu) {
                        let reserveEquip: ReserveEquip = {
                            equip: numberSelectEqu,
                            number: value
                        }
                        regReserveEquip(reserveEquip)
                    }
                    setNumberSelectDialog(false)
                }}
                item_number={numberSelectEqu ? numberSelectEqu.item_number : 0}
            />

            <ConfirmReserveDialog
                open={confirmReserveDialog}
                onClose={() => {
                    setConfirmReserveDialog(false)
                }}
                reserveData={reserveData}
                onReserve={() => {
                    doReserve()
                }}
                disabled={reserving}
            />

            <Dialog
                open={completeReserveDialog}
                fullWidth
            >
                <DialogTitle>ご予約ありがとうございます</DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => {
                            navigate("/")
                        }}
                    >トップへ戻る</Button>
                </DialogActions>
            </Dialog>
        </Paper>
    )
}

export default ReservePage
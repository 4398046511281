import { Visibility, VisibilityOff } from "@mui/icons-material"
import { FormControl, IconButton, Input, InputAdornment, InputLabel, SxProps } from "@mui/material"
import React from "react"

export interface InputPasswordProps {
    value: string
    onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    label?: string
    required?: boolean
    sx?: SxProps
    id?: string
}

const InputPassword = React.forwardRef<HTMLHeadingElement, InputPasswordProps>(({
    value,
    onChange,
    label,
    required,
    sx,
    id,

}, ref) => {

    let [showPassword, setShowPassword] = React.useState(false)

    return (
        <FormControl
            fullWidth
            variant="standard"
            sx={sx !== undefined ? sx : {
                my: 1,
            }}
        >
            <InputLabel htmlFor={id === undefined ? "password" : id}>{label !== undefined ? label : "パスワード"}</InputLabel>
            <Input
                id={id === undefined ? "password" : id}
                type={showPassword ? 'text' : 'password'}
                value={value}
                onChange={onChange}
                fullWidth
                required={required !== undefined ? required : true}
                inputRef={ref}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                                setShowPassword(!showPassword)
                            }}
                            onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                                event.preventDefault()
                            }}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    )
})

export default InputPassword
import { Collapse, Paper, Stack, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import React from "react"
import { ApiError } from "../../Api/ApiError"
import { CheckRegisterd, CheckRegisterdResult, RegMypage } from "../../Api/Mypage"
import { MemberIdKindType } from "../../Objects/MemberIdKindType"
import BlueArea from "../atoms/BlueArea"
import RegisterStep1 from "../organisms/RegisterStep1"
import RegisterStep2a from "../organisms/RegisterStep2a"
import RegisterStep2b from "../organisms/RegisterStep2b"
import RegisterStep3a from "../organisms/RegisterStep3a"

const RegisterPage: React.FC = () => {
    let { enqueueSnackbar } = useSnackbar()

    let [isRegisterd, setIsRegisterd] = React.useState<boolean>()
    let [memberIdKind, setMemberIdKind] = React.useState<MemberIdKindType>()
    let [code, setCode] = React.useState<string>()
    let [oldMemberId, setOldMemberId] = React.useState<string>()
    let [pronunciation1, setPronunciation1] = React.useState<string>()
    let [pronunciation2, setPronunciation2] = React.useState<string>()
    let [birthday, setBirthday] = React.useState<Date>()
    let [tel, setTel] = React.useState<string>()
    let [isSending, setIsSending] = React.useState(false)

    let [sendMail, setSendMail] = React.useState<boolean>()

    let [token, setToken] = React.useState<string>()

    let [isComplete, setIsComplete] = React.useState(false)

    const abortControllerRef = React.useRef(new AbortController())

    let doResetRegisterdData = React.useCallback(() => {
        setMemberIdKind(undefined)
        setCode(undefined)
        setOldMemberId(undefined)
        setPronunciation1(undefined)
        setPronunciation2(undefined)
        setBirthday(undefined)
        setTel(undefined)
    }, [])

    const doRegMypage = React.useCallback(() => {
        if(token) {
            RegMypage({
                token: token
            }, {signal: abortControllerRef.current.signal}).then(() => {
                setIsComplete(true)
            }).catch((e: any) => {
                if( e.__CANCEL__ ) {
                    console.info("canceled")
                } else if (e instanceof ApiError) {
                    enqueueSnackbar(e.message, { variant: "error" })
                } else if (e instanceof Error) {
                    enqueueSnackbar(e.message, { variant: "error" })
                }else {
                    enqueueSnackbar("通信に失敗しました", { variant: "error" })
                }
            })
        }
    }, [token, enqueueSnackbar])

    const doCheckRegisterd = React.useCallback(() => {
        if (memberIdKind === undefined) {
            return;
        }
        if (memberIdKind === 'code' && code === undefined) {
            return;
        }
        if (memberIdKind === 'old_member_id' && oldMemberId === undefined) {
            return;
        }
        if (pronunciation1 === undefined) {
            return;
        }
        if (pronunciation2 === undefined) {
            return;
        }
        if (birthday === undefined) {
            return;
        }
        if (tel === undefined) {
            return;
        }
        setIsSending(true)
        CheckRegisterd({
            memberIdKind: memberIdKind,
            code: code,
            oldMemberId: oldMemberId,
            pronunciation1: pronunciation1,
            pronunciation2: pronunciation2,
            birthday: birthday,
            tel: tel
        }, {signal: abortControllerRef.current.signal}).then((result: CheckRegisterdResult) => {
            setSendMail(true)
            setToken(result.token)
        }).catch((e: ApiError) => {
            enqueueSnackbar(e.message, { variant: 'error' });
        }).finally(() => {
            setIsSending(false)
        })
    }, [memberIdKind, code, oldMemberId, pronunciation1, pronunciation2, birthday, tel, enqueueSnackbar])

    React.useEffect(() => {
        abortControllerRef.current = new AbortController()
        return () => {
            abortControllerRef.current.abort()
        }
    }, [])

    return (
        <Paper sx={{
            p: {
                xs: 1,
                sm: 2,
            },
        }}>
            {isComplete ? (
                <BlueArea>
                    {isRegisterd ? (
                        <>
                            <Typography>マイページ登録ありがとうございました。</Typography>
                            <Typography>登録されているメールアドレスにログインパスワードを送信しました。</Typography>
                        </>
                    ) : (
                        <>
                            <Typography>メールを送信しました。</Typography>
                        </>
                    )}
                </BlueArea>
            ) : (
                <Stack
                    spacing={1}
                >
                    <RegisterStep1
                        result={isRegisterd}
                        onClick={(value: boolean) => {
                            setIsRegisterd(value)
                        }}
                        onReset={() => {
                            setIsRegisterd(undefined)
                        }}
                    />
                    <Collapse in={isRegisterd === true}>
                        <RegisterStep2a
                            result={sendMail ? pronunciation1 + ' ' + pronunciation2 : undefined}
                            memberIdKind={memberIdKind}
                            code={code}
                            oldMemberId={oldMemberId}
                            pronunciation1={pronunciation1}
                            pronunciation2={pronunciation2}
                            birthday={birthday}
                            tel={tel}
                            onChangeMemberIdKind={(value?: MemberIdKindType) => {
                                setMemberIdKind(value)
                            }}
                            onChangeCode={(value?: string) => {
                                setCode(value)
                            }}
                            onChangeOldMemberId={(value?: string) => {
                                setOldMemberId(value)
                            }}
                            onChangePronunciation1={(value?: string) => {
                                setPronunciation1(value)
                            }}
                            onChangePronunciation2={(value?: string) => {
                                setPronunciation2(value)
                            }}
                            onChangeBirthday={(value?: Date) => {
                                setBirthday(value)
                            }}
                            onChangeTel={(value?: string) => {
                                setTel(value)
                            }}
                            onSubmit={() => {
                                doCheckRegisterd()
                            }}
                            onReset={() => {
                                doResetRegisterdData()
                                setSendMail(undefined)
                            }}
                            isSending={isSending}
                        />
                    </Collapse>
                    <Collapse in={sendMail === true}>
                        <RegisterStep3a
                            onSubmit={() => {
                                doRegMypage()
                            }}
                        />
                    </Collapse>
                    <Collapse in={isRegisterd === false}>
                        <RegisterStep2b
                            onSubmit={() => {
                                setIsComplete(true)
                            }}
                        />
                    </Collapse>
                </Stack>
            )}
        </Paper>
    )
}

export default RegisterPage
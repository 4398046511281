import { Box, Divider, Skeleton, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { ApiError } from "../../Api/ApiError";
import { RecentReserveList } from "../../Api/Mypage";
import { ReserveData } from "../../Objects/ReserveData";
import ReserveCardItem from "../molecules/ReserveCardItem";
import ReserveDetailDialog from "../molecules/ReserveDetailDialog";

const NextReserves: React.FC = () => {
    let { enqueueSnackbar } = useSnackbar()

    let [nextReserves, setNextReserves] = React.useState<ReserveData[]>()
    let [loadingNextReserve, setLoadingNextReserve] = React.useState(true)

    let [reserveDetailId, setReserveDetailId] = React.useState<number>()

    const abortControllerRef = React.useRef(new AbortController())

    let doReloadReserveList = React.useCallback(() => {
        RecentReserveList({signal: abortControllerRef.current.signal}).then((result) => {
            setNextReserves(result.reserveDatas)
            setLoadingNextReserve(false)
        }).catch((e: any) => {
            if( e.__CANCEL__ ) {
                console.info("canceled")
            } else if (e instanceof ApiError) {
                enqueueSnackbar(e.message, { variant: "error" })
            } else if (e instanceof Error) {
                enqueueSnackbar(e.message, { variant: "error" })
            }else {
                enqueueSnackbar("通信に失敗しました", { variant: "error" })
            }
        })
    }, [enqueueSnackbar])

    React.useEffect(() => {
        doReloadReserveList()
    }, [doReloadReserveList])

    React.useEffect(() => {
        abortControllerRef.current = new AbortController()
        return () => {
            abortControllerRef.current.abort()
        }
    }, [])

    /**
     * View
     */

    return (
        <Box>
            <Typography
                sx={{
                    textAlign: 'left',
                    color: '#333399'
                }}
            >次のご予約</Typography>
            <Divider sx={{ mb: 2 }} />
            {loadingNextReserve ? (
                <Skeleton
                    variant='rectangular'
                    height={60}
                />
            ) :
                (nextReserves && nextReserves.length > 0) ? (
                    <>
                        <Stack
                            spacing={1}
                        >
                            {nextReserves.map((reserveData: ReserveData, index: number) => (
                                <ReserveCardItem
                                    reserveData={reserveData}
                                    key={index}
                                    onClick={() => {
                                        setReserveDetailId(reserveData.stdrv_id)
                                    }}
                                />
                            ))}
                        </Stack>
                        <ReserveDetailDialog
                            open={reserveDetailId !== undefined}
                            onClose={() => {
                                setReserveDetailId(undefined)
                                doReloadReserveList()
                            }}
                            stdrv_id={reserveDetailId}
                        />
                    </>
                ) : (
                    <Typography>予約がありません</Typography>
                )
            }
        </Box>
    )
}

export default NextReserves
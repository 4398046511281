import { Card, CardActions, CardContent, Typography } from "@mui/material";
import React from "react";
import { Teacher } from "../../Objects/Teacher";

export interface TeacherCardItemProps {
    teacher: Teacher
    children?: React.ReactElement
}

const TeacherCardItem: React.FC<TeacherCardItemProps> = ({
    teacher,
    children,
}) => {


    /**
     * View
     */
    return (
        <Card>
            <CardContent
                sx={{
                    p: 1
                }}
            >
                <Typography
                    variant="body1"
                    textAlign="left"
                >{teacher.teacher_name} 先生</Typography>
            </CardContent>
            {children &&
                <CardActions>{children}</CardActions>
            }
        </Card>
    )
}

export default TeacherCardItem
import { Box, Button, Chip, Grid, MenuItem, Typography } from "@mui/material"
import React, { ReactElement } from "react"
import { Time } from "../../Helper/Time/Time"
import { StoreData } from "../../Objects/StoreData"
import { StudioTag } from "../../Objects/StudioTag"
import BlueArea from "../atoms/BlueArea"
import Select from "../atoms/Select"

export interface SelectReserveDetailProps {
    open?: Time
    close?: Time
    unit?: Time
    reservable?: number[]
    startTime?: Time
    setStartTime: (value?: Time) => void
    durationTime?: Time
    setDurationTime: (value?: Time) => void
    numMember?: number
    setNumMember: (value?: number) => void
    searchStore: StoreData[]
    setSearchStore: (value: StoreData[]) => void
    searchStudioTag: StudioTag[]
    setSearchStudioTag: (value: StudioTag[]) => void
    allStoreNames: StoreData[]
    studioTagList: StudioTag[]
    onSubmit: () => void
}
const SelectReserveDetail: React.FC<SelectReserveDetailProps> = ({
    open,
    close,
    unit,
    reservable,
    startTime,
    setStartTime,
    durationTime,
    setDurationTime,
    numMember,
    setNumMember,
    searchStore,
    setSearchStore,
    searchStudioTag,
    setSearchStudioTag,
    allStoreNames,
    studioTagList,
    onSubmit,
}) => {

    let [startTimeSelectOptions, setStartTimeSelectOptions] = React.useState<ReactElement[]>([])
    let [durationTimeSelectOptions, setDurationTimeSelectOptions] = React.useState<ReactElement[]>([])

    React.useEffect(() => {
        if (open && close && unit) {
            let _startTimeSelectOptions: ReactElement[] = []
            let _durationTimeSelectOptions: ReactElement[] = []
            for (let index = new Time(open); index.isBefore(close); index.add(unit)) {
                _startTimeSelectOptions.push(
                    <MenuItem
                        value={index.sec}
                        key={index.sec}
                        disabled={reservable ? !reservable.includes(index.sec/60) : false }
                    >{index.toStringAHM()}</MenuItem>
                )
            }
            for (let index = new Time("1:00:00"); index.isBefore(close); index.add(unit)) {
                _durationTimeSelectOptions.push(
                    <MenuItem value={index.sec} key={index.sec}>{index.toStgingLength()}</MenuItem>
                )
            }
            setStartTimeSelectOptions(_startTimeSelectOptions)
            setDurationTimeSelectOptions(_durationTimeSelectOptions)
        }
    }, [open, close, unit, reservable])

    return (
        <Box>
            <BlueArea>
                <Typography
                    sx={{
                        textAlign: "left",
                        fontWeight: "bold",
                    }}
                >ご希望の時間、人数、その他条件を指定してください。</Typography>
            </BlueArea>

            <Box
                component='form'
                onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault()
                    onSubmit()
                }}
                sx={{
                    maxWidth: '600px',
                    mx: 'auto',
                    px: 2
                }}
            >
                <Grid container
                    spacing={2}
                    direction={{
                        xs: 'column',
                        sm: 'row'
                    }}
                    justifyContent='center'
                >
                    <Grid item xs={12} sm={4}>
                        <Select
                            labelId="start_time"
                            label="開始時間"
                            value={startTime ? startTime.toSec() : ""}
                            onChange={e => {
                                setStartTime(e.target.value ? new Time(e.target.value as number) : undefined)
                            }}
                            formControlProps={{
                                sx: {
                                    width: '100%'
                                }
                            }}
                        >{startTimeSelectOptions}</Select>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Select
                            labelId="duration_time"
                            label="時間数"
                            value={durationTime ? durationTime.toSec() : ""}
                            onChange={e => {
                                setDurationTime(e.target.value ? new Time(e.target.value as number) : undefined)
                            }}
                            formControlProps={{
                                sx: {
                                    width: '100%'
                                }
                            }}
                        >{durationTimeSelectOptions}</Select>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Select
                            labelId="number"
                            label="人数"
                            value={numMember ? numMember : ""}
                            onChange={e => {
                                setNumMember(e.target.value ? e.target.value as number : undefined)
                            }}
                            formControlProps={{
                                sx: {
                                    width: '100%'
                                }
                            }}
                        >
                            <MenuItem value={1}>1人</MenuItem>
                            <MenuItem value={2}>2人</MenuItem>
                            <MenuItem value={3}>3人</MenuItem>
                            <MenuItem value={4}>4人</MenuItem>
                            <MenuItem value={5}>5人</MenuItem>
                            <MenuItem value={6}>6人</MenuItem>
                            <MenuItem value={7}>7人</MenuItem>
                            <MenuItem value={8}>8人</MenuItem>
                            <MenuItem value={9}>9人</MenuItem>
                            <MenuItem value={10}>10人以上</MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                <Typography
                    sx={{
                        mt: 2,
                        mb: 1,
                        textAlign: 'left'
                    }}
                >検索店舗範囲を指定</Typography>
                <Box
                    sx={{
                        textAlign: 'left',
                    }}
                >
                    {allStoreNames &&
                        allStoreNames.map((storeData: StoreData) => (
                            <Chip
                                key={storeData.str_id}
                                label={storeData.str_name}
                                variant={searchStore.includes(storeData) ? "filled" : "outlined"}
                                color={searchStore.includes(storeData) ? "primary" : "default"}
                                onClick={() => {
                                    if (searchStore.includes(storeData)) {
                                        let s = searchStore.filter(data => data !== storeData)
                                        setSearchStore(s)
                                    } else {
                                        let s = searchStore ? [...searchStore, storeData] : [storeData]
                                        setSearchStore(s)
                                    }
                                }}
                                sx={{
                                    margin: 0.5
                                }}
                            />
                        ))
                    }
                </Box>

                <Typography
                    sx={{
                        mt: 2,
                        mb: 1,
                        textAlign: 'left'
                    }}
                >スタジオ検索範囲を指定</Typography>
                <Box
                    sx={{
                        textAlign: 'left',
                    }}
                >
                    {studioTagList &&
                        studioTagList.map((studioTag: StudioTag) => (
                            <Chip
                                key={studioTag.stdtag_id}
                                label={studioTag.stdtag_name}
                                variant={searchStudioTag.includes(studioTag) ? "filled" : "outlined"}
                                color={searchStudioTag.includes(studioTag) ? "primary" : "default"}
                                onClick={() => {
                                    if (searchStudioTag.includes(studioTag)) {
                                        let s = searchStudioTag.filter(data => data !== studioTag)
                                        setSearchStudioTag(s)
                                    } else {
                                        let s = searchStudioTag ? [...searchStudioTag, studioTag] : [studioTag]
                                        setSearchStudioTag(s)
                                    }
                                }}
                                sx={{
                                    margin: 0.5
                                }}
                            />
                        ))
                    }
                </Box>

                <Box
                    sx={{
                        my: 3
                    }}
                >
                    <Button
                        variant="outlined"
                        type="submit"
                        disabled={[startTime, durationTime, numMember].some((value: any) => value === undefined) || searchStore.length === 0}
                    >スタジオを選択する</Button>
                </Box>

            </Box>
        </Box>
    )
}

export default SelectReserveDetail
import { Box, Button, Collapse, Grid, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import React from "react"
import { ApiError } from "../../Api/ApiError"
import { getEquipmentList, getEquipmentListResult } from "../../Api/Mypage"
import { Time } from "../../Helper/Time/Time"
import { EqucateData } from "../../Objects/EqucateData"
import { EquipData } from "../../Objects/EquipData"
import { ReservableStudio } from "../../Objects/ReservableStudio"
import { ReserveEquip } from "../../Objects/ReserveEquip"
import BlueArea from "../atoms/BlueArea"
import EquipCardItem from "../molecules/EquipCardItem"

export interface SelectReserveEquipProps {
    reserve_date?: Date
    start_time?: Time
    duration_time?: Time
    number?: number
    studio?: ReservableStudio
    reserveEquipList?: ReserveEquip[]
    onSelect: (equipData: EquipData) => void
}
const SelectReserveEquip: React.FC<SelectReserveEquipProps> = ({
    reserve_date,
    start_time,
    duration_time,
    number,
    studio,
    reserveEquipList,
    onSelect,
}) => {
    let { enqueueSnackbar } = useSnackbar()

    let [equcateList, setEqucateList] = React.useState<EqucateData[]>([])
    let [showcate, setShowcate] = React.useState<number>()

    const abortControllerRef = React.useRef(new AbortController())

    React.useEffect(() => {
        if (reserve_date && start_time && duration_time && number && studio) {
            getEquipmentList({
                reserve_date: reserve_date,
                start_time: start_time,
                end_time: new Time(start_time.toSec() + duration_time.toSec()),
                number: number,
                studio: studio
            }, {signal: abortControllerRef.current.signal}).then((value: getEquipmentListResult) => {
                setEqucateList(value.equcate_list)
            }).catch((e: any) => {
                if( e.__CANCEL__ ) {
                    console.info("canceled")
                } else if (e instanceof ApiError) {
                    enqueueSnackbar(e.message, { variant: "error" })
                } else if (e instanceof Error) {
                    enqueueSnackbar(e.message, { variant: "error" })
                }else {
                    enqueueSnackbar("通信に失敗しました", { variant: "error" })
                }
            })
        }
    }, [reserve_date, start_time, duration_time, number, studio, enqueueSnackbar])

    React.useEffect(() => {
        setShowcate(undefined)
    }, [reserveEquipList])

    React.useEffect(() => {
        abortControllerRef.current = new AbortController()
        return () => {
            abortControllerRef.current.abort()
        }
    }, [])

    return (
        <Box>
            <BlueArea>
                <Typography
                    sx={{
                        textAlign: "left",
                        fontWeight: "bold",
                    }}
                >レンタルする機材を選択してください。</Typography>
            </BlueArea>
            <Box
                sx={{
                    m: { xs: 2, md: 3 },
                }}
            >
                {equcateList.map((equcate: EqucateData) => {
                    return (
                        <Box key={"equcate-" + equcate.equcate_id}
                            sx={{
                                mb: 1
                            }}
                        >
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    if (showcate === equcate.equcate_id) {
                                        setShowcate(undefined)
                                    } else {
                                        setShowcate(equcate.equcate_id)
                                    }
                                }}
                                fullWidth
                            >{equcate.equcate_name}</Button>
                            <Collapse
                                in={showcate === equcate.equcate_id}
                            >
                                <Grid container
                                    spacing={1}
                                >
                                    {equcate.equ_list.map((equdata: EquipData) => (
                                        <Grid item
                                            key={"equ-" + equdata.equ_id}
                                            xs={12}
                                        >
                                            <EquipCardItem
                                                equipData={equdata}
                                                onClick={() => {
                                                    onSelect(equdata)
                                                }}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Collapse>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

export default SelectReserveEquip
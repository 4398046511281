import { Box, Button, CircularProgress, Paper, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import React from "react"
import { useNavigate } from "react-router-dom"
import { ApiError } from "../../Api/ApiError"
import { Forgot } from "../../Api/Mypage"
import BlueArea from "../atoms/BlueArea"
import InputMailAddress from "../atoms/InputMailAddress"

const ForgotPage: React.FC = () => {
    let [inputMailAddress, setInputMailAddress] = React.useState<string>("")
    let navigate = useNavigate()
    let { enqueueSnackbar } = useSnackbar()

    const [isLoading, setIsLoading] = React.useState<boolean>(false)

    const abortControllerRef = React.useRef(new AbortController())

    const doForgot = React.useCallback(() => {
        setIsLoading(true)
        if (inputMailAddress) {
            Forgot({
                mail: inputMailAddress
            }, {signal: abortControllerRef.current.signal}).then(() => {
                navigate("/forgot/complete")
            }).catch((e: any) => {
                if( e.__CANCEL__ ) {
                    console.info("canceled")
                } else if (e instanceof ApiError) {
                    enqueueSnackbar(e.message, { variant: "error" })
                } else if (e instanceof Error) {
                    enqueueSnackbar(e.message, { variant: "error" })
                }else {
                    enqueueSnackbar("通信に失敗しました", { variant: "error" })
                }
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }, [inputMailAddress, enqueueSnackbar, navigate])

    React.useEffect(() => {
        abortControllerRef.current = new AbortController()
        return () => {
            abortControllerRef.current.abort()
        }
    }, [])

    return (
        <Paper sx={{
            p: {
                xs: 1,
                sm: 2,
            },
        }}>
            <BlueArea>
                <Typography
                    textAlign='left'
                    sx={{
                        fontWeight: 'bold'
                    }}
                >パスワードを再発行します。メールアドレスを入力してください。</Typography>
            </BlueArea>
            <Box
                component='form'
                onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault()
                    doForgot()
                }}
                sx={{
                    maxWidth: '600px',
                    mx: 'auto'
                }}
            >
                <InputMailAddress
                    value={inputMailAddress}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setInputMailAddress(e.target.value)
                    }}
                />
                <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    sx={{
                        my: 1
                    }}
                    disabled={isLoading}
                    endIcon={isLoading ? (<CircularProgress size={15} color="secondary" />) : undefined}
                >送信</Button>
                <Button
                    variant="outlined"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault()
                        navigate(-1)
                    }}
                    fullWidth
                    sx={{
                        my: 1
                    }}
                >戻る</Button>
            </Box>
        </Paper>
    )
}

export default ForgotPage
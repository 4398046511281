export interface EnqueteItem {
    id: number
    name: string
    extra: boolean
}
export interface EnqueteData {
    id: number
    name: string
    title: string
    multiselect: boolean
    require: boolean
    items: EnqueteItem[]
}
export interface EnqueteAnswer {
    enqgroup_id: number,
    enqitem_id: number,
    enqitem_extra?: string
}
export interface ObjectToEnqueteItemProprs {
    id: number | string
    name: string
    extra: boolean | string
}
export interface ObjectToEnqueteDataProprs {
    id: number | string
    name: string
    title: string
    multiselect: boolean | string
    require: boolean | string
    items: any
}
export function ObjectToEnqueteItem(obj: ObjectToEnqueteItemProprs): EnqueteItem {
    return {
        id: typeof obj.id === "string" ? parseInt(obj.id) : obj.id,
        name: obj.name,
        extra: typeof obj.extra === "string" ? obj.extra === "1" : obj.extra
    }
}
export function ObjectToEnqueteData(obj: ObjectToEnqueteDataProprs): EnqueteData {
    return {
        id: typeof obj.id === "string" ? parseInt(obj.id) : obj.id,
        name: obj.name,
        title: obj.title,
        multiselect: typeof obj.multiselect === "string" ? obj.multiselect === "1" : obj.multiselect,
        require: typeof obj.require === "string" ? obj.require === "1" : obj.require,
        items: obj.items.map((item: any) => ObjectToEnqueteItem(item))
    }
}
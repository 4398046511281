import { Container, Typography } from "@mui/material"

const CopyRights: React.FC = () => {
    return (
        <Container disableGutters={true}>
            <Typography variant="caption" component="p" textAlign="right">Copyright © 2013 BLstudio, All rights reserved.</Typography>
        </Container>
    )
}

export default CopyRights
import { isBefore, isSameDay, parse } from "date-fns"
import { Time } from "../Helper/Time/Time"

export interface LessonUnreservable {
    lurvable_id: number
    teacher_id: number
    lurvable_date: Date
    start: Time | null
    end: Time | null
}

export const ObjectToLessonUnreservable = ({
    lurvable_id,
    teacher_id,
    lurvable_date,
    start,
    end,
}:{
    lurvable_id: number | string
    teacher_id: number | string
    lurvable_date: string
    start: string | null
    end: string | null
}) => {
    return {
        lurvable_id: typeof lurvable_id === 'string' ? parseInt(lurvable_id) : lurvable_id,
        teacher_id: typeof teacher_id === 'string' ? parseInt(teacher_id) : teacher_id,
        lurvable_date: parse(lurvable_date, "yyyy-MM-dd", new Date()),
        start: start ? new Time(start) : null,
        end: end ? new Time(end) : null,
    }
}

export const IsUnreservableDate = (
    date: Date,
    lessonUnreservable: LessonUnreservable,
) => {
    if( lessonUnreservable.start && lessonUnreservable.end ) {
        return false
    }
    if( isSameDay(date, lessonUnreservable.lurvable_date) ) {
        return true
    }
    return false
}
import { Box, Button, Paper, Stack, Typography } from "@mui/material"
import React from "react"
import { ReserveData } from "../../Objects/ReserveData"
import { ReserveEquip } from "../../Objects/ReserveEquip"
import EquipCardItem from "../molecules/EquipCardItem"
import ReserveConditions from "../molecules/ReserveConditions"
import ReservableStudioItem from "./ReservableStudioItem"

export interface SelectedReserveDataProps {
    reserveData?: ReserveData
    onEditDate: () => void
    onEditTime: () => void
    onEditNumMember: () => void
    onEditReservableStudio: () => void
    onEditReserveEquip: (reserveEquip: ReserveEquip) => void
    onConfirmReaserve: () => void
}
const SelectedReserveData: React.FC<SelectedReserveDataProps> = ({
    reserveData,
    onEditDate,
    onEditTime,
    onEditNumMember,
    onEditReservableStudio,
    onEditReserveEquip,
    onConfirmReaserve,
}) => {

    return (
        <Paper
            sx={{
                p: 2
            }}
        >
            <Typography
                variant="body1"
                component="p"
                textAlign="left"
                color="primary"
                sx={{
                    fontWeight: "bold"
                }}
            >予約条件</Typography>
            <ReserveConditions
                reserveData={reserveData}
                onEditDate={onEditDate}
                onEditTime={onEditTime}
                onEditNumMember={onEditNumMember}
            />
            <Box
                sx={{
                    mb: 1
                }}
            >
                {reserveData?.reservableStudio &&
                    <>
                        <Typography
                            variant="body1"
                            component="p"
                            textAlign="left"
                            color="primary"
                            sx={{
                                fontWeight: "bold"
                            }}
                        >予約スタジオ</Typography>
                        <ReservableStudioItem
                            reservableStudio={reserveData.reservableStudio}
                            onClick={() => {
                                onEditReservableStudio()
                            }}
                        />
                    </>

                }
            </Box>
            {reserveData?.reserveEquipList &&
                <>
                    <Typography
                        variant="body1"
                        component="p"
                        textAlign="left"
                        color="primary"
                        sx={{
                            fontWeight: "bold"
                        }}
                    >レンタル機材</Typography>
                    <Stack
                        spacing={1}
                    >
                        {reserveData?.reserveEquipList.map((reserveEquip: ReserveEquip) => (
                            <EquipCardItem
                                equipData={reserveEquip.equip}
                                onClick={() => {
                                    onEditReserveEquip(reserveEquip)
                                }}
                                key={reserveEquip.equip.equ_id + "-" + reserveEquip.number}
                            >
                                <Typography
                                    textAlign="right"
                                >{reserveEquip.number}台</Typography>
                            </EquipCardItem>
                        )
                        )}
                    </Stack>
                </>
            }
            <Box
                sx={{
                    mt: 2,
                }}
            >
                <Button
                    variant="contained"
                    fullWidth
                    disabled={reserveData?.reservableStudio === undefined}
                    onClick={() => {
                        onConfirmReaserve()
                    }}
                >予約を確認する</Button>
            </Box>
        </Paper>
    )
}

export default SelectedReserveData
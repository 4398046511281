import { addDays, format, isSameDay, isToday } from "date-fns"
import { ja } from "date-fns/locale"

export class Time {
    public sec: number

    constructor(value: undefined | string | number | Date | Time = undefined) {
        this.sec = 0
        if (value === undefined) {
            value = new Date()
        }
        if (typeof value === "string") {
            let value_data = value.match(/^([0-9]*):([0-9]*):?([0-9]*)$/)
            if (value_data) {
                let hour: number = parseInt(value_data[1])
                let minute: number = parseInt(value_data[2])
                let second: number = value_data[3] ? parseInt(value_data[3]) : 0
                this.sec = hour * 3600 + minute * 60 + second
            }
        }
        if (typeof value === "number") {
            this.sec = value
        }
        if (value instanceof Date) {
            let second: number = value.getSeconds()
            let minute: number = value.getMinutes()
            let hour: number = value.getHours()
            this.sec = hour * 3600 + minute * 60 + second
        }
        if (value instanceof Time) {
            this.sec = value.sec
        }
    }

    add(value: Time | number): Time | null {
        let cur = this.toSec()
        if (value instanceof Time) {
            this.sec = cur + value.toSec()
            return this
        }
        if (typeof value === 'number') {
            this.sec = cur + value
            return this
        }
        throw new Error("param error")
    }

    sub(value: Time | number): Time | null {
        let cur = this.toSec()
        if (value instanceof Time) {
            this.sec = cur - value.toSec()
            return this
        }
        if (typeof value === 'number') {
            this.sec = cur - value
            return this
        }
        throw new Error("param error")
    }

    isBefore(value: Time | number, orEqual: boolean = false): boolean | null {
        let cur = this.toSec()
        if (value instanceof Time) {
            return orEqual ? cur <= value.toSec() : cur < value.toSec()
        }
        if (typeof value === 'number') {
            return orEqual ? cur <= value : cur < value
        }
        return null
    }

    isAfter(value: Time | number, orEqual: boolean = false): boolean | null {
        let cur = this.toSec()
        if (value instanceof Time) {
            return orEqual ? cur >= value.toSec() : cur > value.toSec()
        }
        if (typeof value === 'number') {
            return orEqual ? cur >= value : cur > value
        }
        return null
    }

    isBetween(value1: undefined | string | number | Date | Time, value2: undefined | string | number | Date | Time, orEqual: boolean = false): boolean | null {
        let cur = this.toSec()
        if (value1 instanceof Time) {
            value1 = value1.toSec()
        } else {
            value1 = new Time(value1).toSec()
        }
        if (value2 instanceof Time) {
            value2 = value2.toSec()
        } else {
            value2 = new Time(value2).toSec()
        }
        if (value2 < value1) {
            let tmp = value2
            value2 = value1
            value1 = tmp
        }
        return orEqual ? value1 <= cur && cur <= value2 : value1 < cur && cur < value2
    }

    /**
     * 文字列変換
     * @returns {string} H:mm:ss
     */
    toString(): string {
        let hour: number = Math.floor(this.sec / 3600)
        let minute: number = Math.floor((this.sec - hour*3600) / 60)
        let second: number = this.sec % 60
        return hour + ":" + ("0" + minute).slice(-2) + ":" + ("0" + second).slice(-2)
    }

    /**
     * 文字列変換
     * @returns {string} H:mm
     */
    toStringHM(): string {
        let hour: number = Math.floor(this.sec / 3600)
        let minute: number = Math.floor((this.sec - hour*3600) / 60)
        return hour + ":" + ("0" + minute).slice(-2)
    }

    toStringAHM(): string {
        let hour: number = Math.floor(this.sec / 3600)
        let minute: number = Math.floor((this.sec - hour*3600) / 60)
        let d: Date = new Date()
        d.setHours(hour)
        d.setMinutes(minute)
        return format(d, "b h時mm分", {locale: ja})
    }

    /**
     * 時間間隔文字列変換
     * @returns string H時間mm分ss秒
     */
    toStgingLength(): string {
        let hour: number = Math.floor(this.sec / 3600)
        let minute: number = Math.floor((this.sec - hour*3600) / 60)
        let second: number = this.sec % 60
        let sz = hour + "時間"
        if (minute > 0 || second > 0) {
            sz = sz + ("0" + minute).slice(-2) + "分"
        }
        if (second > 0) {
            sz = sz + ("0" + second).slice(-2) + "秒"
        }
        return sz
    }

    /**
     * 秒数に変換
     * @returns {number} sec
     */
    toSec(): number {
        return this.sec
    }

    *eachUnit(end: Time, unit: Time) {
        let e = end.toSec()
        let u = unit.toSec()
        for (let cur = this.toSec(); cur < e; cur += u) {
            yield new Time(cur)
        }
    }
}

export function nowWorkTime(workingTime: { open: any, close: any }) {
    let time = new Time()
    let workEnd = new Time(workingTime.close)
    if (workEnd.sec >= 24 * 60 * 60) {
        // 24時を超える場合
        let time2 = time.add(24 * 60 * 60)
        if (time2 && time2.isBefore(workEnd, true)) {
            return time2
        }
    }
    return time
}

export function isWorkToday(date: string | Date, workingTime: { open: any, close: any }) {
    if (typeof date === 'string') {
        date = new Date(date)
    }

    let dateOfTime = new Time(date)
    let workBegin = new Time(workingTime.open)
    let workEnd = new Time(workingTime.close)

    if (dateOfTime.isBefore(workBegin)) {
        date = addDays(date, -1)
    }
    if (dateOfTime.isAfter(workEnd)) {
        date = addDays(date, 1)
    }

    return isToday(date)
}

export function isWorkDay(datetime: string | Date, date: string | Date, workingTime: { open: any, close: any }) {
    if (typeof datetime === 'string') {
        datetime = new Date(datetime)
    }
    if (typeof date === 'string') {
        date = new Date(date)
    }

    let dateOfTime = new Time(datetime)
    let workBegin = new Time(workingTime.open)
    let workEnd = new Time(workingTime.close)

    if (dateOfTime.isBefore(workBegin)) {
        datetime = addDays(datetime, -1)
    }
    if (dateOfTime.isAfter(workEnd)) {
        datetime = addDays(datetime, 1)
    }

    return isSameDay(datetime, date)
}

export function minTime(...times: Time[]) {
    let minSec: number | undefined = undefined
    times.forEach(t => {
        minSec = minSec !== undefined ? Math.min(t.toSec(), minSec) : t.toSec()
    });
    return new Time(minSec)
}

export function maxTime(...times: Time[]) {
    let maxSec = 0
    times.forEach(t => {
        maxSec = Math.max(t.toSec(), maxSec)
    });
    return new Time(maxSec)
}

export function isEqual(time1: undefined | string | number | Date | Time, time2: undefined | string | number | Date | Time) {
    let t1 = new Time(time1)
    let t2 = new Time(time2)
    return t1.toSec() === t2.toSec()
}

export function timeLength(time1: undefined | string | number | Date | Time, time2: undefined | string | number | Date | Time): Time | null {
    let t1 = new Time(time1)
    let t2 = new Time(time2)
    return t1.isAfter(t2) ? t1.sub(t2) : t2.sub(t1)
}

export function timeLengthString(time1: undefined | string | number | Date | Time, time2: undefined | string | number | Date | Time): string {
    let len = timeLength(time1, time2)
    return len ? len.toStgingLength() : ""
}

export function secToTimeString(sec: number): string {
    let t = new Time(sec)
    return t.toString()
}

export function secToTimeStringHM(sec: number): string {
    let t = new Time(sec)
    return t.toStringHM()
}

export function secToTimeStringAHM(sec: number): string {
    let t = new Time(sec)
    return t.toStringAHM()
}

export function TimeToTimeStringAHM(time: undefined | string | number | Date | Time): string {
    let t = new Time(time)
    return t.toStringAHM()
}

// Time.prototype.toString = function(){
//     return this.hour + ":" + ("0"+this.minute).slice(-2) + ":" + ("0"+this.second).slice(-2)
// }
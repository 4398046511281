import { Button, Grid, Paper, Typography } from "@mui/material";
import React from "react";

export interface SelectMonthProps {
    year: number
    month: number
    onSelectMonth: (year: number, month: number) => void
}
const SelectMonth: React.FC<SelectMonthProps> = ({
    year,
    month,
    onSelectMonth,
}) => {

    /**
     * View
     */

    return (
        <Paper
            variant="outlined"
            sx={{
                p: 1
            }}
        >
            <Grid container
                alignItems="center"
            >
                <Grid item xs={3} sm={2} md={1}>
                    <Button
                        onClick={() => {
                            onSelectMonth(
                                month === 0 ? year - 1 : year
                                , month === 0 ? 11 : month - 1
                            )
                        }}
                        fullWidth
                    >前月</Button>
                </Grid>
                <Grid item xs={6} sm={8} md={10}>
                    <Typography>{year}年 {month + 1}月</Typography>
                </Grid>
                <Grid item xs={3} sm={2} md={1}>
                    <Button
                        onClick={() => {
                            onSelectMonth(
                                month === 11 ? year + 1 : year
                                , month === 11 ? 0 : month + 1
                            )
                        }}
                        fullWidth
                    >翌月</Button>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default SelectMonth
import { Box, Collapse, SxProps, Theme, Typography } from "@mui/material"
import { DateCalendar, PickersDay, PickersDayProps } from "@mui/x-date-pickers"
import { format, getDay, isAfter, isBefore } from "date-fns"
import { useSnackbar } from "notistack"
import React from "react"
import { ApiError } from "../../Api/ApiError"
import { holidayData, holidays } from "../../Api/Calendar"
import { ReservableList, ReservableListResult } from "../../Api/Mypage"
import { IsReservableDate, LessonReservable } from "../../Objects/LessonReservable"
import { IsUnreservableDate, LessonUnreservable } from "../../Objects/LessonUneservable"
import BlueArea from "../atoms/BlueArea"

const Day = (props: PickersDayProps<Date> & {
    fromDate?: Date | null,
    toDate?: Date | null,
    lessonReservableList?: LessonReservable[],
    lessonUnreservableList?: LessonUnreservable[],
    holidayDatas?: holidayData,
}) => {
    let { day, disabled, fromDate, toDate, lessonReservableList, lessonUnreservableList, holidayDatas, ...other } = props
    const [sx, setSx] = React.useState<SxProps<Theme>>()
    const [holiday, setHoliday] = React.useState<string>("")

    React.useEffect(() => {
        if( holidayDatas ) {
            const sz = format(day, "yyyy-M-d")
            const hol = holidayDatas[sz] ?? ""
            setHoliday(hol)
        }
    }, [day, holidayDatas])

    const isReservable = React.useCallback((date: Date) => {
        if ( fromDate && isBefore( date, fromDate ) ) {
            return false
        }
        if ( toDate && isAfter( date, toDate) ) {
            return false
        }
        // lessonReservableList
        if( lessonReservableList && lessonReservableList.some((lessonReservable: LessonReservable) => IsReservableDate(date, lessonReservable, holiday!=="")) === false ) {
            return false
        }
        if( lessonUnreservableList && lessonUnreservableList.some((lessonUnreservable: LessonUnreservable) => IsUnreservableDate(date, lessonUnreservable)) ) {
            return false
        }
        return true
    },[fromDate, toDate, lessonReservableList, lessonUnreservableList, holiday])

    React.useEffect(() => {
        if( holiday !== "" ) {
            setSx({
                ...props.sx,
                color: "red"
            })
            return;
        }
        const d = getDay(day)
        switch (d) {
            case 0:
                setSx({
                    ...props.sx,
                    color: "red"
                })
                break;
            case 6:
                setSx({
                    ...props.sx,
                    color: "blue"
                })
                break;
        
            default:
                setSx(props.sx)
                break;
        }

    }, [day, props.sx, holiday])

    if( ! disabled ) {
        disabled = ! isReservable(day)
    }
    return <PickersDay
        {...other}
        day={day}
        sx={sx}
        disabled={disabled}
    />
}

export interface SelectReserveDateProps {
    selected?: Date
    onSelect: (value?: Date) => void
    teacher_id?: number
}
const SelectReserveDate: React.FC<SelectReserveDateProps> = ({
    selected,
    onSelect,
    teacher_id,
}) => {
    let { enqueueSnackbar } = useSnackbar()

    let [loading, setLoading] = React.useState<boolean>(true)
    let [fromDate, setFromDate] = React.useState<Date>()
    let [toDate, setToDate] = React.useState<Date>()
    let [lessonReservableList, setLessonReservableList] = React.useState<LessonReservable[]>()
    let [lessonUnreservableList, setLessonUnreservableList] = React.useState<LessonUnreservable[]>()
    const [holidayDatas, setHolidayDatas] = React.useState<holidayData>()

    const abortControllerRef = React.useRef(new AbortController())

    React.useEffect(() => {
        setLoading(true)
        ReservableList({
            teacher_id: teacher_id
        }, {signal: abortControllerRef.current.signal}).then((result: ReservableListResult) => {
            setFromDate(result.fromDate)
            setToDate(result.toDate)
            setLessonReservableList(result.lesson_reservable_list)
            setLessonUnreservableList(result.lesson_unreservable_list)
            holidays({
                from: result.fromDate,
                to: result.toDate,
            }).then(result => {
                setHolidayDatas(result.holidays)
            }).catch((e: any) => {
                if( e.__CANCEL__ ) {
                    console.info("canceled")
                } else if (e instanceof ApiError) {
                    enqueueSnackbar(e.message, { variant: "error" })
                } else if (e instanceof Error) {
                    enqueueSnackbar(e.message, { variant: "error" })
                }else {
                    enqueueSnackbar("通信に失敗しました", { variant: "error" })
                }
            }).finally(() => {
                setLoading(false)
            })
        }).catch((e: any) => {
            if( e.__CANCEL__ ) {
                console.info("canceled")
            } else if (e instanceof ApiError) {
                enqueueSnackbar(e.message, { variant: "error" })
            } else if (e instanceof Error) {
                enqueueSnackbar(e.message, { variant: "error" })
            }else {
                enqueueSnackbar("通信に失敗しました", { variant: "error" })
            }
        })
    }, [teacher_id, enqueueSnackbar])

    React.useEffect(() => {
        abortControllerRef.current = new AbortController()
        return () => {
            abortControllerRef.current.abort()
        }
    }, [])

    return (
        <Box>
            <BlueArea>
                <Typography
                    sx={{
                        textAlign: "left",
                        fontWeight: "bold",
                    }}
                >ご利用する日付を選択してください。</Typography>
            </BlueArea>
            <Box>
                <Collapse
                    in={!loading}
                >
                    <DateCalendar
                        value={selected ? selected : null}
                        onChange={(value) => {
                            onSelect(value ?? undefined)
                        }}
                        views={['day']}
                        slots={{
                            day: Day
                        }}
                        slotProps={{
                            day: {
                                fromDate: fromDate,
                                toDate: toDate,
                                lessonReservableList: lessonReservableList,
                                lessonUnreservableList: lessonUnreservableList,
                                holidayDatas: holidayDatas,
                            } as any,
                        }}
                        minDate={fromDate}
                        maxDate={toDate}
                    />
                </Collapse>
            </Box>
        </Box>
    )
}

export default SelectReserveDate
import { Paper, Skeleton, Stack, Typography } from "@mui/material"
import { getMonth, getYear } from "date-fns"
import { useSnackbar } from "notistack"
import React from "react"
import { ApiError } from "../../Api/ApiError"
import { ReserveList, ReserveListResult } from "../../Api/Mypage"
import { ReserveData } from "../../Objects/ReserveData"
import ReserveCardItem from "../molecules/ReserveCardItem"
import ReserveDetailDialog from "../molecules/ReserveDetailDialog"
import SelectMonth from "../organisms/SelectMonth"

interface YearMonth {
    year: number
    month: number
}

const ReserveListPage: React.FC = () => {
    let { enqueueSnackbar } = useSnackbar()

    let [yearMonth, setYearMonth] = React.useState<YearMonth>({ year: getYear(new Date()), month: getMonth(new Date()) })

    let [reserveList, setReserveList] = React.useState<ReserveData[]>()
    let [detailStdrvId, setDetailStdrvId] = React.useState<number>()

    const abortControllerRef = React.useRef(new AbortController())

    let doReload = React.useCallback((ym: YearMonth) => {
        setReserveList(undefined)
        ReserveList({
            year: ym.year,
            month: ym.month + 1
        }, {signal: abortControllerRef.current.signal}).then((result: ReserveListResult) => {
            setReserveList(result.reserveDatas)
        }).catch((e: any) => {
            if( e.__CANCEL__ ) {
                console.info("canceled")
            } else if (e instanceof ApiError) {
                enqueueSnackbar(e.message, { variant: "error" })
            } else if (e instanceof Error) {
                enqueueSnackbar(e.message, { variant: "error" })
            }else {
                enqueueSnackbar("通信に失敗しました", { variant: "error" })
            }
        })
    }, [enqueueSnackbar])

    React.useEffect(() => {
        doReload(yearMonth)
    }, [yearMonth, doReload])

    React.useEffect(() => {
        abortControllerRef.current = new AbortController()
        return () => {
            abortControllerRef.current.abort()
        }
    }, [])

    return (
        <Paper sx={{
            p: {
                xs: 1,
                sm: 2,
            },
        }}>
            <SelectMonth
                year={yearMonth.year}
                month={yearMonth.month}
                onSelectMonth={(year: number, month: number) => {
                    setYearMonth({
                        year: year,
                        month: month
                    })
                }}
            />
            {reserveList ? (
                <Stack
                    spacing={1}
                    sx={{
                        mt: 2
                    }}
                >
                    {reserveList.length === 0 ? (
                        <>
                            <Typography
                                textAlign="left"
                            >予約がありません</Typography>
                        </>
                    ) : (
                        <>
                            {reserveList.map((reserveData: ReserveData, index: number) => (
                                <ReserveCardItem
                                    key={index}
                                    reserveData={reserveData}
                                    onClick={() => {
                                        setDetailStdrvId(reserveData.stdrv_id)
                                    }}
                                />
                            ))}
                        </>
                    )}
                </Stack>
            ) : (
                <Skeleton
                    variant='rectangular'
                    height={100}
                    sx={{
                        mt: 2
                    }}
                />
            )}
            <ReserveDetailDialog
                open={detailStdrvId !== undefined}
                onClose={() => {
                    setDetailStdrvId(undefined)
                    doReload(yearMonth)
                }}
                stdrv_id={detailStdrvId}
            />
        </Paper>
    )
}

export default ReserveListPage
import axios, { AxiosRequestConfig } from "axios"
import { format } from "date-fns"
import { ApiError } from "./ApiError"

axios.defaults.withCredentials = true
axios.defaults.xsrfHeaderName = 'X-CSRF-Token'

export interface holidayData {
    [index: string]: string
}

export interface holidaysProps {
    from: Date
    to: Date
}
export interface holidaysResults {
    holidays: holidayData
}
export const holidays = async (props: holidaysProps, config?: AxiosRequestConfig): Promise<holidaysResults> => {
    try {

        let res = await axios.get(
            process.env.REACT_APP_API_ENDPOINT + 'calendar/holidays', {
            ...config,
            params: {
                from: format(props.from, "yyyy-MM-dd"),
                to: format(props.to, "yyyy-MM-dd"),
            }
        }
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }
        return {
            holidays: res.data.holidays
        }
    } catch (error) {
        throw error
    }
}
import axios, { AxiosRequestConfig } from "axios"
import { format, parse } from "date-fns"
import { secToTimeString, secToTimeStringHM, Time } from "../Helper/Time/Time"
import { EnqueteAnswer, EnqueteData, ObjectToEnqueteData } from "../Objects/EnqueteData"
import { EqucateData, ObjectToEqucateData } from "../Objects/EqucateData"
import { ObjectToEquipData } from "../Objects/EquipData"
import { EquipmentAdjustment, ObjectToEquipmentAdjustment } from "../Objects/EquipmentAdjustment"
import { LessonReservable, ObjectToLessonReservable } from "../Objects/LessonReservable"
import { LessonUnreservable, ObjectToLessonUnreservable } from "../Objects/LessonUneservable"
import { ObjectToReservableStudio, ReservableStudio } from "../Objects/ReservableStudio"
import { ObjectToReserveData, ReserveData } from "../Objects/ReserveData"
import { ObjectToReserveDetail, ReserveDetail } from "../Objects/ReserveDetail"
import { ObjectToStoreData, StoreData } from "../Objects/StoreData"
import { ObjectToStudent, Student } from "../Objects/Student"
import { ObjectToStudioAdjustment, StudioAdjustment } from "../Objects/StudioAdjustment"
import { ObjectToStudioTag, StudioTag } from "../Objects/StudioTag"
import { ObjectToTeacher, Teacher } from "../Objects/Teacher"
import { ApiError } from "./ApiError"

axios.defaults.withCredentials = true
axios.defaults.xsrfHeaderName = 'X-CSRF-Token'

export interface loginProps {
    mail: string
    password: string
}
export interface loginResult {
    ctm_id?: number
}
export const login = async ({
    mail,
    password
}: loginProps, config?: AxiosRequestConfig): Promise<loginResult> => {
    try {
        let params = new URLSearchParams()
        params.append("mail", mail)
        params.append("password", password)

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/login',
            params, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }
        return {
            ctm_id: parseInt(res.data.ctm_id)
        }
    } catch (error) {
        throw error
    }
}

export interface getCustomerDataResult {
    ctm_id: number
    name1: string
    name2: string
    validity_date?: Date
    point: number
    teacher_id: number | null
    student_id: number | null
}
export const getCustomerData = async (config?: AxiosRequestConfig): Promise<getCustomerDataResult> => {
    try {

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/get_customer_data', undefined, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }
        return {
            ctm_id: parseInt(res.data.data.ctm_id),
            name1: res.data.data.name1,
            name2: res.data.data.name2,
            validity_date: res.data.data.validity_date ? parse(res.data.data.validity_date, 'yyyy-MM-dd', new Date()) : undefined,
            point: parseInt(res.data.data.point),
            teacher_id: res.data.data.teacher_id ? parseInt(res.data.data.teacher_id) : null,
            student_id: res.data.data.student_id ? parseInt(res.data.data.student_id) : null,
        }
    } catch (error) {
        throw error
    }
}

export interface isLoginResult {
    isLogin: boolean
    ctm_id?: number
}
export const isLogin = async (config?: AxiosRequestConfig): Promise<isLoginResult> => {
    try {

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/is_login', undefined, config
        )
        return {
            isLogin: res.data.status === 'LOGINED',
            ctm_id: parseInt(res.data.ctm_id),
        }
    } catch (error) {
        throw error
    }
}

export const Logout = async (config?: AxiosRequestConfig): Promise<void> => {
    try {

        await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/logout', undefined, config
        )
    } catch (error) {
        throw error
    }
}

export interface CheckRegisterdProps {
    memberIdKind: string
    code?: string
    oldMemberId?: string
    pronunciation1: string
    pronunciation2: string
    birthday: Date
    tel: string
}
export interface CheckRegisterdResult {
    token: string
}
export const CheckRegisterd = async ({
    memberIdKind,
    code,
    oldMemberId,
    pronunciation1,
    pronunciation2,
    birthday,
    tel
}: CheckRegisterdProps, config?: AxiosRequestConfig): Promise<CheckRegisterdResult> => {
    try {
        let params = new URLSearchParams()
        params.append("memberIdKind", memberIdKind)
        if (code) {
            params.append("code", code)
        }
        if (oldMemberId) {
            params.append("oldMemberId", oldMemberId)
        }
        params.append("pronunciation1", pronunciation1)
        params.append("pronunciation2", pronunciation2)
        params.append("birthday", format(birthday, "yyy-MM-dd"))
        params.append("tel", tel)

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/check_registerd',
            params, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }
        return {
            token: res.data.token
        }
    } catch (error) {
        throw error
    }
}

export interface RegMypageProps {
    token: string
}
export interface RegMypageResult {
}
export const RegMypage = async ({
    token,
}: RegMypageProps, config?: AxiosRequestConfig): Promise<RegMypageResult> => {
    try {
        let params = new URLSearchParams()
        params.append("token", token)

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/reg_mypage',
            params, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }
        return {
        }
    } catch (error) {
        throw error
    }

}

export interface SendRegMailProps {
    mail: string
}
export interface SendRegMailResult { }
export const SendRegMail = async ({
    mail,
}: SendRegMailProps, config?: AxiosRequestConfig): Promise<SendRegMailResult> => {
    try {
        let params = new URLSearchParams()
        params.append("mail", mail)

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/send_reg_mail',
            params, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }
        return {
        }
    } catch (error) {
        throw error
    }
}

export interface ValidateKeyProps {
    key: string
    subkey: string
}
export interface ValidateKeyResult { }
export const ValidateKey = async ({
    key,
    subkey
}: ValidateKeyProps, config?: AxiosRequestConfig): Promise<ValidateKeyResult> => {
    try {
        let params = new URLSearchParams()
        params.append("key", key)
        params.append("subkey", subkey)

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/validate_key',
            params, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }
        return {
        }
    } catch (error) {
        throw error
    }
}

export interface RegisterProps {
    key?: string,
    subkey?: string,
    name1?: string
    name2?: string
    pronunciation1?: string
    pronunciation2?: string
    birthday?: Date
    gender?: string
    tel1?: string
    tel2?: string
    postcode?: string
    address1?: string
    address2?: string
    enqdata?: EnqueteAnswer[]
}

export const Register = async ({
    key,
    subkey,
    name1,
    name2,
    pronunciation1,
    pronunciation2,
    birthday,
    gender,
    tel1,
    tel2,
    postcode,
    address1,
    address2,
    enqdata,
}: RegisterProps, config?: AxiosRequestConfig): Promise<void> => {
    try {
        let params = new URLSearchParams()
        params.append("key", key ?? "")
        params.append("subkey", subkey ?? "")
        params.append("name1", name1 ?? "")
        params.append("name2", name2 ?? "")
        params.append("pronunciation1", pronunciation1 ?? "")
        params.append("pronunciation2", pronunciation2 ?? "")
        params.append("birthday", birthday ? format(birthday, "yyyy-MM-dd") : "")
        params.append("gender", gender ?? "")
        params.append("tel1", tel1 ?? "")
        params.append("tel2", tel2 ?? "")
        params.append("postcode", postcode ?? "")
        params.append("address1", address1 ?? "")
        params.append("address2", address2 ?? "")
        params.append("enqdata", enqdata ? JSON.stringify(enqdata) : "")

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/register',
            params, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }
    } catch (error) {
        throw error
    }
}

export interface ForgotProps {
    mail: string
}
export const Forgot = async ({
    mail
}: ForgotProps, config?: AxiosRequestConfig): Promise<void> => {
    try {
        let params = new URLSearchParams()
        params.append("input_login_mail", mail)

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/forgot',
            params, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }
    } catch (error) {
        throw error
    }

}

export interface ForgotCheckProps {
    id: string,
    key: string,
}
export const ForgotCheck = async ({
    id,
    key
}: ForgotCheckProps, config?: AxiosRequestConfig): Promise<void> => {
    try {
        let params = new URLSearchParams()
        params.append("id", id)
        params.append("key", key)

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/forgot_check',
            params, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }
    } catch (error) {
        throw error
    }

}

export interface getWorkingtimeProps {
    reserve_date: Date
    teacher_id?: number
}
export interface getWorkingtimeResult {
    open: Time,
    close: Time,
    unit: Time,
    reservable?: number[]
}
export const getWorkingtime = async ({
    reserve_date,
    teacher_id
}: getWorkingtimeProps, config?: AxiosRequestConfig): Promise<getWorkingtimeResult> => {
    try {
        let params = new URLSearchParams()
        params.append("reserve_date", format(reserve_date, "yyyy-MM-dd"))
        if (teacher_id) {
            params.append("teacher_id", teacher_id.toString())
        }

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/get_workingtime',
            params, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }
        return {
            'open': new Time(parseInt(res.data.open) * 60),
            'close': new Time(parseInt(res.data.close) * 60),
            'unit': new Time(parseInt(res.data.unit) * 60),
            'reservable': res.data.reservable ? res.data.reservable.map((x: string | number) => typeof x === "string" ? parseInt(x) : x) : undefined,
        }
    } catch (error) {
        throw error
    }
}

export interface getAllStoreNameResult {
    list: StoreData[]
}
export const getAllStoreName = async (config?: AxiosRequestConfig): Promise<getAllStoreNameResult> => {
    try {

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/get_all_store_name',
            undefined, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }
        return {
            list: res.data.list.map((value: any) => ObjectToStoreData(value))
        }
    } catch (error) {
        throw error
    }
}

export interface getStudioTagListProps {
    str_id?: number
}
export interface getStudioTagListResult {
    list: StudioTag[]
}
export const getStudioTagList = async ({
    str_id
}: getStudioTagListProps, config?: AxiosRequestConfig): Promise<getStudioTagListResult> => {
    try {
        let params = new URLSearchParams()
        if (str_id) {
            params.append("str_id", str_id.toString())
        }

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/get_studio_tag_list',
            params, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }
        return {
            list: res.data.list.map((value: any) => ObjectToStudioTag(value))
        }
    } catch (error) {
        throw error
    }
}

export interface getReservableStudioListProps {
    reserve_date: Date
    start_time: Time
    end_time: Time
    number: number
    store_list: StoreData[]
    stdtag_list: StudioTag[]
    teacher_id?: number
}
export interface getReservableStudioListResult {
    list: ReservableStudio[]
}
export const getReservableStudioList = async ({
    reserve_date,
    start_time,
    end_time,
    number,
    store_list,
    stdtag_list,
    teacher_id,
}: getReservableStudioListProps, config?: AxiosRequestConfig): Promise<getReservableStudioListResult> => {
    try {
        let params = new URLSearchParams()
        params.append("reserve_date", format(reserve_date, "yyyy-MM-dd"))
        params.append("start_time", start_time.toStringHM())
        params.append("end_time", end_time.toStringHM())
        params.append("number", number.toString())
        params.append("store_list", store_list.map((value: StoreData) => value.str_id).join(","))
        params.append("stdtag_list", stdtag_list.map((value: StudioTag) => value.stdtag_id).join(","))
        if( teacher_id ) {
            params.append("teacher_id", teacher_id.toString())
        }

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/get_reservable_studio_list',
            params, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }
        return {
            list: res.data.list.map((value: any) => ObjectToReservableStudio(value))
        }
    } catch (error) {
        throw error
    }
}

export interface getEquipmentListProps {
    reserve_date: Date
    start_time: Time
    end_time: Time
    number: number
    studio: ReservableStudio
}
export interface getEquipmentListResult {
    equcate_list: EqucateData[]
}
export const getEquipmentList = async ({
    reserve_date,
    start_time,
    end_time,
    number,
    studio,
}: getEquipmentListProps, config?: AxiosRequestConfig): Promise<getEquipmentListResult> => {
    try {
        let params = new URLSearchParams()
        params.append("reserve_date", format(reserve_date, "yyyy-MM-dd"))
        params.append("start_time", start_time.toStringHM())
        params.append("end_time", end_time.toStringHM())
        params.append("number", number.toString())
        params.append("str_id", studio.str_id.toString())
        params.append("std_id", studio.std_id.toString())

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/get_equipment_list',
            params, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }
        let equcate_list: EqucateData[] = []
        Object.keys(res.data.equ_list).forEach((key: string) => {
            let equcate = ObjectToEqucateData(res.data.equcate_list[key])
            let equ_list = res.data.equ_list[key]
            Object.keys(equ_list).forEach((name: string) => {
                let equip = ObjectToEquipData(equ_list[name])
                equcate.equ_list.push(equip)
            })
            equcate_list.push(equcate)
        })
        return {
            equcate_list: equcate_list,
        }
    } catch (error) {
        throw error
    }
}

export interface getReserveConfirmDataProps {
    reserveData: ReserveData
}
export interface getReserveConfirmDataResult {
    studioAdjustmentList: StudioAdjustment[],
    equipmentAdjustmentList: EquipmentAdjustment[],
}
export const getReserveConfirmData = async ({
    reserveData
}: getReserveConfirmDataProps, config?: AxiosRequestConfig): Promise<getReserveConfirmDataResult> => {
    try {
        let params = new URLSearchParams()
        if (reserveData.date) {
            params.append("reserve_date", format(reserveData.date, "yyyy-MM-dd"))
        } else {
            throw new ApiError("予約日が指定されていません", "NO_RESERVE_DATE")
        }
        if (reserveData.startTime) {
            params.append("start_time", reserveData.startTime.toStringHM())
        } else {
            throw new ApiError("開始時間が指定されていません", "NO_START_TIME")
        }
        if (reserveData.durationTime) {
            params.append("end_time", secToTimeStringHM(reserveData.startTime.toSec() + reserveData.durationTime.toSec()))
        } else {
            throw new ApiError("終了時間が指定されていません", "NO_END_TIME")
        }
        if (reserveData.numMember) {
            params.append("number", reserveData.numMember.toString())
        } else {
            throw new ApiError("終了時間が指定されていません", "NO_END_TIME")
        }
        if (reserveData.reservableStudio) {
            params.append("std_id", reserveData.reservableStudio.std_id.toString())
        } else {
            throw new ApiError("終了時間が指定されていません", "NO_END_TIME")
        }
        if (reserveData.reserveEquipList) {
            params.append("equ_list", reserveData.reserveEquipList.map(equdata => {
                return equdata.equip.id_list.slice(0, equdata.number).join(",")
            }).join(","))
        }
        if( reserveData.lessonReserve?.teacher?.teacher_id ) {
            params.append("teacher_id", reserveData.lessonReserve?.teacher?.teacher_id.toString())
        }

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/get_reserve_confirm_data',
            params, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }

        return {
            studioAdjustmentList: res.data.studio_adjustment_list.map((studio_adjustment: any) => ObjectToStudioAdjustment(studio_adjustment)),
            equipmentAdjustmentList: res.data.equipment_adjustment_list.map((equipment_adjustment: any) => ObjectToEquipmentAdjustment(equipment_adjustment)),
        }
    } catch (error) {
        throw error
    }
}


export interface ReserveProps {
    reserveData: ReserveData
}
export interface ReserveResult {
    sendmail: string
}
export const Reserve = async ({
    reserveData
}: ReserveProps, config?: AxiosRequestConfig): Promise<ReserveResult> => {
    try {
        let params = new URLSearchParams()
        if (reserveData.date) {
            params.append("reserve_date", format(reserveData.date, "yyyy-MM-dd"))
        } else {
            throw new ApiError("予約日が指定されていません", "NO_RESERVE_DATE")
        }
        if (reserveData.startTime) {
            params.append("start_time", reserveData.startTime.toString())
        } else {
            throw new ApiError("開始時間が指定されていません", "NO_START_TIME")
        }
        if (reserveData.durationTime) {
            params.append("end_time", secToTimeString(reserveData.startTime.toSec() + reserveData.durationTime.toSec()))
        } else {
            throw new ApiError("終了時間が指定されていません", "NO_END_TIME")
        }
        if (reserveData.numMember) {
            params.append("number", reserveData.numMember.toString())
        } else {
            throw new ApiError("終了時間が指定されていません", "NO_END_TIME")
        }
        if (reserveData.reservableStudio) {
            params.append("std_id", reserveData.reservableStudio.std_id.toString())
        } else {
            throw new ApiError("終了時間が指定されていません", "NO_END_TIME")
        }
        if (reserveData.reserveEquipList) {
            params.append("equ_list", reserveData.reserveEquipList.map(equdata => {
                return equdata.equip.id_list.slice(0, equdata.number).join(",")
            }).join(","))
        }
        if( reserveData.lessonReserve?.teacher?.teacher_id ) {
            params.append("teacher_id", reserveData.lessonReserve?.teacher?.teacher_id.toString())
        }
        if( reserveData.lessonReserve?.student?.student_id ) {
            params.append("student_id", reserveData.lessonReserve?.student?.student_id.toString())
        }

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/reserve',
            params, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }

        return {
            sendmail: res.data.send_mail
        }
    } catch (error) {
        throw error
    }
}

export interface RecentReserveListResult {
    reserveDatas: ReserveData[]
}
export const RecentReserveList = async (config?: AxiosRequestConfig): Promise<RecentReserveListResult> => {
    try {

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/get_recent_reserve_list',
            undefined, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }

        return {
            reserveDatas: res.data.reserve_list.map((data: any) => ObjectToReserveData(data))
        }
    } catch (error) {
        throw error
    }
}

export interface getReserveDetailProps {
    stdrv_id: number
}
export interface getReserveDetailResult {
    reserveDetail: ReserveDetail
}
export const getReserveDetail = async ({
    stdrv_id
}: getReserveDetailProps, config?: AxiosRequestConfig): Promise<getReserveDetailResult> => {
    try {
        let params = new URLSearchParams()
        params.append("stdrv_id", stdrv_id.toString())

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/get_reserve_detail',
            params, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }

        return {
            reserveDetail: ObjectToReserveDetail({
                ...res.data.studio_reserve,
                studioAdjustmentList: res.data.studio_reserve.adjustment_list,
                equipmentAdjustmentList: res.data.equipment_reserve
            })
        }
    } catch (error) {
        throw error
    }
}

export interface ReserveCancelRequestProps {
    stdrv_id: number
}
export interface ReserveCancelRequestResult {
    studio_cancel_charge: number
    equipment_cancel_charge: number
}
export const ReserveCancelRequest = async ({
    stdrv_id
}: ReserveCancelRequestProps, config?: AxiosRequestConfig): Promise<ReserveCancelRequestResult> => {
    try {
        let params = new URLSearchParams()
        params.append("stdrv_id", stdrv_id.toString())

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/reserve_cancel_request',
            params, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }

        return {
            studio_cancel_charge: res.data.studio_cancel_charge,
            equipment_cancel_charge: res.data.equipment_cancel_charge,
        }
    } catch (error) {
        throw error
    }
}

export interface ReserveCancelSubmitProps {
    stdrv_id: number
}
export interface ReserveCancelSubmitResult {
    send_mail_code: true | "NO_MAIL" | "NOT_MEMBERSHIP" | "SUCCESS"
}
export const ReserveCancelSubmit = async ({
    stdrv_id
}: ReserveCancelSubmitProps, config?: AxiosRequestConfig): Promise<ReserveCancelSubmitResult> => {
    try {
        let params = new URLSearchParams()
        params.append("stdrv_id", stdrv_id.toString())

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/reserve_cancel_submit',
            params, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }

        return {
            send_mail_code: res.data.send_mail,
        }
    } catch (error) {
        throw error
    }
}

export interface ReserveListProps {
    year: number
    month: number
}
export interface ReserveListResult {
    reserveDatas: ReserveData[]
}
export const ReserveList = async ({
    year,
    month,
}: ReserveListProps, config?: AxiosRequestConfig): Promise<ReserveListResult> => {
    try {
        let params = new URLSearchParams()
        params.append("year", year.toString())
        params.append("month", month.toString())

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/get_reserve_list',
            params, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }

        return {
            reserveDatas: res.data.reserve_list.map((reserveData: any) => ObjectToReserveData(reserveData))
        }
    } catch (error) {
        throw error
    }
}

export interface getProfileResult {
    mail1: string
    mail2: string
}
export const getProfile = async (config?: AxiosRequestConfig): Promise<getProfileResult> => {
    try {

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/get_profile', undefined, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }

        return {
            mail1: res.data.mail1,
            mail2: res.data.mail2,
        }
    } catch (error) {
        throw error
    }
}

export interface setProfileProps {
    mail1: string
    mail2: string
}
export const setProfile = async ({
    mail1,
    mail2,
}: setProfileProps, config?: AxiosRequestConfig): Promise<void> => {
    try {
        let params = new URLSearchParams()
        params.append("mail1", mail1)
        params.append("mail2", mail2)

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/set_profile',
            params, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }

    } catch (error) {
        throw error
    }
}

export interface ChangePasswordProps {
    oldPassword: string
    newPassword: string
    confirmPassword: string
}
export const ChangePassword = async ({
    oldPassword,
    newPassword,
    confirmPassword,
}: ChangePasswordProps, config?: AxiosRequestConfig): Promise<void> => {
    try {
        let params = new URLSearchParams()
        params.append("old_password", oldPassword)
        params.append("new_password", newPassword)
        params.append("confirm_password", confirmPassword)

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/change_password',
            params, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }

    } catch (error) {
        throw error
    }
}


export interface TeachersProps {
    limit?: number,
    offset?: number,
}
export interface TeachersResult {
    teachers: Teacher[]
    teachers_count: number
}
export const Teachers = async ({
    limit,
    offset,
}: TeachersProps, config?: AxiosRequestConfig): Promise<TeachersResult> => {
    try {
        let params = new URLSearchParams()
        if (limit !== undefined && offset !== undefined) {
            params.append("limit", limit.toString())
            params.append("offset", offset.toString())
        }

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/get_teachers',
            params, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }

        return {
            teachers: res.data.teachers.map((teacher: any) => ObjectToTeacher(teacher)),
            teachers_count: parseInt(res.data.teachers_count),
        }
    } catch (error) {
        throw error
    }
}

export interface StudentsProps {
    limit?: number,
    offset?: number,
}
export interface StudentsResult {
    students: Student[]
    students_count: number
}
export const Students = async ({
    limit,
    offset,
}: StudentsProps, config?: AxiosRequestConfig): Promise<StudentsResult> => {
    try {
        let params = new URLSearchParams()
        if (limit !== undefined && offset !== undefined) {
            params.append("limit", limit.toString())
            params.append("offset", offset.toString())
        }

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/get_students',
            params, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }

        return {
            students: res.data.students.map((student: any) => ObjectToStudent(student)),
            students_count: parseInt(res.data.students_count),
        }
    } catch (error) {
        throw error
    }
}

export interface ReservableListProps {
    teacher_id?: number
}
export interface ReservableListResult {
    fromDate: Date,
    toDate: Date,
    lesson_reservable_list?: LessonReservable[],
    lesson_unreservable_list?: LessonUnreservable[],
}
export const ReservableList = async (
    reservableListProps?: ReservableListProps,
    config?: AxiosRequestConfig
): Promise<ReservableListResult> => {
    try {
        let params = new URLSearchParams()
        if (reservableListProps && reservableListProps.teacher_id) {
            params.append("teacher_id", reservableListProps.teacher_id.toString())
        }

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/get_reservable_dates',
            params, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }

        return {
            fromDate: parse(res.data.from_date, "yyyy-MM-dd", new Date()),
            toDate: parse(res.data.to_date, "yyyy-MM-dd", new Date()),
            lesson_reservable_list: res.data.reservable_list ? res.data.reservable_list.map((data: any) => ObjectToLessonReservable(data)) : undefined,
            lesson_unreservable_list: res.data.unreservable_list ? res.data.unreservable_list.map((data: any) => ObjectToLessonUnreservable(data)) : undefined,
        }
    } catch (error) {
        throw error
    }
}

export interface EnqueteListResult {
    enqueteList: EnqueteData[]
}
export const EnqueteList = async (
    config?: AxiosRequestConfig
): Promise<EnqueteListResult> => {
    try {

        let res = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + 'mypage/get_enquete_list',
            undefined, config
        )
        if (res.data.status !== 'SUCCESS') {
            throw new ApiError(res.data.message ? res.data.message : "サーバーエラー", res.data.status)
        }

        return {
            enqueteList: res.data.data.map((enqdata: any) => ObjectToEnqueteData(enqdata))
        }
    } catch (error) {
        throw error
    }
}


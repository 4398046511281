import { Box, Button, Typography } from "@mui/material"
import React from "react"
import BlueArea from "../atoms/BlueArea"

export interface RegisterStep3aProps {
    onSubmit: () => void
}
const RegisterStep3a: React.FC<RegisterStep3aProps> = ({
    onSubmit,
}) => {

    return (
        <Box>
            <BlueArea>
                <Typography
                    textAlign='left'
                    sx={{
                        fontWeight: 'bold'
                    }}
                >Step3.登録メールアドレスに初期ログインパスワードを送信します。</Typography>
            </BlueArea>
            <Box
                sx={{
                    my: 2,
                    mx: 'auto',
                    px: 2,
                    py: 1,
                    width: { xs: '100%', md: '80%' },
                }}
            >
                <Button
                    variant="outlined"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault()
                        onSubmit()
                    }}
                    fullWidth
                >登録されたメールアドレスに送信</Button>
            </Box>
        </Box>
    )
}

export default RegisterStep3a
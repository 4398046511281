import React from 'react'
import { CustomerData } from '../Objects/CustomerData'

interface UserObjectInterface {
    ctm_id?: number
    customer_data?: CustomerData
}

const initialState: UserObjectInterface = {
}

type Action =
    | {
        type: 'login',
        payload: {
            ctm_id: number
        }
    }
    | {
        type: 'logout'
    }
    | {
        type: 'customerData',
        payload: {
            customer_data: CustomerData
        }
    }


export const UserObjectStore = React.createContext({} as {
    userObjectState: UserObjectInterface,
    userObjectDispatch: React.Dispatch<Action>
})

const reducer = (dataState: UserObjectInterface, action: Action): UserObjectInterface => {
    switch (action.type) {

        case 'login':
            return {
                ctm_id: action.payload.ctm_id
            }

        case 'customerData':
            return {
                ...dataState,
                customer_data: action.payload.customer_data
            }

        case 'logout':
            return initialState

        default:
            throw new Error()
    }
}


export const UserObjectProvider = (props: React.PropsWithChildren<{}>) => {
    const [userObjectState, userObjectDispatch] = React.useReducer<React.Reducer<UserObjectInterface, Action>>(reducer, initialState)
    return (
        <UserObjectStore.Provider
            value={{ userObjectState, userObjectDispatch }}
            {...props}
        />
    )
}

import { Box, Button, CircularProgress, Collapse, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import React from "react"
import { MemberIdKindType } from "../../Objects/MemberIdKindType"
import BlueArea from "../atoms/BlueArea"

export interface RegisterStep2aProps {
    result?: string
    onReset: () => void
    memberIdKind?: MemberIdKindType
    code?: string
    oldMemberId?: string
    pronunciation1?: string
    pronunciation2?: string
    birthday?: Date
    tel?: string
    onChangeMemberIdKind: (value?: MemberIdKindType) => void
    onChangeCode: (value?: string) => void
    onChangeOldMemberId: (value?: string) => void
    onChangePronunciation1: (value?: string) => void
    onChangePronunciation2: (value?: string) => void
    onChangeBirthday: (value?: Date) => void
    onChangeTel: (value?: string) => void
    onSubmit: () => void
    isSending?: boolean
}
const RegisterStep2a: React.FC<RegisterStep2aProps> = ({
    result,
    onReset,
    memberIdKind,
    code,
    oldMemberId,
    pronunciation1,
    pronunciation2,
    birthday,
    tel,
    onChangeMemberIdKind,
    onChangeCode,
    onChangeOldMemberId,
    onChangePronunciation1,
    onChangePronunciation2,
    onChangeBirthday,
    onChangeTel,
    onSubmit,
    isSending,
}) => {

    return (
        <Box>
            <BlueArea>
                <Typography
                    textAlign='left'
                    sx={{
                        fontWeight: 'bold'
                    }}
                >Step2.以前登録したデータを入力してください。</Typography>
                <Typography
                    textAlign='left'
                    variant='caption'
                    component='p'
                >(メールアドレスの登録がすんでいない場合は<a href={"mailto:" + process.env.REACT_APP_SYSTEM_REGISTER_MAIL}>{process.env.REACT_APP_SYSTEM_REGISTER_MAIL}</a>に空メールを送信してください。)</Typography>
            </BlueArea>
            {result === undefined ? (
                <Box
                    component='form'
                    onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                        e.preventDefault()
                        onSubmit()
                    }}
                >
                    <Stack
                        sx={{
                            my: 2,
                            mx: 'auto',
                            px: 2,
                            py: 1,
                            width: { xs: '100%', md: '80%' },
                        }}
                        spacing={2}
                    >
                        <FormControl>
                            <InputLabel id='select-member_id_kind'>お持ちの会員番号の種別を選択してください</InputLabel>
                            <Select
                                labelId="select-member_id_kind"
                                label="お持ちの会員番号の種別を選択してください"
                                value={memberIdKind ? memberIdKind : ''}
                                onChange={(e: SelectChangeEvent<string>) => {
                                    if (e.target.value === '') {
                                        onChangeMemberIdKind(undefined)
                                    } else {
                                        onChangeMemberIdKind(e.target.value as MemberIdKindType)
                                    }
                                }}
                            >
                                <MenuItem value='' disabled>お持ちの会員番号の種別を選択してください</MenuItem>
                                {Object.entries(MemberIdKindType).map(([key, val]) => <MenuItem value={key} key={key}>{val}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <Collapse in={memberIdKind !== undefined}>
                            {memberIdKind === 'code' &&
                                <TextField
                                    label="バーコード番号の下6桁"
                                    required={memberIdKind === 'code'}
                                    name='code'
                                    value={code ? code : ""}
                                    onChange={(e) => {
                                        onChangeCode(e.target.value)
                                    }}
                                    fullWidth
                                />
                            }
                            {memberIdKind === 'old_member_id' &&
                                <TextField
                                    label="会員番号"
                                    required={memberIdKind === 'old_member_id'}
                                    name='old_member_id'
                                    value={oldMemberId ? oldMemberId : ""}
                                    onChange={(e) => {
                                        onChangeOldMemberId(e.target.value)
                                    }}
                                    fullWidth
                                />
                            }
                        </Collapse>
                        <TextField
                            label="せい"
                            required
                            name='pronunciation1'
                            value={pronunciation1 ? pronunciation1 : ""}
                            onChange={(e) => {
                                onChangePronunciation1(e.target.value)
                            }}
                            fullWidth
                        />
                        <TextField
                            label="めい"
                            required
                            name='pronunciation2'
                            value={pronunciation2 ? pronunciation2 : ""}
                            onChange={(e) => {
                                onChangePronunciation2(e.target.value)
                            }}
                            fullWidth
                        />
                        <DatePicker
                            openTo="year"
                            label="生年月日"
                            onChange={(date) => {
                                onChangeBirthday(date ? date : undefined)
                            }}
                            value={birthday ? birthday : null}
                            disableFuture={true}
                        />
                        <TextField
                            label="電話番号"
                            required
                            name='tel'
                            value={tel ? tel : ""}
                            onChange={(e) => {
                                onChangeTel(e.target.value)
                            }}
                            fullWidth
                            type="tel"
                        />
                        <Button
                            variant="outlined"
                            type="submit"
                            fullWidth
                            disabled={isSending}
                        >{isSending ? <CircularProgress size={"1em"} color="inherit" sx={{my: 0.7}} /> : "送信"}</Button>
                    </Stack>

                </Box>
            ) : (
                <Box
                    textAlign='right'
                    sx={{
                        mx: 2
                    }}
                >
                    <Button
                        onClick={() => {
                            onReset()
                        }}
                    >{result}</Button>
                </Box>
            )}
        </Box>
    )
}

export default RegisterStep2a
import { Backdrop, CircularProgress, Collapse, Paper, Stack, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import React from "react"
import { Link, useParams } from "react-router-dom"
import { ApiError } from "../../Api/ApiError"
import { CheckRegisterd, CheckRegisterdResult, Register, RegisterProps, RegMypage, ValidateKey } from "../../Api/Mypage"
import { MemberIdKindType } from "../../Objects/MemberIdKindType"
import BlueArea from "../atoms/BlueArea"
import RegisterStep4 from "../organisms/RegisterStep4"
import RegisterStep5 from "../organisms/RegisterStep5"
import RegisterStep2a from "../organisms/RegisterStep2a"
import RegisterStep3a from "../organisms/RegisterStep3a"

const RegisterCheckPage: React.FC = () => {
    let { enqueueSnackbar } = useSnackbar()
    let { key, subkey } = useParams()
    let [loading, setIsLoading] = React.useState<boolean>(true)
    let [isRegisterd, setIsRegisterd] = React.useState<boolean>()
    let [isError, setIsError] = React.useState<boolean>(false)
    let [isComplete, setIsComplete] = React.useState(false)
    let [isSending, setIsSending] = React.useState(false)
    
    let [memberIdKind, setMemberIdKind] = React.useState<MemberIdKindType>()
    let [code, setCode] = React.useState<string>()
    let [oldMemberId, setOldMemberId] = React.useState<string>()
    let [pronunciation1, setPronunciation1] = React.useState<string>()
    let [pronunciation2, setPronunciation2] = React.useState<string>()
    let [birthday, setBirthday] = React.useState<Date>()
    let [tel, setTel] = React.useState<string>()

    let [registerData, setRegisterData] = React.useState<RegisterProps>({})
    let [sendMail, setSendMail] = React.useState<boolean>()
    let [token, setToken] = React.useState<string>()

    const abortControllerRef = React.useRef(new AbortController())

    const doRegister = React.useCallback(() => {
        setIsSending(true)
        Register({
            ...registerData,
            key: key,
            subkey: subkey
        }, {signal: abortControllerRef.current.signal}).then( () => {
            setIsComplete(true)
        }).catch((e: any) => {
            if( e.__CANCEL__ ) {
                console.info("canceled")
            } else if (e instanceof ApiError) {
                enqueueSnackbar(e.message, { variant: "error" })
            } else if (e instanceof Error) {
                enqueueSnackbar(e.message, { variant: "error" })
            }else {
                enqueueSnackbar("通信に失敗しました", { variant: "error" })
            }
        }).finally(() => {
            setIsSending(false)
        })
    }, [key, subkey, registerData, enqueueSnackbar])

    let doResetRegisterdData = React.useCallback(() => {
        setMemberIdKind(undefined)
        setCode(undefined)
        setOldMemberId(undefined)
        setPronunciation1(undefined)
        setPronunciation2(undefined)
        setBirthday(undefined)
        setTel(undefined)
    }, [])

    const doCheckRegisterd = React.useCallback(() => {
        if (memberIdKind === undefined) {
            return;
        }
        if (memberIdKind === 'code' && code === undefined) {
            return;
        }
        if (memberIdKind === 'old_member_id' && oldMemberId === undefined) {
            return;
        }
        if (pronunciation1 === undefined) {
            return;
        }
        if (pronunciation2 === undefined) {
            return;
        }
        if (birthday === undefined) {
            return;
        }
        if (tel === undefined) {
            return;
        }
        setIsSending(true)
        CheckRegisterd({
            memberIdKind: memberIdKind,
            code: code,
            oldMemberId: oldMemberId,
            pronunciation1: pronunciation1,
            pronunciation2: pronunciation2,
            birthday: birthday,
            tel: tel
        }, {signal: abortControllerRef.current.signal}).then((result: CheckRegisterdResult) => {
            setSendMail(true)
            setToken(result.token)
        }).catch((e: ApiError) => {
            enqueueSnackbar(e.message, { variant: 'error' });
        }).finally(() => {
            setIsSending(false)
        })
    }, [memberIdKind, code, oldMemberId, pronunciation1, pronunciation2, birthday, tel, enqueueSnackbar])

    const doRegMypage = React.useCallback(() => {
        if(token) {
            setIsSending(true)
            RegMypage({
                token: token
            }, {signal: abortControllerRef.current.signal}).then(() => {
                setIsComplete(true)
            }).catch((e: any) => {
                if( e.__CANCEL__ ) {
                    console.info("canceled")
                } else if (e instanceof ApiError) {
                    enqueueSnackbar(e.message, { variant: "error" })
                } else if (e instanceof Error) {
                    enqueueSnackbar(e.message, { variant: "error" })
                }else {
                    enqueueSnackbar("通信に失敗しました", { variant: "error" })
                }
            }).finally(() => {
                setIsSending(false)
            })
        }
    }, [token, enqueueSnackbar])

    React.useEffect(() => {
        if (key && subkey) {
            setIsSending(true)
            ValidateKey({
                key: key,
                subkey: subkey
            }, {signal: abortControllerRef.current.signal}).then(() => {
                setIsLoading(false)
                setIsError(false)
            }).catch((e: any) => {
                if( e.__CANCEL__ ) {
                    console.info("canceled")
                    return;
                } else if (e instanceof ApiError) {
                    enqueueSnackbar(e.message, { variant: "error" })
                } else if (e instanceof Error) {
                    enqueueSnackbar(e.message, { variant: "error" })
                }else {
                    enqueueSnackbar("通信に失敗しました", { variant: "error" })
                }
                setIsError(true)
                // enqueueSnackbar(e.message, { variant: 'error' });
            }).finally(() => {
                setIsSending(false)
            })
        }
    }, [key, subkey, enqueueSnackbar])

    React.useEffect(() => {
        abortControllerRef.current = new AbortController()
        return () => {
            abortControllerRef.current.abort()
        }
    }, [])

    if (isComplete) {
        return (
            <Paper sx={{
                p: {
                    xs: 1,
                    sm: 2,
                },
            }}>
                <BlueArea>
                    {isRegisterd ? (
                        <>
                            <Typography>マイページ登録ありがとうございました。</Typography>
                            <Typography>登録されているメールアドレスにログインパスワードを送信しました。</Typography>
                        </>
                    ) : (
                        <>
                            <Typography>Web入会申し込みありがとうございました。</Typography>
                            <Typography>Web入会完了メールを、あなたのメールアドレスに送信しました。</Typography>
                        </>
                    )}
                </BlueArea>
            </Paper>
        )
    }

    if (isError) {
        return (
            <Paper sx={{
                p: {
                    xs: 1,
                    sm: 2,
                },
            }}>
                <BlueArea>
                    <Typography
                        textAlign='left'
                        sx={{
                            fontWeight: 'bold'
                        }}
                        color="error"
                    >データが見つかりません。</Typography>
                    <Typography
                        textAlign='left'
                        sx={{
                            fontWeight: 'bold'
                        }}
                        color="error"
                    >お手数ですが、再度登録を行ってください。</Typography>
                </BlueArea>
                <Link to="/register">戻る</Link>
            </Paper>
        )
    }

    if (loading) {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    return (
        <Paper sx={{
            p: {
                xs: 1,
                sm: 2,
            },
        }}>
            <Stack>
                <RegisterStep4
                    result={isRegisterd}
                    onClick={(value: boolean) => {
                        setIsRegisterd(value)
                    }}
                    onReset={() => {
                        setIsRegisterd(undefined)
                    }}
                />
                <Collapse in={isRegisterd === false}>
                    <RegisterStep5
                        data={registerData}
                        onSubmit={() => {
                            doRegister()
                        }}
                        onReset={() => {
                            setRegisterData({})
                        }}
                        onChange={(data: RegisterProps) => {
                            setRegisterData(data)
                        }}
                        isSending={isSending}
                    />
                </Collapse>
                <Collapse in={isRegisterd === true}>
                    <RegisterStep2a
                            result={sendMail ? pronunciation1 + ' ' + pronunciation2 : undefined}
                            memberIdKind={memberIdKind}
                            code={code}
                            oldMemberId={oldMemberId}
                            pronunciation1={pronunciation1}
                            pronunciation2={pronunciation2}
                            birthday={birthday}
                            tel={tel}
                            onChangeMemberIdKind={(value?: MemberIdKindType) => {
                                setMemberIdKind(value)
                            }}
                            onChangeCode={(value?: string) => {
                                setCode(value)
                            }}
                            onChangeOldMemberId={(value?: string) => {
                                setOldMemberId(value)
                            }}
                            onChangePronunciation1={(value?: string) => {
                                setPronunciation1(value)
                            }}
                            onChangePronunciation2={(value?: string) => {
                                setPronunciation2(value)
                            }}
                            onChangeBirthday={(value?: Date) => {
                                setBirthday(value)
                            }}
                            onChangeTel={(value?: string) => {
                                setTel(value)
                            }}
                            onSubmit={() => {
                                doCheckRegisterd()
                            }}
                            onReset={() => {
                                doResetRegisterdData()
                                setSendMail(undefined)
                            }}
                            isSending={isSending}
                    />
                </Collapse>
                <Collapse in={sendMail === true}>
                    <RegisterStep3a
                        onSubmit={() => {
                            doRegMypage()
                        }}
                    />
                </Collapse>
            </Stack>
        </Paper>
    )
}

export default RegisterCheckPage
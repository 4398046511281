import React from "react"
import { FormControl, InputLabel, Select as MuiSelect, SelectProps as MuiSelectProps, FormControlProps } from "@mui/material"

export interface SelectProps extends MuiSelectProps {
    formControlProps?: FormControlProps
}

const Select: React.FC<SelectProps> = (props) => {
    let { formControlProps, ...muiSelectProps } = props
    return (
        <FormControl {...props?.formControlProps}>
            {props.labelId &&
                <InputLabel id={props.labelId}>{props.label}</InputLabel>
            }
            <MuiSelect
                {...muiSelectProps}
            />
        </FormControl>
    )
}

export default Select
import { Box, Checkbox, Collapse, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import React from "react"
import { ApiError } from "../../Api/ApiError"
import { EnqueteList } from "../../Api/Mypage"
import { EnqueteAnswer, EnqueteData } from "../../Objects/EnqueteData"
import BlueArea from "../atoms/BlueArea"


interface EnqueteCheckBoxProps {
    enqueteData: EnqueteData,
    values: EnqueteAnswer[],
    onChange: (items: EnqueteAnswer[]) => void
}
const EnqueteCheckBox = ({
    enqueteData,
    values,
    onChange
}: EnqueteCheckBoxProps) => {
    return (
        <FormControl>
            <FormLabel id={``}>{enqueteData.title}</FormLabel>
            <FormGroup>
                <Stack
                    direction={"row"}
                    flexWrap="wrap"
                >
                    {enqueteData.items.map(enqueteItem => (
                        <Box key={enqueteItem.id} textAlign="left">
                            <FormControlLabel
                                label={enqueteItem.name}
                                control={<Checkbox />}
                                checked={values.some(value => value.enqitem_id === enqueteItem.id)}
                                onChange={(e, checked) => {
                                    const newValue = values.filter(value => value.enqitem_id !== enqueteItem.id)
                                    if (checked) {
                                        newValue.push({
                                            enqgroup_id: enqueteData.id,
                                            enqitem_id: enqueteItem.id,
                                        })
                                    }
                                    onChange(newValue)
                                }}
                            />
                            {enqueteItem.extra &&
                                <TextField
                                    disabled={!values.some(value => value.enqitem_id === enqueteItem.id)}
                                    size="small"
                                    value={values.find(value => value.enqitem_id === enqueteItem.id)?.enqitem_extra ?? ""}
                                    onChange={e => {
                                        const newValue = values.filter(value => value.enqitem_id !== enqueteItem.id)
                                        newValue.push({
                                            enqgroup_id: enqueteData.id,
                                            enqitem_id: enqueteItem.id,
                                            enqitem_extra: e.target.value
                                        })
                                        onChange(newValue)
                                    }}
                                />
                            }
                        </Box>
                    ))}
                </Stack>
            </FormGroup>
        </FormControl>
    )
}
interface EnqueteRadioGroupProps {
    enqueteData: EnqueteData,
    value: EnqueteAnswer | null,
    onChange: (item: EnqueteAnswer | null) => void
}
const EnqueteRadioGroup = ({
    enqueteData,
    value,
    onChange
}: EnqueteRadioGroupProps) => {
    const [extraField, setExtraField] = React.useState<boolean>(false)

    React.useEffect(() => {
        const selected = enqueteData.items.find(enqueteItem => enqueteItem.id === value?.enqitem_id)
        if (selected?.extra) {
            setExtraField(true)
        } else {
            setExtraField(false)
        }
    }, [value?.enqitem_id, enqueteData.items])

    return (
        <FormControl>
            <FormLabel>{enqueteData.title}</FormLabel>
            <RadioGroup
                row
                value={value ? value.enqitem_id : null}
                onChange={e => {
                    const enqitem_id: number = parseInt((e.target as HTMLInputElement).value)
                    onChange({
                        enqgroup_id: enqueteData.id,
                        enqitem_id: enqitem_id,
                    })
                }}
            >
                {enqueteData.items && enqueteData.items.map((item, index) => (
                    <FormControlLabel
                        key={`${enqueteData.id}-${item.id}`}
                        value={item.id}
                        control={<Radio />}
                        label={item.name}
                    />
                ))}
            </RadioGroup>
            <Collapse
                in={extraField}
            >
                <TextField
                    size="small"
                    value={value?.enqitem_extra ?? ""}
                    onChange={e => {
                        if (value) {
                            onChange({
                                ...value,
                                enqitem_extra: e.target.value
                            })
                        }
                    }}
                    fullWidth
                />
            </Collapse>
        </FormControl>
    )
}

export interface RegisterStep5EnqueteProps {
    enqueteAnswers: EnqueteAnswer[]
    onChange: (answers: EnqueteAnswer[]) => void
}
const RegisterStep5Enquete: React.FC<RegisterStep5EnqueteProps> = ({
    enqueteAnswers,
    onChange
}: RegisterStep5EnqueteProps) => {
    let { enqueueSnackbar } = useSnackbar()

    const [enqueteList, setEnqueteList] = React.useState<EnqueteData[]>([])
    // const [enqueteResults, setEnqueteResults] = React.useState<EnqueteAnswar[]>([])

    const abortControllerRef = React.useRef(new AbortController())

    React.useEffect(() => {
        abortControllerRef.current = new AbortController()

        EnqueteList({ signal: abortControllerRef.current.signal }).then(result => {
            setEnqueteList(result.enqueteList)
        }).catch(e => {
            if (e.__CANCEL__) {
                console.info("canceled")
            } else if (e instanceof ApiError) {
                enqueueSnackbar(e.message, { variant: "error" })
            } else if (e instanceof Error) {
                enqueueSnackbar(e.message, { variant: "error" })
            } else {
                enqueueSnackbar("通信に失敗しました", { variant: "error" })
            }
        })

        return () => {
            abortControllerRef.current.abort()
        }
    }, [])

    return (
        <Box>
            <BlueArea>
                <Typography
                    textAlign='left'
                    sx={{
                        fontWeight: 'bold'
                    }}
                >アンケートのご協力をお願いします</Typography>
            </BlueArea>
            <Box
                sx={{
                    mx: 2
                }}
            >
                <Stack
                    spacing={2}
                >
                    {enqueteList && enqueteList.map(enqueteData => {
                        return enqueteData.multiselect ? (
                            <EnqueteCheckBox
                                key={enqueteData.id}
                                enqueteData={enqueteData}
                                values={enqueteAnswers.filter(value => value.enqgroup_id === enqueteData.id)}
                                onChange={(items) => {
                                    const newValue: EnqueteAnswer[] = enqueteAnswers.filter(result => result.enqgroup_id !== enqueteData.id)
                                    onChange(newValue.concat(items))
                                }}
                            />
                        ) : (
                            <EnqueteRadioGroup
                                key={enqueteData.id}
                                enqueteData={enqueteData}
                                value={enqueteAnswers.find(result => result.enqgroup_id === enqueteData.id) ?? null}
                                onChange={item => {
                                    const newValue: EnqueteAnswer[] = enqueteAnswers.filter(result => result.enqgroup_id !== enqueteData.id)
                                    if (item) {
                                        newValue.push(item)
                                    }
                                    onChange(newValue)
                                }}
                            />
                        )
                    })}
                </Stack>
            </Box>
        </Box>
    )
}

export default RegisterStep5Enquete
/**
 * interface StoreData
 */
export interface StoreData {
    str_id: number
    str_login_str: string
    str_name: string
}
export const ObjectToStoreData = ({
    str_id,
    str_login_str,
    str_name
}: {
    str_id: string,
    str_login_str: string,
    str_name: string
}) => {
    return {
        str_id: parseInt(str_id),
        str_login_str: str_login_str,
        str_name: str_name
    }
}
import { Edit } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import React from "react";
import { secToTimeStringAHM } from "../../Helper/Time/Time";
import { ReserveData } from "../../Objects/ReserveData";

export interface ReserveConditionsProps {
    reserveData?: ReserveData
    onEditDate?: () => void
    onEditTime?: () => void
    onEditNumMember?: () => void
    align?: 'center' | 'inherit' | 'justify' | 'left' | 'right'
    sx?: SxProps
}

const ReserveConditions: React.FC<ReserveConditionsProps> = ({
    reserveData,
    onEditDate,
    onEditTime,
    onEditNumMember,
    align,
    sx,
}) => {


    /**
     * View
     */
    return (
        <Stack
            sx={sx}
        >
            <Box>
                {reserveData?.date &&
                    <Typography align={align ? align : "right"}>
                        {reserveData?.date ? format(reserveData?.date, "yyyy年MM月dd日(E)", { locale: ja }) : ""}
                        {onEditDate &&
                            <IconButton aria-label="変更" color="primary" size="small"
                                onClick={() => {
                                    onEditDate()
                                }}
                            ><Edit fontSize="inherit" /></IconButton>
                        }
                    </Typography>
                }
            </Box>
            <Box>
                {reserveData?.startTime && reserveData?.durationTime &&
                    <Typography align={align ? align : "right"}>
                        {reserveData.startTime.toStringAHM()} 〜 {secToTimeStringAHM(reserveData.startTime.toSec() + reserveData.durationTime.toSec())}
                        {onEditTime &&
                            <IconButton aria-label="変更" color="primary" size="small"
                                onClick={() => {
                                    onEditTime()
                                }}
                            ><Edit fontSize="inherit" /></IconButton>
                        }
                    </Typography>
                }
            </Box>
            <Box>
                {reserveData?.numMember &&
                    <Typography align={align ? align : "right"}>
                        {reserveData.numMember < 10 ? reserveData.numMember + "人" : "10人以上"}
                        {onEditNumMember &&
                            <IconButton aria-label="変更" color="primary" size="small"
                                onClick={() => {
                                    onEditNumMember()
                                }}
                            ><Edit fontSize="inherit" /></IconButton>
                        }
                    </Typography>
                }
            </Box>
        </Stack>
    )
}

export default ReserveConditions
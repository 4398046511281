import { getDay, isAfter, isBefore, parse } from "date-fns"
import { Time } from "../Helper/Time/Time"

export interface LessonReservable {
    lrvable_id: number
    teacher_id: number
    week_conditions?: "sun" | "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "hol"
    start: Time
    end: Time
    start_apply: Date | null
    end_apply: Date | null
}

export const ObjectToLessonReservable = ({
    lrvable_id,
    teacher_id,
    week_conditions,
    start,
    end,
    start_apply,
    end_apply,
}:{
    lrvable_id: number | string
    teacher_id: number | string
    week_conditions: string
    start: string
    end: string
    start_apply: string | null
    end_apply: string | null
}) => {
    return {
        lrvable_id: typeof lrvable_id === 'string' ? parseInt(lrvable_id) : lrvable_id,
        teacher_id: typeof teacher_id === 'string' ? parseInt(teacher_id) : teacher_id,
        week_conditions: ["sun" , "mon" , "tue" , "wed" , "thu" , "fri" , "sat" , "hol"].includes(week_conditions) ? week_conditions : undefined,
        start: new Time(start),
        end: new Time(end),
        start_apply: start_apply ? parse(start_apply, "yyyy-MM-dd", new Date()) : null,
        end_apply: end_apply ? parse(end_apply, "yyyy-MM-dd", new Date()) : null,
    }
}

export const IsReservableDate = (
    date: Date,
    lessonReservable: LessonReservable,
    isHoliday?: boolean
) => {
    if( lessonReservable.start_apply && isBefore(date, lessonReservable.start_apply) ) {
        return false
    }
    if( lessonReservable.end_apply && isAfter(date, lessonReservable.end_apply) ) {
        return false
    }
    if(lessonReservable.week_conditions) {
        if( isHoliday === true ) {
            return lessonReservable.week_conditions === "hol"
        }
        let day = ["sun" , "mon" , "tue" , "wed" , "thu" , "fri" , "sat"].at(getDay(date))
        if( day !== lessonReservable.week_conditions ) {
            return false
        }
    }
    return true
}
import { Box, Button, Paper, Typography } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"
import BlueArea from "../atoms/BlueArea"

const ForgotCompletePage: React.FC = () => {
    let navigate = useNavigate()

    return (
        <Paper sx={{
            p: {
                xs: 1,
                sm: 2,
            },
        }}>
            <BlueArea>
                <Typography
                    textAlign='left'
                >メールを送信しました。</Typography>
                <Typography
                    textAlign='left'
                >受信したメールに記載してあるリンクよりパスワード再発行を完了させてください。</Typography>
                <Typography
                    textAlign='left'
                >メール記載のリンクは1時間有効です。</Typography>
            </BlueArea>
            <Box
                sx={{
                    maxWidth: '600px',
                    mx: 'auto'
                }}
            >
                <Button
                    variant="outlined"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault()
                        navigate("/login")
                    }}
                    fullWidth
                    sx={{
                        my: 1
                    }}
                >戻る</Button>
            </Box>
        </Paper>
    )
}

export default ForgotCompletePage
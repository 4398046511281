import { parse } from "date-fns"
import { Time, timeLength } from "../Helper/Time/Time"
import { LessonReserve, ObjectToLessonReserve } from "./LessonReserve"
import { ReservableStudio } from "./ReservableStudio"
import { ReserveEquip } from "./ReserveEquip"
import { ObjectToStudioData, StudioData } from "./StudioData"

/**
 * interface ReserveData
 */
export interface ReserveData {
    stdrv_id?: number
    date?: Date
    startTime?: Time
    durationTime?: Time
    numMember?: number
    reservableStudio?: ReservableStudio
    reserveStudio?: StudioData
    reserveEquipList?: ReserveEquip[]
    lessonReserve?: LessonReserve
}
export const ObjectToReserveData = ({
    stdrv_id,
    reserve_date,
    start,
    end,
    number,
    std_id,
    std_name,
    std_area,
    stdimg_data,
    stdimg_id,
    str_id,
    str_name,
    sort,
    stdimg_mime_type,
    lesson_reserve,
    lesson_id,
    teacher_id,
    student_id,
}: {
    stdrv_id?: number | string
    reserve_date: string
    start: string
    end: string
    number: number | string
    std_id: number | string
    std_name: string
    std_area: string
    stdimg_data: string
    stdimg_id: number | string
    str_id: number | string
    str_name: string
    sort: string | number
    stdimg_mime_type: string
    lesson_reserve?: any
    lesson_id?: number | string
    teacher_id?: number | string
    student_id?: number | string
}): ReserveData => {
    let duration = timeLength(start, end)
    if( ! lesson_reserve && ( lesson_id || teacher_id || student_id ) ) {
        lesson_reserve = ObjectToLessonReserve({
            lesson_id: lesson_id,
            student_id: student_id,
            teacher_id: teacher_id,
        })
    }
    
    return {
        stdrv_id: typeof stdrv_id === 'string' ? parseInt(stdrv_id) : stdrv_id,
        date: parse(reserve_date, "yyyy-MM-dd", new Date()),
        startTime: new Time(start),
        durationTime: duration ? duration : undefined,
        numMember: typeof number === 'string' ? parseInt(number) : number,
        lessonReserve: lesson_reserve ? ObjectToLessonReserve(lesson_reserve) : undefined,
        reserveStudio: ObjectToStudioData({
            std_id: std_id,
            std_name: std_name,
            str_id: str_id,
            str_name: str_name,
            sort: sort,
            std_area: std_area,
            stdimg_id: stdimg_id,
            stdimg_data: stdimg_data,
            stdimg_mime_type: stdimg_mime_type
        }),
    }
}
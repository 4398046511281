
/**
 * interface EquipmentAdjustment
 */
export interface EquipmentAdjustment {
    equ_name: string
    equ_maker: string
    equ_model: string
    equgrp_id: number
    equgrp_name: string
    item_number: number
    apiece: number
    charge: number
    free_flug: number
    comment: string
}
export const ObjectToEquipmentAdjustment = ({
    equ_name,
    equ_maker,
    equ_model,
    equgrp_id,
    equgrp_name,
    item_number,
    apiece,
    charge,
    free_flug,
    comment,
}: {
    equ_name: string
    equ_maker: string
    equ_model: string
    equgrp_id: number | string
    equgrp_name: string
    item_number: number | string
    apiece: number | string
    charge: number | string
    free_flug: number | string
    comment: string
}): EquipmentAdjustment => {
    return {
        equ_name: equ_name,
        equ_maker: equ_maker,
        equ_model: equ_model,
        equgrp_id: typeof equgrp_id === 'string' ? parseInt(equgrp_id) : equgrp_id,
        equgrp_name: equgrp_name,
        item_number: typeof item_number === 'string' ? parseInt(item_number) : item_number,
        apiece: typeof apiece === 'string' ? parseInt(apiece) : apiece,
        charge: typeof charge === 'string' ? parseInt(charge) : charge,
        free_flug: typeof free_flug === 'string' ? parseInt(free_flug) : free_flug,
        comment: comment,
    }
}
import { Box, Stack, SxProps, Typography } from "@mui/material";
import React from "react";
import { EquipmentAdjustment } from "../../Objects/EquipmentAdjustment";

export interface EquipmentAdjustmentDetailProps {
    equipmentAdjustmentList: EquipmentAdjustment[]
    sx?: SxProps
}

const EquipmentAdjustmentDetail: React.FC<EquipmentAdjustmentDetailProps> = ({
    equipmentAdjustmentList,
    sx,
}) => {


    /**
     * View
     */

    return (
        <Stack
            sx={sx}
            spacing={1}
        >
            {equipmentAdjustmentList.map((equipmentAdjustment: EquipmentAdjustment, index: number) => (
                <Box key={index}>
                    <Typography variant="body2">{equipmentAdjustment.equ_name}</Typography>
                    <Typography variant="body2">{equipmentAdjustment.equ_maker} / {equipmentAdjustment.equ_model}</Typography>
                    <Typography variant="body2" align="right">{equipmentAdjustment.charge.toLocaleString()}円</Typography>
                </Box>
            ))}
        </Stack>
    )
}

export default EquipmentAdjustmentDetail
import { Edit } from "@mui/icons-material";
import { IconButton, Typography, TypographyProps } from "@mui/material";
import React from "react";
import { secToTimeStringAHM, Time } from "../../Helper/Time/Time";

export interface TypographyTimeProps {
    startTime: Time
    durationTime: Time
    typographyProps?: TypographyProps
    onEdit?: () => void
}
const TypographyTime: React.FC<TypographyTimeProps> = ({
    startTime,
    durationTime,
    typographyProps,
    onEdit,
}) => {

    /**
     * View
     */

    return (
        <Typography
            {...typographyProps}
        >
            {startTime.toStringAHM()} 〜 {secToTimeStringAHM(startTime.toSec() + durationTime.toSec())}
            {onEdit &&
                <IconButton aria-label="変更" color="primary" size="small"
                    onClick={onEdit}
                >
                    <Edit fontSize="inherit" />
                </IconButton>
            }
        </Typography>
    )
}

export default TypographyTime